import React from 'react';
import { StatementStatus } from '@/transport/getAdminDataApi/types';
import { Chip, useTheme } from '@mui/material';

type StatusChipProps = {
    status: StatementStatus;
};

export const StatusChip: React.FC<StatusChipProps> = ({ status }) => {
    const { palette } = useTheme();

    const getStatusBgColor = (status: StatementStatus) => {
        if (status === 'SUCCESS') {
            return palette.success.light;
        } else if (status === 'CANCELED') {
            return palette.error.light;
        } else {
            return palette.text.secondary;
        }
    };

    return (
        <Chip
            label={status}
            sx={{
                color: 'white',
                bgcolor: getStatusBgColor(status),
            }}
        />
    );
};
