import { createSvgIcon } from '@mui/material/utils';

export const KeyIcon = createSvgIcon(
    <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M 0 12.019 C 0 8.474 2.874 5.6 6.419 5.6 C 8.853 5.6 10.969 6.955 12.057 8.949 L 22.047 8.949 C 23.125 8.949 24 9.823 24 10.902 L 24 13.693 C 24 14.155 23.625 14.53 23.163 14.53 L 20.651 14.53 L 20.651 16.484 C 20.651 16.946 20.276 17.321 19.814 17.321 L 17.023 17.321 C 16.561 17.321 16.186 16.946 16.186 16.484 L 16.186 14.53 L 12.327 14.53 C 11.35 16.826 9.073 18.437 6.419 18.437 C 2.874 18.437 0 15.564 0 12.019 Z M 6.419 9.786 C 5.186 9.786 4.186 10.786 4.186 12.019 C 4.186 13.252 5.186 14.251 6.419 14.251 C 7.652 14.251 8.651 13.252 8.651 12.019 C 8.651 10.786 7.652 9.786 6.419 9.786 Z'
    />,
    'LockIcon'
);
