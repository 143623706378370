import { createSvgIcon } from '@mui/material/utils';

export const CoinIcon = createSvgIcon(
    <>
        <path
            d='M20.707 4.828c3.98 4.805 3.328 11.985-1.457 16.043-4.781 4.059-11.887 3.457-15.867-1.348C-.602 14.723.05 7.543 4.836 3.484 9.62-.574 16.723.027 20.707 4.828zm0 0'
            fill='#f9c80e'
        />
        <path
            d='M16.96 6.332a7.692 7.692 0 00-3.878-1.898c-.094-.016-.187-.032-.281-.043-.008 0-.016 0-.024-.004a.323.323 0 01-.257-.34.31.31 0 01.367-.281c.101.011.203.027.3.043a8.296 8.296 0 014.192 2.05l.004.004a8.475 8.475 0 012.601 4.633v.008a8.494 8.494 0 01-.668 5.277l-.003.008a8.28 8.28 0 01-3.676 3.797l-.004.004a8.206 8.206 0 01-5.2.785h-.007a8.307 8.307 0 01-4.633-2.54 8.49 8.49 0 01-2.207-4.839v-.008a8.473 8.473 0 01.8-4.633c.048-.09.09-.18.141-.27l.012-.023a.303.303 0 01.426-.117.328.328 0 01.125.418 7.835 7.835 0 00-.883 4.55v.009a7.855 7.855 0 002.043 4.48 7.7 7.7 0 004.29 2.348l.007.004a7.61 7.61 0 004.805-.73v-.004a7.641 7.641 0 003.394-3.504l.004-.008a7.856 7.856 0 00.621-4.88v-.003a7.83 7.83 0 00-2.41-4.293zm0 0'
            fill='#fff'
        />
        <path
            d='M12.965 12.563c-.086-.028-.223.035-.41.183a1.43 1.43 0 01-.235.172c-.117.074-.261.172-.437.289a12.103 12.103 0 00-1.16.797c-.07-.082-.145-.172-.223-.262a3.862 3.862 0 01-.219-.281l-.054-.027.3-.223a3.97 3.97 0 01.246-.2l1.07-.78c.118-.102.208-.168.259-.208.128-.183.195-.332.207-.445.011-.11-.028-.226-.118-.355a.478.478 0 00-.218-.16c-.086-.04-.192-.016-.32.078l-.122.09c-.25.207-.547.44-.898.71l-1.028.79a3.57 3.57 0 01-.414-1.165 4.064 4.064 0 01-.027-1.34c.07-.453.23-.874.477-1.273.242-.402.59-.73 1.043-.984.488-.063.867-.164 1.136-.309.266-.148.442-.3.524-.453.078-.156.078-.305-.008-.434-.082-.132-.234-.21-.453-.23-.223-.027-.504.016-.836.137-.324.113-.695.336-1.117.668-.602.406-1.063.875-1.383 1.406a4.392 4.392 0 00-.625 1.633 3.98 3.98 0 00.07 1.636c.137.536.363 1.016.68 1.442-.09.078-.176.148-.258.207l-.367.27-.11.3a.932.932 0 00.618.078c.222-.05.453-.136.687-.25.192.27.395.504.61.711-.215.25-.368.496-.45.739a.665.665 0 00.086.668l.422-.31c.195-.14.41-.292.645-.452.738.558 1.53.832 2.375.824.847-.004 1.699-.324 2.558-.953l.465-.344c.18-.144.367-.297.555-.457.183-.172.363-.348.539-.531.168-.192.297-.387.394-.582.098-.195.153-.387.164-.578a.872.872 0 00-.175-.551c-.051-.07-.102-.102-.16-.106a.355.355 0 00-.192.02.462.462 0 00-.18.082c-.054.031-.101.055-.128.078a2.611 2.611 0 00-.52.485 8.36 8.36 0 00-.395.492 5.043 5.043 0 01-.414.508c-.16.171-.367.355-.625.546-.55.403-1.097.621-1.648.664a2.956 2.956 0 01-1.574-.347l.426-.309a2.74 2.74 0 00.289-.234c.109-.086.226-.188.363-.297.144-.117.265-.238.371-.36.113-.128.187-.25.227-.37a.379.379 0 00-.06-.364.432.432 0 00-.245-.18zm0 0'
            fill='#fff'
        />
    </>,
    'CoinIcon'
);
