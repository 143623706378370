import React from 'react';
import { useTranslation } from '@/ui-kit/hooks';
import { Box, IconButton, Stack } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import { UserRolesTable } from '../components/UserRolesTable';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PageTitle } from '@/ui-kit/components';

const DesktopUsersListPage = () => {
    const { t } = useTranslation('statements');
    const navigate = useNavigate();

    return (
        <Box
            sx={({ spacing }) => ({
                pb: spacing(5),
            })}
        >
            <Stack direction='row' justifyContent='space-between'>
                <Box>
                    <PageTitle>
                        <IconButton onClick={() => navigate('/users')}>
                            <ArrowBackIcon />
                        </IconButton>
                        {t('User roles list')}
                    </PageTitle>
                </Box>
            </Stack>
            <UserRolesTable />
            <Outlet />
        </Box>
    );
};

export default DesktopUsersListPage;
