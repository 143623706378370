import * as React from 'react';
import { FC, useCallback, useMemo, useState } from 'react';
import { styled } from '@mui/material';
import {
    ChevronDownIcon as ChevronDownIconBase,
    ChevronUpIcon as ChevronUpIconBase,
    ClockIcon as ClockIconBase,
} from '../../icons';
import liberBlack from '../../theme/colors/liberBlack';
import { useTranslation } from '../../hooks/useTranslation';
import { WorkingHourDay } from '../../types/WorkingHourDay';

type MerchantContentWorkingHoursProps = {
    items: WorkingHourDay[];
};

const ClockIcon = styled(ClockIconBase)(({ theme }) => ({
    fill: theme.palette.error.main,
    width: theme.spacing(2),
    height: theme.spacing(2),
}));

const ChevronUpIcon = styled(ChevronUpIconBase)(({ theme }) => ({
    fill: theme.palette.error.main,
    width: theme.spacing(2),
    height: theme.spacing(2),
}));

const ChevronDownIcon = styled(ChevronDownIconBase)(({ theme }) => ({
    fill: theme.palette.error.main,
    width: theme.spacing(2),
    height: theme.spacing(2),
}));

const ContainerTable = styled('table')(({ theme }) => ({
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.fontSize,
    color: liberBlack[200],
    width: '100%',
    cursor: 'pointer',
}));

const TableRow = styled('tr')(() => ({
    width: 'auto',
}));

const TableCell = styled('td')(() => ({}));

const DAY_MAP = {
    0: 'sunday',
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday',
};

const formatTime = (str: string) => {
    const a = str.split(':');
    return [a[0], a[1]].join(':');
};

const TableCellWithIcon = styled(TableCell)({
    display: 'inline-flex',
    alignItems: 'center',
});

export const MerchantContentWorkingHours: FC<MerchantContentWorkingHoursProps> = ({ items }) => {
    const { t } = useTranslation('ui-kit__merchant');

    const [opened, setOpened] = useState(false);

    const currentDayItem = useMemo(() => {
        const currentDay = new Date().getDay();
        return items.find((item) => item.day === currentDay);
    }, [items]);

    const handleOpen = useCallback(() => setOpened(true), []);
    const handleClose = useCallback(() => setOpened(false), []);

    return (
        <ContainerTable>
            {opened ? (
                <>
                    {items.map((item, index) => {
                        if (item.from === null || item.to === null || item.day === null) {
                            return null;
                        }
                        return (
                            <TableRow onClick={handleClose} key={index}>
                                <TableCellWithIcon width='12px'>
                                    {index === 0 && <ClockIcon />}
                                </TableCellWithIcon>
                                <TableCell
                                    sx={(theme) => ({
                                        color:
                                            item.day === 0 || item.day === 6
                                                ? theme.palette.error.main
                                                : liberBlack[200],
                                    })}
                                >
                                    {t(`merchant_content.short_days.${DAY_MAP[item.day]}`)}
                                </TableCell>
                                <TableCell>
                                    {`${formatTime(item.from)} — ${formatTime(item.to)}`}
                                </TableCell>
                                <TableCell align='right'>
                                    {index === 0 && <ChevronUpIcon />}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </>
            ) : (
                <>
                    <TableRow onClick={handleOpen}>
                        <TableCellWithIcon width='12px'>
                            <ClockIcon />
                        </TableCellWithIcon>

                        {currentDayItem ? (
                            <>
                                <TableCell
                                    sx={(theme) => ({
                                        color:
                                            currentDayItem.day === 0 || currentDayItem.day === 6
                                                ? theme.palette.error.main
                                                : liberBlack[200],
                                    })}
                                >
                                    {t(
                                        `merchant_content.short_days.${DAY_MAP[currentDayItem.day]}`
                                    )}
                                </TableCell>
                                <TableCell>
                                    {`${formatTime(currentDayItem.from)} — ${formatTime(
                                        currentDayItem.to
                                    )}`}
                                </TableCell>
                            </>
                        ) : (
                            <TableCell>{t('merchant_content.closed')}</TableCell>
                        )}

                        <TableCell align='right'>
                            <ChevronDownIcon />
                        </TableCell>
                    </TableRow>
                </>
            )}
        </ContainerTable>
    );
};
