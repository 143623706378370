import React from 'react';
import { useTransactionFilters } from '../hooks/useTransactionFilters';
import { subMonths } from 'date-fns';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { DateRangePicker } from '@/components/DateRangePicker';
import { TokenizedAutocomplete } from '@/components/TokenizedAutocomplete';
import { tryExtractOrderId } from '../utils/tryExtractOrderId';
import { MultipleSelect } from '@/components/MultipleSelect';
import { StatementStatus } from '@/transport/getAdminDataApi/types';
import { useGetDirectoryDataQuery } from '@/transport/getAdminDataApi';
import { ExportStatementsButton } from './ExportStatementsButton';
import { ElevatedPaper } from '@/ui-kit/components';

const today = new Date();
const monthAgo = subMonths(new Date(), 1);

export const TransactionFilters = () => {
    const {
        dateStart,
        dateEnd,
        selectedCoupons,
        orderIds,
        institutionIds,
        countryCodes,
        statementStatuses,
        showTestTransfers,

        setDateStart,
        setDateEnd,
        setSelectedCoupons,
        setOrderIds,
        setInstitutionIds,
        setCountryCodes,
        setStatementStatuses,
        setShowTestTransfers,
    } = useTransactionFilters();

    const handleDateChange = ({
        dateStart,
        dateEnd,
    }: {
        dateStart: Date | null;
        dateEnd: Date | null;
    }) => {
        setDateStart(dateStart || monthAgo);
        setDateEnd(dateEnd || today);
    };

    const { data: directoryData } = useGetDirectoryDataQuery();
    const couponsAbbr = directoryData?.data.coupons.map((coupon) => coupon.abbr) || [];

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    height: 0,
                    position: 'relative',
                }}
            >
                <ExportStatementsButton
                    sx={{ position: 'absolute', bottom: 0 }}
                    dateStart={dateStart}
                    dateEnd={dateEnd}
                    coupons={selectedCoupons}
                />
            </Box>

            <ElevatedPaper
                sx={({ spacing }) => ({
                    py: spacing(3),
                    px: spacing(5),
                    width: '100%',
                    margin: '0 auto',
                    mb: spacing(4),
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    columnGap: '10px',
                    rowGap: '10px',
                })}
            >
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1.2fr 1.2fr 2fr 2fr',
                        columnGap: '10px',
                        rowGap: '10px',
                        width: '100%',
                        margin: '0 auto',
                        '@media (max-width: 768px)': {
                            gridTemplateColumns: '1fr',
                        },
                    }}
                >
                    <DateRangePicker
                        date={{ dateStart: new Date(dateStart), dateEnd: new Date(dateEnd) }}
                        onDateChange={handleDateChange}
                    />
                    <TokenizedAutocomplete
                        value={orderIds}
                        onChange={setOrderIds}
                        placeholder='Filter by order ID (or payment URL)'
                        label='Order ID'
                        formatTagBeforeAdd={tryExtractOrderId}
                    />
                    <MultipleSelect
                        label='Coupons'
                        placeholder='Filter by coupons'
                        options={couponsAbbr}
                        value={selectedCoupons}
                        onChange={setSelectedCoupons}
                        limitTags={3}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr 1fr',
                        columnGap: '10px',
                        rowGap: '10px',
                        width: '100%',
                        margin: '0 auto',
                        '@media (max-width: 768px)': {
                            gridTemplateColumns: '1fr',
                        },
                    }}
                >
                    <MultipleSelect
                        label='Institutions'
                        placeholder='Filter by institutions'
                        options={directoryData?.data.institutionIds || []}
                        value={institutionIds}
                        onChange={setInstitutionIds}
                        limitTags={1}
                    />
                    <MultipleSelect
                        label='Countries'
                        placeholder='Filter by countries'
                        options={directoryData?.data.institutionCountryCodes || []}
                        value={countryCodes}
                        onChange={setCountryCodes}
                        limitTags={3}
                    />
                    <MultipleSelect
                        label='Statuses'
                        placeholder='Filter by statuses'
                        options={Object.values(StatementStatus)}
                        value={statementStatuses}
                        onChange={setStatementStatuses}
                        limitTags={1}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showTestTransfers}
                                onChange={(_, checked) => setShowTestTransfers(checked)}
                            />
                        }
                        sx={{ mr: 0 }}
                        label={<Typography variant='body2'>Show test transfers</Typography>}
                    />
                </Box>
            </ElevatedPaper>
        </Box>
    );
};
