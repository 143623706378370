import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogActionsProps,
    DialogContent,
    DialogContentProps,
    DialogProps,
    DialogTitle,
    DialogTitleProps,
} from '@mui/material';

export const ManageDialog: React.FC<DialogProps> = ({ children, ...props }) => {
    return (
        <Dialog
            maxWidth='sm'
            fullWidth
            PaperProps={{ sx: { padding: { xs: 2, sm: 4 }, pt: { xs: 8 } } }}
            {...props}
        >
            {children}
        </Dialog>
    );
};

export const ManageDialogTitle: React.FC<DialogTitleProps> = ({ children, ...props }) => {
    return (
        <DialogTitle variant='h2' fontWeight='bold' sx={{ p: 0, mb: 4 }} {...props}>
            {children}
        </DialogTitle>
    );
};

export const ManageDialogContent: React.FC<DialogContentProps> = ({ children, ...props }) => {
    return (
        <DialogContent sx={{ p: 0, overflow: 'visible' }} {...props}>
            {children}
        </DialogContent>
    );
};

export const ManageDialogActions: React.FC<DialogActionsProps> = ({ children, ...props }) => {
    return (
        <DialogActions sx={{ p: 0 }} {...props}>
            {children}
        </DialogActions>
    );
};
