/**
 * Functional programming helpers
 */

type ComposedFnWPredecessor<
    Predecessor extends (args: any) => unknown,
    SelfReturnType = unknown
> = (arg: ReturnType<Predecessor>) => SelfReturnType;

type ComposeFn = {
    <F1 extends ComposedFn>(f1: F1): (...args: Parameters<F1>) => ReturnType<F1>;

    <F1 extends ComposedFn, F2 extends ComposedFnWPredecessor<F1>>(f1: F1, f2: F2): (
        ...args: Parameters<F1>
    ) => ReturnType<F2>;
    <
        F1 extends ComposedFn,
        F2 extends ComposedFnWPredecessor<F1>,
        F3 extends ComposedFnWPredecessor<F2>
    >(
        f1: F1,
        f2: F2,
        f3: F3
    ): (...args: Parameters<F1>) => ReturnType<F3>;

    <
        F1 extends ComposedFn,
        F2 extends ComposedFnWPredecessor<F1>,
        F3 extends ComposedFnWPredecessor<F2>,
        F4 extends ComposedFnWPredecessor<F3>
    >(
        f1: F1,
        f2: F2,
        f3: F3,
        f4: F4
    ): (...args: Parameters<F1>) => ReturnType<F4>;

    <
        F1 extends ComposedFn,
        F2 extends ComposedFnWPredecessor<F1>,
        F3 extends ComposedFnWPredecessor<F2>,
        F4 extends ComposedFnWPredecessor<F3>,
        F5 extends ComposedFnWPredecessor<F4>
    >(
        f1: F1,
        f2: F2,
        f3: F3,
        f4: F4,
        f5: F5
    ): (...args: Parameters<F1>) => ReturnType<F5>;
};

type ComposedFn<Parameters = any, Return = any> = (arg: Parameters) => Return;

type FirstFnParametersType<FNs extends ComposedFn[]> = FNs[0] extends ComposedFn<
    infer ParametersType
>
    ? ParametersType
    : unknown;

type LastFnReturnType<FNs extends ComposedFn[]> = FNs extends [
    ...firstNItems: unknown[],
    lastFn: infer LastFn
]
    ? LastFn extends ComposedFn<unknown, infer ReturnType>
        ? ReturnType
        : unknown
    : unknown;

export const compose: ComposeFn =
    (...fns: ComposedFn[]) =>
    (...p: FirstFnParametersType<typeof fns>): LastFnReturnType<typeof fns> =>
        fns.reduce((acc: any[], cur: (...args: any[]) => any) => {
            const result = cur(...acc);
            return Array.isArray(result) ? result : [result];
        }, p)[0];
