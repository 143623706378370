import React, { useCallback, useEffect } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteUserRoleMutation, useGetUserRolesQuery } from '@/transport/usersApi';
import { useNotification } from '@/components/Notification';
import {
    ManageDialog,
    ManageDialogActions,
    ManageDialogContent,
    ManageDialogTitle,
} from '@/components/ManageDialog';

const DeleteUserRoleDialog: React.FC = () => {
    const navigate = useNavigate();
    const { roleId } = useParams();

    const { data: rolesData } = useGetUserRolesQuery();
    const [deleteUserRole, { isSuccess, isLoading, isError }] = useDeleteUserRoleMutation();

    const handleClose = useCallback(() => {
        navigate('/users/roles');
    }, [navigate]);

    const handleDelete = () => {
        if (!roleId) return;
        deleteUserRole({ id: roleId });
    };

    const { notify } = useNotification();

    useEffect(() => {
        if (isError) {
            notify({ message: 'Error while deleting role', type: 'error' });
        }
    }, [isError, notify]);

    useEffect(() => {
        if (isSuccess) {
            notify({ message: 'Role deleted successfully', type: 'success' });
            handleClose();
        }
    }, [handleClose, isSuccess, notify]);

    const currentRoleData = rolesData?.data.find((role) => role.id === roleId);

    return (
        <ManageDialog open={true} onClose={handleClose}>
            <ManageDialogTitle>Delete role</ManageDialogTitle>
            <ManageDialogContent>
                {!currentRoleData && (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}

                {currentRoleData && (
                    <Typography variant='body2'>
                        Are you sure want to delete role &quot;
                        {currentRoleData.title}&quot; ({currentRoleData.code})?
                    </Typography>
                )}
            </ManageDialogContent>
            <ManageDialogActions>
                <Button
                    color='secondary'
                    onClick={handleClose}
                    disabled={isLoading || !currentRoleData}
                >
                    Cancel
                </Button>
                <Button
                    type='submit'
                    color='primary'
                    onClick={handleDelete}
                    disabled={isLoading || !currentRoleData || isSuccess}
                >
                    Delete
                </Button>
            </ManageDialogActions>
        </ManageDialog>
    );
};

export { DeleteUserRoleDialog };
