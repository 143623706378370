import React, { FC } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { StarsRating } from '../StarsRating';
import { MerchantLogo } from '../MerchantCard/MerchantCard';
import { DefaultMerchantLogo } from '../DefaultMerchantLogo';
import { Nullable } from '../../types';

type MerchantTitleAndRatingProps = {
    name: string;
    rating?: number;
    merchantLogo?: string;
    color?: Nullable<string>;
};

const Container = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

export const MerchantTitleAndRating: FC<MerchantTitleAndRatingProps> = ({
    name,
    merchantLogo,
    rating,
    color,
}) => {
    return (
        <Container>
            <Box overflow='hidden'>
                <Typography variant='h2' component='h1' noWrap>
                    {name}
                </Typography>
                {rating && <StarsRating rating={rating} />}
            </Box>
            {merchantLogo ? (
                <MerchantLogo src={merchantLogo} />
            ) : (
                <DefaultMerchantLogo name={name} color={color} />
            )}
        </Container>
    );
};
