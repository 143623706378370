import { AppBar, Toolbar, styled, useScrollTrigger } from '@mui/material';
import { FC, ReactElement, cloneElement } from 'react';
import { mobileLayoutContentPadding } from '@/ui-kit/constants';

export const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
  paddingLeft: theme.spacing(mobileLayoutContentPadding),
  paddingRight: theme.spacing(mobileLayoutContentPadding),
}));

export const AppBarStyled = styled(AppBar)(({ theme }) => ({
  backgroundColor: `${theme.palette.background.default}!important`,
  boxShadow: `${theme.shadows[3]} !important`,
}));

export const ElevationScroll: FC<{ children: ReactElement }> = ({ children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};
