import { createSvgIcon } from '@mui/material/utils';
import liberRed from '../theme/colors/liberRed';

export const ScanQRIcon = createSvgIcon(
    <>
        <path d='M8.727 19.363H5.453a.83.83 0 01-.578-.238.83.83 0 01-.238-.578v-3.274a.819.819 0 10-1.637 0v3.274A2.454 2.454 0 005.453 21h3.274a.817.817 0 100-1.637zm11.457-4.91a.819.819 0 00-.82.82v3.274a.83.83 0 01-.239.578.83.83 0 01-.578.238h-3.274a.819.819 0 100 1.637h3.274A2.454 2.454 0 0021 18.547v-3.274a.817.817 0 00-.816-.82zM18.547 3h-3.274a.817.817 0 100 1.637h3.274a.83.83 0 01.578.238.83.83 0 01.238.578v3.274a.819.819 0 101.637 0V5.453A2.454 2.454 0 0018.547 3zM3.817 9.547a.819.819 0 00.82-.82V5.453a.83.83 0 01.238-.578.83.83 0 01.578-.238h3.274a.819.819 0 100-1.637H5.453A2.454 2.454 0 003 5.453v3.274a.817.817 0 00.816.82zm6.546-3.274H7.09a.822.822 0 00-.578.239.822.822 0 00-.239.578v3.273c0 .219.086.426.239.578a.826.826 0 00.578.243h3.273a.82.82 0 00.578-.243.82.82 0 00.243-.578V7.09a.826.826 0 00-.243-.578.816.816 0 00-.578-.239zm-.816 3.274H7.91V7.91h1.637zm4.09 1.637h3.273c.215 0 .426-.09.578-.243a.816.816 0 00.239-.578V7.09a.822.822 0 00-.239-.578.822.822 0 00-.578-.239h-3.273a.816.816 0 00-.578.239.826.826 0 00-.243.578v3.273c0 .219.09.426.243.578a.82.82 0 00.578.243zm.816-3.274h1.637v1.637h-1.637zm-4.09 4.906H7.09a.826.826 0 00-.578.243.816.816 0 00-.239.578v3.273c0 .215.086.426.239.578a.822.822 0 00.578.239h3.273a.816.816 0 00.578-.239.826.826 0 00.243-.578v-3.273a.82.82 0 00-.243-.578.82.82 0 00-.578-.243zm-.816 3.274H7.91v-1.637h1.637zm4.09-.817a.81.81 0 00.578-.242.816.816 0 00.238-.578.816.816 0 00.578-.238.81.81 0 000-1.156.82.82 0 00-.578-.243h-.816a.82.82 0 00-.578.243.82.82 0 00-.243.578v.816c0 .219.09.426.243.578.152.156.359.242.578.242zm3.273-2.457a.82.82 0 00-.578.243.803.803 0 00-.242.578v2.453a.81.81 0 00-.578.242.822.822 0 000 1.156.822.822 0 00.578.239h.82a.822.822 0 00.578-.239.822.822 0 00.239-.578v-3.273a.816.816 0 00-.239-.578.826.826 0 00-.578-.243zm-3.273 3.274c-.164 0-.32.05-.453.14a.8.8 0 00-.305.368.847.847 0 00-.047.472.862.862 0 00.227.418.814.814 0 001.258-.125.816.816 0 00-.102-1.031.81.81 0 00-.578-.242zm0 0' />
        <path
            d='M3 12c0-.277.223-.5.5-.5h17c.277 0 .5.223.5.5s-.223.5-.5.5h-17A.498.498 0 013 12zm0 0'
            fillRule='evenodd'
            fill={liberRed[400]}
        />
        <path
            d='M5 12c0 .55-.45 1-1 1s-1-.45-1-1 .45-1 1-1 1 .45 1 1zm0 0M21 12c0 .55-.45 1-1 1s-1-.45-1-1 .45-1 1-1 1 .45 1 1zm0 0'
            fill={liberRed[400]}
        />
    </>,
    'ScanQRIcon'
);
