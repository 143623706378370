import { Box, styled } from '@mui/material';

export const CouponBgImgWrapper = styled(Box)(({ color }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  objectFit: 'contain',
  zIndex: -1,
  filter: 'drop-shadow(4px 2px 4px rgba(24, 39, 75, 0.12))',
  '& .coupon-card': {
    fill: color,
  },
}));
