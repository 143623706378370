import { Grid, Paper, styled } from '@mui/material';
import { FC } from 'react';
import { LinkMainPage } from '../../components/Link';

// import { useTranslation } from 'react-i18next';
// import { PageSubtitle } from '@/ui-kit/components';
// import { t } from 'i18next';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export const MainComponent: FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Item>
                    <LinkMainPage link={'/show-transfer-volume'} title='SHOW TRANSFER VOLUME' />
                </Item>
            </Grid>
            <Grid item xs={12}>
                <Item>
                    <LinkMainPage link={'/search-transactions'} title='SEARCH TRANSACTIONS' />
                </Item>
            </Grid>
        </Grid>
    );
};
