import React from 'react';
import { useTranslation } from '@/ui-kit/hooks';
import { Box, IconButton, Stack } from '@mui/material';
import { PageTitle } from '@/ui-kit/components';
import { MerchantInfo } from '../components/MerchantInfo';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const DesktopMerchantInfoPage = () => {
    const { t } = useTranslation('statements');
    const navigate = useNavigate();

    return (
        <Box
            sx={({ spacing }) => ({
                pb: spacing(5),
            })}
        >
            <Stack direction='row' justifyContent='space-between'>
                <Box>
                    <PageTitle>
                        <IconButton onClick={() => navigate('../')}>
                            <ArrowBackIcon />
                        </IconButton>
                        {t('Merchant info')}
                    </PageTitle>
                </Box>
            </Stack>
            <MerchantInfo />
        </Box>
    );
};

export default DesktopMerchantInfoPage;
