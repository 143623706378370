import { styled } from '@mui/material';
import { FC } from 'react';

const MerchantLogoInner = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1.5),
  left: theme.spacing(1.5),
  right: theme.spacing(1.5),
  margin: 'auto',
  height: theme.spacing(3),
  borderRadius: theme.spacing(0.5),
}));

export const MerchantLogo: FC<{ image: string }> = ({ image }) => (
  <MerchantLogoInner style={{ background: `center / contain no-repeat url(${image})` }} />
);
