import BigNumber from 'bignumber.js';
import {
    DEFAULT_FIAT_CURRENCY_NAME,
    DEFAULT_FIAT_CURRENCY_SIGN,
    USD_CURRENCY_NAME,
    USD_CURRENCY_SIGN,
} from '../constants';

const roundingMode: BigNumber.RoundingMode = BigNumber.ROUND_DOWN;

const currencyFormat: BigNumber.Format = {
    decimalSeparator: '.',
    groupSize: 3,
};

export const euroFormat: BigNumber.Format = {
    ...currencyFormat,
    groupSeparator: ',',
    prefix: DEFAULT_FIAT_CURRENCY_SIGN,
};

export const usdFormat: BigNumber.Format = {
    ...currencyFormat,
    groupSeparator: ',',
    prefix: USD_CURRENCY_SIGN,
};

const statementCardEuroFormat: BigNumber.Format = {
    suffix: ` ${DEFAULT_FIAT_CURRENCY_SIGN}`,
    decimalSeparator: '.',
};
export const euroStatementFormat: BigNumber.Format = {
    ...currencyFormat,
    groupSeparator: ',',
    prefix: `${DEFAULT_FIAT_CURRENCY_SIGN} `,
};

const currencyStatementFormat: BigNumber.Format = {
    ...currencyFormat,
    groupSeparator: ',',
};

export const EMPTY_PLACEHOLDER = '--';

export const formatCurrencyMoney = (
    value?: number,
    opts?: { prefix?: string; ignoreNegative?: boolean }
): string => {
    const { prefix = '', ignoreNegative = false } = opts ?? {};
    if (value === undefined || Number.isNaN(value)) {
        return `${prefix} ${EMPTY_PLACEHOLDER}`;
    }
    let bn = new BigNumber(value);
    if (ignoreNegative) {
        bn = bn.abs();
    } else if (bn.isNegative()) {
        return bn
            .abs()
            .toFormat(0, roundingMode, { ...currencyFormat, prefix: prefix ? `${prefix} -` : '-' });
    }
    const result = bn.toFormat(0, roundingMode, {
        ...currencyFormat,
        prefix: prefix ? `${prefix} ` : '',
    });
    return result;
};

export const formatMoney = (
    value?: number,
    opts?: { prefix?: string; noDecimalIfNatural?: boolean },
    format?: BigNumber.Format
): string => {
    const { prefix = format?.prefix || DEFAULT_FIAT_CURRENCY_SIGN, noDecimalIfNatural = false } =
        opts ?? {};
    if (value === undefined || Number.isNaN(value)) {
        return `${prefix}${EMPTY_PLACEHOLDER}`;
    }
    const bn = new BigNumber(value);
    const targetDecimalPlaces = (noDecimalIfNatural ? bn.dp() : null) ?? 2;

    if (bn.isNegative()) {
        return bn
            .abs()
            .decimalPlaces(targetDecimalPlaces, roundingMode)
            .toFormat({
                ...format,
                prefix: `-${prefix}`,
            });
    }
    return bn.toFormat(targetDecimalPlaces, roundingMode, format);
};

export const formatEuroMoney = (
    value?: number,
    opts?: { noDecimalIfNatural?: boolean }
): string => {
    return formatMoney(
        value,
        {
            prefix: DEFAULT_FIAT_CURRENCY_SIGN,
            noDecimalIfNatural: opts?.noDecimalIfNatural,
        },
        euroFormat
    );
};

export const formatUsdMoney = (
    value?: number,
    opts?: { prefix?: string; noDecimalIfNatural?: boolean }
): string => {
    return formatMoney(
        value,
        {
            prefix: USD_CURRENCY_SIGN,
            noDecimalIfNatural: opts?.noDecimalIfNatural,
        },
        usdFormat
    );
};

export const formatDefaultFiatCurrency = (
    value?: number,
    opts?: { noDecimalIfNatural?: boolean; currencyName?: string }
) => {
    const formatMoneyOpts = {
        noDecimalIfNatural: opts?.noDecimalIfNatural,
    };
    switch (opts?.currencyName || DEFAULT_FIAT_CURRENCY_NAME) {
        case USD_CURRENCY_NAME:
            return formatUsdMoney(value, formatMoneyOpts);
        case DEFAULT_FIAT_CURRENCY_NAME:
        default:
            return formatEuroMoney(value, formatMoneyOpts);
    }
};

export const formatStatementCardEuroMoney = (value?: number): string =>
    value === undefined
        ? `${EMPTY_PLACEHOLDER}${statementCardEuroFormat.suffix}`
        : new BigNumber(value).toFormat(2, roundingMode, statementCardEuroFormat);

export const formatStatementEuroMoney = (value?: number): string => {
    if (value === undefined || Number.isNaN(value)) {
        return `${euroStatementFormat.prefix}${EMPTY_PLACEHOLDER}`;
    }
    const bn = new BigNumber(value);
    if (bn.isNegative()) {
        return bn
            .abs()
            .decimalPlaces(2, roundingMode)
            .toFormat({
                ...euroStatementFormat,
                prefix: `-${euroStatementFormat.prefix}`,
            });
    }
    return bn.decimalPlaces(2, roundingMode).toFormat({
        ...euroStatementFormat,
        prefix: `+${euroStatementFormat.prefix}`,
    });
};

export const formatStatementMoney = (value?: number): string => {
    if (value === undefined || Number.isNaN(value)) {
        return `${EMPTY_PLACEHOLDER}`;
    }
    const bn = new BigNumber(value);
    if (bn.isNegative()) {
        return bn
            .abs()
            .decimalPlaces(2, roundingMode)
            .toFormat({
                ...currencyStatementFormat,
                prefix: '-',
            });
    }
    return bn.decimalPlaces(2, roundingMode).toFormat({
        ...currencyStatementFormat,
        prefix: '',
    });
};
