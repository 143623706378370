import { styled } from '@mui/material';
import Button from '@mui/material/Button';

export const PrimaryLightButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    '&:hover, &:active': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
    },
}));
