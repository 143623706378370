import { createSvgIcon } from '@mui/material/utils';

export const ChevronLeftIcon = createSvgIcon(
    <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.6805 5.32372C16.1065 5.75536 16.1065 6.45517 15.6805 6.8868L10.6337 12L15.6805 17.1132C16.1065 17.5448 16.1065 18.2446 15.6805 18.6763C15.2545 19.1079 14.5637 19.1079 14.1377 18.6763L8.31952 12.7815C7.89349 12.3499 7.89349 11.6501 8.31952 11.2185L14.1377 5.32372C14.5637 4.89209 15.2545 4.89209 15.6805 5.32372Z'
    />,
    'ChevronLeftIcon'
);
