import { Typography } from '@mui/material';
import { Box, styled } from '@mui/system';

export const InfoCard = styled(Box)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}));

export const InfoTitle = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
}));

export const InfoContent = styled(Typography)(({ theme }) => ({
    fontSize: 18,
    fontWeight: 'bold',
}));
