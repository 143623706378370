import React, { FC, ReactNode } from 'react';
import { Box, BoxProps, styled } from '@mui/material';
import { TypographyBody5 } from '../Typography/TypographyBody5';

export const ShareButtonContainer = styled(Box)(() => ({
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
}));

export const ShareButtonIcon = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1.25),
    lineHeight: 1,
}));

type ShareButtonProps = {
    icon: ReactNode;
    color: string;
    title: string;

    target?: string;
    href?: string;
    component?: React.ElementType; // MUIv6 styled Box issue
} & BoxProps;

export const ShareButton: FC<ShareButtonProps> = ({ icon, color, title, ...props }) => {
    return (
        <ShareButtonContainer {...props}>
            <ShareButtonIcon
                sx={{
                    background: color,
                }}
            >
                {icon}
            </ShareButtonIcon>
            <TypographyBody5
                sx={(theme) => ({
                    marginTop: theme.spacing(1),
                    color: theme.palette.text.primary,
                })}
            >
                {title}
            </TypographyBody5>
        </ShareButtonContainer>
    );
};
