import { BreakpointsOptions, createTheme, PaletteOptions, ThemeOptions } from '@mui/material';
import liberBlack from './colors/liberBlack';
import liberGreen from './colors/liberGreen';
import liberRed from './colors/liberRed';
import liberWhite from './colors/liberWhite';
import { DialogShowUpTransition } from '../components/DialogShowTransition/DialogShowUpTransition';

const palette: PaletteOptions = {
    text: {
        primary: liberBlack[300],
        secondary: liberBlack[100],
        disabled: liberBlack[100],
    },
    primary: { main: liberRed[400], light: liberRed[100] },
    secondary: { main: liberBlack[100] },
    background: {
        default: liberWhite[300],
        paper: liberWhite[400],
    },
    common: {
        black: liberBlack[400],
        white: liberWhite[400],
    },
    success: {
        main: liberGreen[400],
    },
    error: {
        main: liberRed[400],
    },
};

const typography: ThemeOptions['typography'] = {
    fontFamily: "'Manrope', sans-serif",
    fontSize: 16,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 800,
    htmlFontSize: 16,
    h1: {
        fontSize: '2rem',
        fontWeight: 800,
    },
    h2: {
        fontSize: '1.5rem',
        fontWeight: 800,
    },
    h3: {
        fontSize: '1.25rem',
        fontWeight: 800,
    },
    h4: {
        fontSize: '1rem',
        fontWeight: 800,
    },
    h5: {
        fontSize: '0.875rem',
        fontWeight: 800,
    },
    h6: {
        fontSize: '0.75rem',
        fontWeight: 800,
    },
    subtitle1: {
        fontSize: '1.5rem',
        fontWeight: 600,
    },
    subtitle2: {
        fontSize: '1.25rem',
        fontWeight: 600,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    subtitle3: {
        fontSize: '1rem',
        fontWeight: 600,
    },
    subtitle4: {
        fontSize: '0.875rem',
        fontWeight: 600,
    },
    subtitle5: {
        fontSize: '0.75rem',
        fontWeight: 600,
    },
    body1: {
        fontSize: '1.25rem',
        fontWeight: 500,
    },
    body2: {
        fontSize: '1rem',
        fontWeight: 500,
    },
    body3: {
        fontSize: '0.875rem',
        fontWeight: 500,
    },
    body4: {
        fontSize: '0.75rem',
        fontWeight: 500,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    link1: {
        fontSize: '1.5rem',
        fontWeight: 600,
    },
    link2: {
        fontSize: '1.25rem',
        fontWeight: 600,
    },
    link3: {
        fontSize: '1rem',
        fontWeight: 600,
    },
    link4: {
        fontSize: '0.875rem',
        fontWeight: 600,
    },
    link5: {
        fontSize: '0.75rem',
        fontWeight: 600,
    },
};

const breakpoints: BreakpointsOptions = {
    values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1040,
        xl: 1232,
    },
};

const baseBorderRadius = 10;

const shape: ThemeOptions['shape'] = { borderRadius: baseBorderRadius * 1.2 };
const shadows: ThemeOptions['shadows'] = [
    'none',
    '0px 2px 1px -1px rgba(18, 20, 23, 12%)',
    '0px 3px 1px -2px rgba(18, 20, 23, 12%)',
    '0px 3px 3px -2px rgba(18, 20, 23, 12%)',
    '0px 2px 4px -1px rgba(18, 20, 23, 12%)',
    '0px 3px 5px -1px rgba(18, 20, 23, 12%)',
    '0px 3px 5px -1px rgba(18, 20, 23, 12%)',
    '0px 4px 5px -2px rgba(18, 20, 23, 12%)',
    '0px 5px 5px -3px rgba(18, 20, 23, 12%)',
    '0px 5px 6px -3px rgba(18, 20, 23, 12%)',
    '0px 6px 6px -3px rgba(18, 20, 23, 12%)',
    '0px 6px 7px -4px rgba(18, 20, 23, 12%)',
    '0px 7px 8px -4px rgba(18, 20, 23, 12%)',
    '0px 7px 8px -4px rgba(18, 20, 23, 12%)',
    '0px 7px 9px -4px rgba(18, 20, 23, 12%)',
    '0px 8px 9px -5px rgba(18, 20, 23, 12%)',
    '0px 8px 10px -5px rgba(18, 20, 23, 12%)',
    '0px 8px 11px -5px rgba(18, 20, 23, 12%)',
    '0px 9px 11px -5px rgba(18, 20, 23, 12%)',
    '0px 9px 12px -6px rgba(18, 20, 23, 12%)',
    '0px 10px 13px -6px rgba(18, 20, 23, 12%)',
    '0px 10px 13px -6px rgba(18, 20, 23, 12%)',
    '0px 10px 14px -6px rgba(18, 20, 23, 12%)',
    '0px 11px 14px -7px rgba(18, 20, 23, 12%)',
    '0px 11px 15px -7px rgba(18, 20, 23, 12%)',
];

const components: ThemeOptions['components'] = {
    MuiButtonBase: {
        defaultProps: {
            disableRipple: true,
        },
        styleOverrides: {
            root: {
                transitionProperty: 'background-color, box-shadow, border-color, color, transform',
                transition: 'transform 2500ms',
                '&:hover': {
                    filter: 'brightness(1.075)',
                },
                '&:active': {
                    filter: 'brightness(1.15)',
                },
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: ({ ownerState, theme }) => {
                let padding = 14;
                let fontSize = '1rem';
                let borderRadius = 1.2 * baseBorderRadius;
                if (ownerState.size === 'medium') {
                    padding = 11.75;
                    fontSize = '0.875rem';
                    borderRadius = baseBorderRadius;
                } else if (ownerState.size === 'small') {
                    fontSize = '0.875rem';
                    padding = 7.75;
                    borderRadius = 0.8 * baseBorderRadius;
                }

                const backgroundColor =
                    ownerState.variant === 'text'
                        ? 'transparent'
                        : ownerState.color
                        ? ownerState.color === 'inherit'
                            ? 'inherit'
                            : theme.palette[ownerState.color]?.main
                        : undefined;

                const color = ownerState.color
                    ? ownerState.color === 'inherit'
                        ? 'inherit'
                        : ownerState.variant === 'text'
                        ? theme.palette[ownerState.color]?.main
                        : theme.palette[ownerState.color]?.contrastText
                    : undefined;

                return {
                    padding,
                    fontSize,
                    fontWeight: 500,
                    borderRadius,
                    textTransform: 'unset',
                    transitionProperty:
                        'background-color, box-shadow, border-color, color, transform',
                    '&:hover': {
                        backgroundColor,
                        color,
                    },
                    '&:disabled': {
                        color: theme.palette.action.disabled,
                        boxShadow: theme.shadows[0],
                        backgroundColor: theme.palette.action.disabledBackground,
                    },
                    '&:active': {
                        backgroundColor,
                        color,
                    },
                };
            },
        },
    },
    MuiLink: {
        styleOverrides: {
            root: ({ theme }) => ({
                color: theme.palette.text.primary,
                textDecorationColor: theme.palette.text.primary,
                '&:hover': {
                    color: theme.palette.primary.main,
                    textDecorationColor: theme.palette.primary.main,
                },
            }),
        },
    },
    MuiInputLabel: {
        styleOverrides: {
            root: {
                fontSize: '1rem',
                color: liberBlack[300],
                '&.Mui-focused': {
                    color: liberBlack[300],
                },
                '&.Mui-error': {
                    color: liberRed[400],
                },
            },
        },
        defaultProps: {
            shrink: true,
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: liberBlack[100],
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: liberBlack[200],
                    '& input': {
                        color: liberBlack[400],
                    },
                },
                '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                    borderColor: liberRed[400],
                },
                borderRadius: 0.8 * baseBorderRadius,
            },
            notchedOutline: {
                // border: `2px solid ${liberWhite[100]}`,
                ' legend': {
                    maxWidth: '100%',
                },
            },
            input: {
                color: liberBlack[100],
                // input without value, i.e. placeholder
                '&:not([value=""])': {
                    color: liberBlack[200],
                },
            },
        },
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                fontSize: '1rem',
                borderRadius: 0.8 * baseBorderRadius,
                color: liberBlack[200],
                caretColor: liberRed[400],
                caretWidth: '2px',
            },
        },
    },
    MuiDialogTitle: {
        styleOverrides: {
            root: {
                // MuiDialog apply global left and right paddings
                paddingLeft: 32,
                paddingRight: 32,
            },
        },
    },
    MuiDialogContent: {
        styleOverrides: {
            root: {
                // MuiDialog apply global left and right paddings
                paddingLeft: 32,
                paddingRight: 32,
            },
        },
    },
    MuiDialogActions: {
        styleOverrides: {
            root: {
                // MuiDialog apply global left and right paddings
                paddingLeft: 32,
                paddingRight: 32,
            },
        },
    },
    MuiDialog: {
        defaultProps: {
            TransitionComponent: DialogShowUpTransition,
        },
        styleOverrides: {
            paper: {
                paddingTop: 16,
                paddingBottom: 24,
                backgroundColor: liberWhite[300],
            },
        },
    },
    MuiAccordion: {
        styleOverrides: {
            root: {
                background: 'transparent',
            },
        },
    },
    MuiListItemIcon: {
        styleOverrides: {
            root: {
                minWidth: 38,
            },
        },
    },
};

export const theme = createTheme({ palette, typography, breakpoints, shape, shadows, components });

export type ThemeType = typeof theme;
