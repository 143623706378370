import React, { FC, useCallback, useState } from 'react';
import { Box, Button, styled, Paper, PaperProps, Typography } from '@mui/material';
import { getCDNImageUrl } from '../../utils/getCDNImageUrl';
import { TFunction } from 'i18next';
import { MerchantSuggestDialog } from './MerchantSuggestDialog';

const Container = styled((props: PaperProps) => <Paper elevation={3} {...props} />)(
    ({ theme }) => ({
        backgroundColor: theme.palette.primary.light,
        borderRadius: theme.spacing(2),
        padding: theme.spacing(4, 3),
        height: '100%',
        backgroundImage: `url(${getCDNImageUrl('/suggest_merchant_bg.svg')})`,
        backgroundSize: 'cover',
        display: 'flex',
    })
);

const Inner = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: theme.spacing(22),
}));

type MerchantSuggestBannerProps = {
    t: TFunction;
    validationT: TFunction;
    locale: string;
    email?: string;
};

export const MerchantSuggestBanner: FC<MerchantSuggestBannerProps> = ({
    t,
    validationT,
    locale,
    email = '',
}) => {
    const [opened, setOpened] = useState(false);

    const doCloseSuggestForm = useCallback(() => {
        setOpened(false);
    }, [setOpened]);

    const doOpenSuggestForm = useCallback(() => {
        setOpened(true);
    }, [setOpened]);

    return (
        <>
            <Container>
                <Inner>
                    <Typography variant='h3'>{t('merchant_suggest.banner.title')}</Typography>

                    <Button
                        variant='contained'
                        color='primary'
                        type='submit'
                        onClick={doOpenSuggestForm}
                    >
                        {t('merchant_suggest.banner.button')}
                    </Button>
                </Inner>
            </Container>
            {opened && (
                <MerchantSuggestDialog
                    email={email}
                    onClose={doCloseSuggestForm}
                    t={t}
                    validationT={validationT}
                    locale={locale}
                />
            )}
        </>
    );
};
