import React, { FC, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Routes } from './routes';
import CssBaseline from '@mui/material/CssBaseline';
import { removeGlobalLoader } from '@/ui-kit/utils/removeGlobalLoader';
import { SnackbarProvider } from '@/ui-kit/components';
import { Providers } from './components/Providers';

const App: FC = () => {
    useLayoutEffect(removeGlobalLoader, []);
    return (
        <Providers>
            <CssBaseline />
            <Helmet>
                <meta charSet='utf-8' />
                <title>LiberSave – new insight into coupons</title>
            </Helmet>
            <SnackbarProvider>
                <Routes />
            </SnackbarProvider>
        </Providers>
    );
};

export default App;
