import { createSvgIcon } from '@mui/material/utils';

export const CatalogIcon = createSvgIcon(
    <>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M 11.417 2.702 C 8.216 0.782 4.302 0.468 0.836 1.854 C 0.331 2.056 0 2.545 0 3.089 L 0 16.413 C 0 17.306 0.901 17.916 1.73 17.585 C 4.568 16.449 7.774 16.706 10.395 18.279 L 11.692 19.057 C 11.791 19.117 11.897 19.143 12 19.141 C 12.103 19.143 12.209 19.117 12.308 19.057 L 13.605 18.279 C 16.226 16.706 19.432 16.449 22.27 17.585 C 23.099 17.916 24 17.306 24 16.413 L 24 3.089 C 24 2.545 23.669 2.056 23.164 1.854 C 19.698 0.468 15.784 0.782 12.583 2.702 L 12 3.052 L 11.417 2.702 Z M 12.857 5.433 C 12.857 4.959 12.473 4.576 12 4.576 C 11.527 4.576 11.143 4.959 11.143 5.433 L 11.143 16.29 C 11.143 16.763 11.527 17.147 12 17.147 C 12.473 17.147 12.857 16.763 12.857 16.29 L 12.857 5.433 Z'
        />
        <path d='M 1.4 19.767 C 3.713 18.417 6.573 18.417 8.886 19.767 L 10.128 20.491 C 11.285 21.166 12.715 21.166 13.871 20.491 L 15.114 19.767 C 17.427 18.417 20.287 18.417 22.6 19.767 L 22.718 19.835 C 23.127 20.074 23.265 20.599 23.026 21.007 C 22.788 21.416 22.263 21.554 21.854 21.316 L 21.736 21.247 C 19.957 20.209 17.757 20.209 15.978 21.247 L 14.735 21.972 C 13.045 22.958 10.955 22.958 9.265 21.972 L 8.022 21.247 C 6.243 20.209 4.043 20.209 2.264 21.247 L 2.146 21.316 C 1.737 21.554 1.212 21.416 0.974 21.007 C 0.735 20.599 0.873 20.074 1.282 19.835 L 1.4 19.767 Z' />
    </>,
    'CatalogIcon'
);
