import { FC } from 'react';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { Stack } from '@mui/material';
import { TypographySubtitle3 } from '@/ui-kit/components';

export const FormError: FC<{ text: string }> = ({ text }) => (
    <Stack direction='row' spacing={1} alignItems='center'>
        <ErrorRoundedIcon color='error' />
        <TypographySubtitle3 color='error'>{text}</TypographySubtitle3>
    </Stack>
);
