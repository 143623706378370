import React, { FC } from 'react';
import { ElevatedPaper } from '../ElevatedPaper';
import { styled, Stack, Typography, PaperProps, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type ElevatedBannerProps = {
    title: string;
    description: string;

    paperProps?: PaperProps;

    onClose: () => void;
};

const Container = styled(ElevatedPaper)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    position: 'relative',
}));

export const ElevatedBanner: FC<ElevatedBannerProps> = ({
    title,
    description,
    paperProps,
    onClose,
}) => {
    return (
        <Container {...paperProps}>
            <IconButton
                onClick={onClose}
                color='secondary'
                sx={{
                    position: 'absolute',
                    right: '16px',
                    top: '16px',
                }}
            >
                <CloseIcon
                    sx={{
                        width: 16,
                        height: 16,
                    }}
                />
            </IconButton>

            <Stack
                direction='row'
                spacing={2}
                justifyContent='space-between'
                alignItems='flex-start'
            >
                <Stack direction='column' justifyContent='space-around' gap={0}>
                    <Typography variant='h4'>{title}</Typography>
                    <Typography
                        variant='body2'
                        color='secondary'
                        sx={{
                            mt: 1.5,
                        }}
                    >
                        {description}
                    </Typography>
                </Stack>
            </Stack>
        </Container>
    );
};
