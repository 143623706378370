export type Coupon = {
    abbr: string;
    name: string;
    emitent: {
        id: string;
        legalName: string | null;
        email: string | null;
    };
};

export type CouponResponseRaw = { data: Coupon[] };
export type CouponResponse = Coupon[];

export type TransferVolumeRequest = {
    dateStart: string; // YYYY-MM-DD
    dateEnd: string; // YYYY-MM-DD
    coupons?: string[];
};

export type TransferVolumeResponse = {
    data: {
        date: string;
        totalAmountApproved: number;
        totalAmountCollected: number;
        totalAmountProcessed: number;
    }[];
};

export enum StatementStatus {
    Success = 'SUCCESS',
    Pending = 'PENDING',
    Canceled = 'CANCELED',
    PartialComplete = 'PARTIAL_COMPLETE',
    Uncredited = 'UNCREDITED',
    Unknown = 'UNKNOWN_STATUS',
}
// TODO GET MORE NAMES
// export type PhaseName =
// | 'INITIATED'
// | 'INSTITUTION_SUBMITTED'
// | 'AUTHORISATION_INITIATED'
// | 'VALIDATION_COMPLETED'
// | 'AUTHORISATION_FAILED'
// | 'FINISHED';

export type PhaseType = {
    phaseName: string;
    phaseCreatedAt: string;
};
export type AccountIdentifications = {
    type: string;
    identification: string;
};

export type TransfersType = {
    id: string;
    realm_id: string;
    user_id: string;
    transfer_id: string;
    tariff_id: string;
    plan_id: string;
    txtype: string;
    amount: number;
    balance: number;
    currency: string;
    acc_no: string;
    acc_other: string;
    tariff: string;
    plan: string;
    ref_id: string;
    ctime: string;
    description_src: string;
    description_dst: string;
    action_code_src: string;
    action_code_dst: string;
};

export type MerchantsStatementType = {
    id: string;
    merchantOrderId: string | null;
    merchantId: string | null;
    institutionCountryCode?: string;
    institutionId?: string;
    status: StatementStatus;
    coupon: string;
    currency: string;
    datetime: string;
    datetimeEnd: string;
    isDirectPayment: boolean;
    amount: number;
    amountCoupons: number;
    fee: number;
    feeCoupons: number;
    cashback: number;
    cashbackCoupons: number;
    resultAmount: number;
    resultAmountCoupons: number;
};

export type MerchantsStatementsRequest = {
    dateStart?: string; // YYYY-MM-DD
    dateEnd?: string; // YYYY-MM-DD
    coupons?: string[];
    someOrderIds?: string[];
    institutionIds?: string[];
    institutionCountryCodes?: string[];
    statuses?: string[];
    isTestTransfers?: boolean;
    limit?: number;
    offset?: number;
};
export type MerchantsStatementsResponse = {
    paging: {
        totalItems: number;
    };
    data: MerchantsStatementType[];
};

export type DataByOrderIdRequest = string;

export type DataByOrderIdResponse = {
    id: string;
    merchantOrderId: string | null;
    merchantId: string | null;
    status: StatementStatus;
    coupon: string;
    currency: string;
    datetime: string;
    datetimeEnd: string;
    isDirectPayment: boolean;
    amount: number;
    amountCoupons: number;
    fee: number;
    feeCoupons: number;
    cashback: number;
    cashbackCoupons: number;
    resultAmount: number;
    resultAmountCoupons: number;
    paymentMethod: string;
    // openbanking: {
    //     paymentRequestDetails: {
    //         reference: string;
    //         contextType: string;
    //         type: string;
    //         payee: {
    //             name: string;
    //             accountIdentifications: AccountIdentifications[];
    //             address: {
    //                 postCode: string;
    //                 country: string;
    //             };
    //             accountIdentificationsValid: boolean;
    //         };
    //         payer: {
    //             accountIdentificationsValid: boolean;
    //             accountIdentifications: AccountIdentifications[];
    //         };
    //         amountDetails: {
    //             amountToPay: number;
    //             currency: string;
    //         };
    //     };
    //     payment: {
    //         status: string;
    //         phases: PhaseType[];
    //     };
    // };
    openbanking?: {
        data: unknown;
        isFundsCollected: boolean;
        referenceId: string;
        institutionId?: string;
        institutionCountryCode?: string;
    };
    // yapilyPaymentRequest: {
    //     meta: {
    //         tracingId: string;
    //     };
    //     data: {
    //         paymentRequestId: string;
    //         userId: string;
    //         applicationUserId: string;
    //         applicationId: string;
    //         institutionIdentifiers: {
    //             institutionId: string;
    //             institutionCountryCode: string;
    //         };
    //         redirectUrl: string;
    //         paymentRequestDetails: {
    //             reference: string;
    //             contextType: string;
    //             type: string;
    //             payee: {
    //                 name: string;
    //                 accountIdentifications: {
    //                     type: string;
    //                     identification: string;
    //                 }[];
    //                 address: {
    //                     postCode: string;
    //                     country: string;
    //                 };
    //                 accountIdentificationsValid: boolean;
    //             };
    //             payer: {
    //                 accountIdentificationsValid: boolean;
    //                 accountIdentifications: [];
    //             };
    //             amountDetails: {
    //                 amountToPay: number;
    //                 currency: string;
    //             };
    //         };
    //         createdAt: string;
    //         authorisationExpiresAt: string;
    //         payments: {
    //             hostedPaymentId: string;
    //             institutionIdentifiers: {
    //                 institutionId: string;
    //                 institutionCountryCode: string;
    //             };
    //             phases: {
    //                 phaseName: 'INITIATED' | 'FINISHED' | string;
    //                 phaseCreatedAt: string;
    //             }[];
    //             paymentStatus: 'PENDING' | string;
    //             statusDetails: [];
    //             paymentIdempotencyId: string;
    //             reference: string;
    //             contextType: 'OTHER' | string;
    //             type: 'DOMESTIC_PAYMENT' | string;
    //             payee: {
    //                 name: string;
    //                 accountIdentifications: {
    //                     type: 'IBAN' | string;
    //                     identification: string;
    //                 }[];
    //                 address: {
    //                     postCode: string;
    //                     country: string;
    //                 };
    //                 accountIdentificationsValid: boolean;
    //             };
    //             payer: {
    //                 accountIdentificationsValid: boolean;
    //                 accountIdentifications: [];
    //             };
    //             amount: {
    //                 amount: number;
    //                 currency: string;
    //             };
    //         }[];
    //     };
    // } | null;
    webhookEvents: Record<string, unknown> | null;
    transfers: TransfersType[];
};

export type GetExportedStatementsByCouponsRequest = {
    coupons: string[];
    // deliveryType: 'download';
    dateStart: string; // "2023-01-01",
    dateEnd: string; // "2024-09-02"
};

export type GetExportedStatementsByCouponsResponse = { downloadUrl: string };

export type GetMerchantsRequest = { limit?: number; offset?: number } | void;

export type StatMerchant = {
    balances: { bank: number; platformFee: number };
    coupon: string;
    email: string;
    firstName: string | null;
    lastName: string | null;
    legalName: string | null;
    id: string;
    isActivated: boolean;
    isRemoved: boolean;
    lastOperationDate: string | null;
    lastOperationTariff: string | null;
    platformIncome: { total: number };
};

export type StatMerchantExtended = StatMerchant & {
    stat: {
        couponExchangeRate: number;
        balance: number;
        debt: {
            platformFee: number;
        };
        withdrawal: {
            total: number;
            available: number;
        };
        sales: {
            issuedTotal: number;
            holdTotal: number;
            soldTotal: number;
            soldMonth: number;
            soldToday: number;
            redeemedTotal: number;
        };
        revenue: {
            total: number;
            month: number;
            today: number;
        };
    };
};

export type GetMerchantsResponse = {
    paging: {
        totalItems: number;
    };
    data: StatMerchant[];
};

export type GetMerchantByIdRequest = { id: string };

export type GetMerchantByIdResponse = StatMerchantExtended;

export type GetDirectoryDataRequest = void;

export type GetDirectoryDataResponse = {
    data: {
        coupons: Coupon[];
        institutionIds: string[];
        institutionCountryCodes: string[];
    };
};
