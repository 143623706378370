import { TransactionsCategoryStat } from '@/transport/accountTransactionsApi/types';
import liberWhite from '@/ui-kit/theme/colors/liberWhite';
import { formatStatementMoney } from '@/ui-kit/utils';
import { Box, Skeleton, Typography } from '@mui/material';
import { formatMonthYearDate } from '../utils/formatMonthYearDate';

export const TableHead = ({
    dates,
    monthsCount,
    isLoading,
    gridTemplateColumns,
}: {
    dates: string[];
    monthsCount: number;
    isLoading?: boolean;
    gridTemplateColumns: string;
}) => {
    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: gridTemplateColumns,
                gap: 1,
                py: 1,
                borderBottom: `1px solid ${liberWhite[100]}`,
            }}
        >
            {Array.from({ length: monthsCount + 1 }, (_, index) => index).map((_, index) => {
                if (index === 0) return <Box sx={{ minWidth: 0 }} key={index}></Box>;
                if (isLoading || dates.length === 0) return <Skeleton key={index} />;
                return (
                    <Typography
                        sx={{ minWidth: 0 }}
                        variant='body2'
                        color='secondary'
                        key={index}
                        textAlign='center'
                    >
                        {formatMonthYearDate(dates[index - 1])}
                    </Typography>
                );
            })}
        </Box>
    );
};

export const TableRow = ({
    categoryData,
    dates,
    monthsCount,
    isLoading,
    gridTemplateColumns,
}: {
    categoryData?: TransactionsCategoryStat | undefined;
    dates?: string[] | undefined;
    monthsCount: number;
    isLoading?: boolean;
    gridTemplateColumns: string;
}) => {
    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: gridTemplateColumns,
                gap: 1,
                py: 1,
                borderBottom: `1px solid ${liberWhite[100]}`,
                '&:last-child': {
                    pb: 0,
                    borderBottom: 'none',
                },
            }}
        >
            {Array.from({ length: monthsCount + 1 }, (_, index) => index).map((_, index) => {
                if (isLoading || !categoryData || !dates) return <Skeleton key={index} />;
                if (index === 0)
                    return (
                        <Typography sx={{ minWidth: 0 }} variant='body2' key={index}>
                            {categoryData.category}
                        </Typography>
                    );

                const inbound = categoryData.data?.[dates[index - 1]].amountInbound;
                const outbound = categoryData.data?.[dates[index - 1]].amountOutbound;

                return (
                    <Box
                        key={index}
                        sx={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr', gap: 1 }}
                    >
                        <Typography sx={{ minWidth: 0 }} variant='body2' textAlign='end'>
                            {outbound === 0 && '-'}
                            {formatStatementMoney(outbound)}
                        </Typography>
                        <Box sx={{ minWidth: 0, borderRight: `1px solid ${liberWhite[100]}` }} />
                        <Typography sx={{ minWidth: 0 }} variant='body2'>
                            +{formatStatementMoney(inbound)}
                        </Typography>
                    </Box>
                );
            })}
        </Box>
    );
};
