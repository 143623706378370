import { Link } from '@/ui-kit/components/Link';

// import { useTranslation } from 'react-i18next';
// import { PageSubtitle } from '@/ui-kit/components';
// import { t } from 'i18next';

interface LinkMainPageType {
    title: string;
    link: string;
}

export const LinkMainPage = ({ title, link }: LinkMainPageType) => {
    return (
        <Link
            sx={{
                width: '100%',
                color: 'inherit',
                display: 'flex',
                textDecoration: 'none',
                '& a': {
                    width: '100%',
                    color: 'inherit',
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration: 'none',
                    fontWeight: 'inherit',
                },
            }}
            href={link}
        >
            {title}
        </Link>
    );
};
