import { Box, styled, Typography, BoxProps } from '@mui/material';
import { FC, ReactElement } from 'react';
import { formatCurrencyMoney, formatDefaultFiatCurrency } from '@/ui-kit/utils/formatMoney';
import liberRed from '@/ui-kit/theme/colors/liberRed';
import liberWhite from '@/ui-kit/theme/colors/liberWhite';
import { TypographyBody4 } from '@/ui-kit/components';
import { ReactComponent as CouponOverlayImg } from '../Coupon/images/couponOverlay.svg';
import { CouponBgImgWrapper } from '../CouponBgImgWrapper';
import { CouponBgImg } from './components/CouponBgImg';
import { VerticalCouponMerchantLogo } from './components/MerchantLogo';

const CouponOverlayImgWrapper = styled('div')(({ color }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  objectFit: 'contain',
  zIndex: 1,
}));

const Container = styled(Box)(({ theme }) => ({
  width: theme.spacing(16),
  // +2 to leave space for a shadow
  height: theme.spacing(16 + 2),
  paddingTop: theme.spacing(6.5),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),

  // +2 to leave space for a shadow
  paddingBottom: theme.spacing(1.5 + 2),
  position: 'relative',
  zIndex: 1,
}));

const CouponInfo = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  width: '100%',
  height: '100%',
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(1),
  justifyContent: 'space-evenly',
  display: 'flex',
  flexDirection: 'column',
}));

type VerticalCouponProps = BoxProps & {
  balanceFiat: number;
  balanceCurrency: number;
  currency: string;
  color?: string;
  image: ReactElement | string;
  hideBalanceBlock?: boolean;
  onClick?: () => void;
  disabled?: boolean;
};

export const VerticalCoupon: FC<VerticalCouponProps> = ({
  balanceFiat,
  balanceCurrency,
  currency,
  color = liberRed[400],
  image,
  hideBalanceBlock,
  onClick,
  disabled,
  ...props
}) => (
  <Container className='LSVerticalCoupon-root' onClick={onClick} {...props}>
    <CouponBgImgWrapper color={color}>
      <CouponBgImg />
    </CouponBgImgWrapper>
    <VerticalCouponMerchantLogo image={image} />
    <CouponInfo>
      {!hideBalanceBlock && (
        <>
          <Typography variant='h4' fontWeight='bold' noWrap>
            {formatDefaultFiatCurrency(balanceFiat, { noDecimalIfNatural: true })}
          </Typography>
          <Box>
            <TypographyBody4 color={color}>{currency}</TypographyBody4>&nbsp;
            <TypographyBody4 color='textSecondary' noWrap>
              {formatCurrencyMoney(balanceCurrency)}
            </TypographyBody4>
          </Box>
        </>
      )}
    </CouponInfo>
    {disabled && (
      <CouponOverlayImgWrapper>
        <CouponOverlayImg />
      </CouponOverlayImgWrapper>
    )}
  </Container>
);

export const VerticalCouponSkeleton: FC = () => (
  <VerticalCoupon
    balanceFiat={0}
    balanceCurrency={0}
    currency=''
    color={liberWhite[100]}
    image=''
    hideBalanceBlock
  />
);
