import React from 'react';
import { OrderModalPaper } from './OrderModalPaper';
import { Box, styled, Typography } from '@mui/material';
import ReactJson from 'react-json-view';
import { CopyToClipboardButton } from './OrderInfo/components/CopyToClipboardButton';

type OpenBankingProps = {
    openbankingData: unknown | null | undefined;
};

const NoJsonData = styled(Typography)(({ theme }) => ({
    fontSize: 18,
    color: theme.palette.text.secondary,
}));

export const OpenBanking: React.FC<OpenBankingProps> = ({ openbankingData }) => {
    return (
        <OrderModalPaper sx={{ marginTop: '30px' }}>
            <Box sx={{ overflow: 'auto' }}>
                <Typography variant='h2' sx={{ mb: 2 }}>
                    Banking Data
                    <CopyToClipboardButton
                        data={JSON.stringify(openbankingData, null, 2)}
                        notificationText='OpenBanking Data copied to clipboard!'
                        sx={{ ml: 1 }}
                    />
                </Typography>

                {openbankingData ? (
                    <ReactJson
                        src={openbankingData}
                        name='openbanking'
                        style={{ fontSize: 16 }}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        enableClipboard={false}
                    />
                ) : (
                    <NoJsonData>No OpenBanking data</NoJsonData>
                )}
            </Box>
        </OrderModalPaper>
    );
};

type WebhookEventsProps = {
    webhookEventsData: Record<string, unknown> | null;
};

export const WebhookEvents: React.FC<WebhookEventsProps> = ({ webhookEventsData }) => {
    return (
        <OrderModalPaper sx={{ marginTop: '30px' }}>
            <Typography variant='h2' sx={{ mb: 2 }}>
                Webhook Events
                <CopyToClipboardButton
                    data={JSON.stringify(webhookEventsData, null, 2)}
                    notificationText='Webhook Events copied to clipboard!'
                    sx={{ ml: 1 }}
                />
            </Typography>
            <Box sx={{ overflow: 'auto' }}>
                {webhookEventsData ? (
                    <ReactJson
                        src={webhookEventsData}
                        name='webhookEvents'
                        style={{ fontSize: 16 }}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        enableClipboard={false}
                        collapsed
                    />
                ) : (
                    <NoJsonData>No Webhook Events data</NoJsonData>
                )}
            </Box>
        </OrderModalPaper>
    );
};
