import { makeStyles } from '@mui/styles';
import liberRed from '@/ui-kit/theme/colors/liberRed';
import { ThemeType } from '@/ui-kit/theme';
import liberWhite from '@/ui-kit/theme/colors/liberWhite';
import liberBlack from '@/ui-kit/theme/colors/liberBlack';

export const useStyles = makeStyles((theme: ThemeType) => ({
  sliderContainer: {
    '& .slick-dots': {
      bottom: theme.spacing(-7.5),
      '& li': {
        '& button': {
          '&::before': {
            content: '""',
            width: theme.spacing(1),
            height: theme.spacing(1),
            borderRadius: theme.spacing(1),
            fontSize: 0.5 * theme.typography.fontSize,
            backgroundColor: liberWhite[100],
            opacity: 1,
            transition: '0.5s ease-in-out width',
          },
        },
      },
      '& .slick-active': {
        '& button': {
          '&::before': {
            width: theme.spacing(2),
            backgroundColor: liberRed[400],
            opacity: 1,
          },
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      '& .slick-list': {
        width: theme.spacing(35),
        height: theme.spacing(37.5),
        margin: 'auto',
      },
      '& .slick-dots': {
        bottom: theme.spacing(-1.25),
      },
    },
  },
  subTitle: {
    '&.MuiTypography-root': {
      marginBottom: theme.spacing(2),
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.fontSize,
        marginBottom: theme.spacing(1.25),
      },
    },
  },
  paraText: {
    textAlign: 'center',
    color: liberBlack[100],
    [theme.breakpoints.down('sm')]: {
      fontSize: 0.75 * theme.typography.fontSize,
    },
  },
}));
