import { Box } from '@mui/material';
import { TransactionsFilters } from './TransactionsTable/components/TransactionsFilters';
import { TransactionsTable } from './TransactionsTable/components/TransactionsTable';
import { TransactionsStat } from './TransactionsStat/TransactionsStat';

export const TransactionsPage = () => {
    return (
        <Box>
            <TransactionsStat />
            <TransactionsFilters />
            <TransactionsTable />
        </Box>
    );
};
