import { FC, PropsWithChildren, ReactNode, useMemo } from 'react';
import {
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon as ListItemIconBase,
  ListItemText as ListItemTextBase,
  styled,
} from '@mui/material';
import { useMatch } from 'react-router-dom';
import { Link } from '@/ui-kit/components';

const ListItemText = styled(ListItemTextBase)({ margin: 0 });

const ListItemIcon = styled(ListItemIconBase)(({ theme }) => ({
  minWidth: theme.spacing(2),
  marginRight: theme.spacing(2),
  color: 'inherit',
}));

export const MobileLayoutMenuButton: FC<
  PropsWithChildren<
    | { icon: ReactNode; href: string; disabled?: boolean; strict?: boolean }
    | { icon: ReactNode; onClick: () => void; disabled?: boolean; strict?: boolean }
  >
> = ({ children, icon, disabled, strict, ...props }) => {
  let href: string | undefined = undefined;
  let onClick: (() => void) | undefined = undefined;
  if ('href' in props) {
    href = props.href;
  }
  if ('onClick' in props) {
    onClick = props.onClick;
  }
  const isActive = useMatch(href ? (strict ? href : `${href}/*`) : '') && href;

  const content = useMemo(
    () => (
      <>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            variant: 'h5',
            fontWeight: isActive ? 'bold' : 'regular',
          }}
        >
          {children}
        </ListItemText>
      </>
    ),
    [children, icon, isActive]
  );

  const sx = useMemo<ListItemButtonProps['sx']>(
    () =>
      ({ palette, spacing, shadows }) => ({
        borderTopLeftRadius: spacing(1.5),
        borderBottomLeftRadius: spacing(1.5),
        backgroundColor: isActive ? `${palette.background.paper} !important` : undefined,
        color: isActive ? palette.primary.main : palette.secondary.main,
        p: 2,
        boxShadow: isActive ? shadows[3] : undefined,
      }),
    [isActive]
  );

  if (href) {
    return (
      <ListItemButton sx={sx} disabled={disabled} onClick={onClick}>
        <Link
          sx={{
            width: '100%',
            color: 'inherit',
            display: 'flex',
            textDecoration: 'none',
            '& a': {
              width: '100%',
              color: 'inherit',
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              fontWeight: 'inherit',
            },
          }}
          href={href}
        >
          {content}
        </Link>
      </ListItemButton>
    );
  } else {
    return (
      <ListItemButton sx={sx} disabled={disabled || !onClick} onClick={onClick}>
        {content}
      </ListItemButton>
    );
  }
};
