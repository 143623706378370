import React from 'react';
import { useTranslation } from '@/ui-kit/hooks';
import { Box, Button, Stack } from '@mui/material';
import { ElevatedPaper } from '@/bo-kit/components/ElevatedPaper';
// import InfiniteScroll from 'react-infinite-scroll-component';
import { UserRowSkeleton } from './components/UserRowSkeleton';
import { UserRow } from './components/UserRow';
import { CellHeader, RowHeader } from './components/TableElements';
import { useNavigate } from 'react-router-dom';
import { useGetUsersQuery } from '@/transport/usersApi';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupIcon from '@mui/icons-material/Group';
import { usePermissionGuard } from '@/components/Permissions/usePermissionGuard';
import { Permission } from '@/transport/authApi/types';
import { PermissionGuard } from '@/components/Permissions/PermissionGuard';
import { useGetProfileQuery } from '@/transport/authApi';

export const UsersTable: React.FC = () => {
    const { t } = useTranslation('Users');
    const { checkPermission } = usePermissionGuard();
    const showActions = checkPermission(Permission.UsersEdit);
    const { data: currentUser } = useGetProfileQuery();

    const columns = [
        'Name',
        'Email',
        'Roles',
        'Created',
        'Status',
        ...(showActions ? ['Actions'] : []),
    ];

    const { data: usersData, isError, isLoading } = useGetUsersQuery();

    const navigate = useNavigate();

    const handleCreateUser = () => {
        navigate('/users/create');
    };

    const handleManageRoles = () => {
        navigate('/users/roles');
    };

    return (
        <>
            <Box>
                <PermissionGuard allowingPermission={Permission.UsersEdit}>
                    <Stack
                        direction='row-reverse'
                        sx={({ spacing }) => ({
                            mb: spacing(1),
                            gap: spacing(1),
                        })}
                    >
                        <Button
                            variant='outlined'
                            startIcon={<PersonAddIcon />}
                            onClick={handleCreateUser}
                        >
                            Create user
                        </Button>
                        <Button
                            variant='text'
                            startIcon={<GroupIcon />}
                            onClick={handleManageRoles}
                        >
                            Manage roles
                        </Button>
                    </Stack>
                </PermissionGuard>
            </Box>
            <Box sx={{ overflow: 'auto' }}>
                <Box sx={{ minWidth: '1000px', position: 'relative' }}>
                    <ElevatedPaper
                        sx={({ spacing }) => ({
                            py: spacing(3),
                            px: spacing(5),
                            width: 'fit-content',
                            minWidth: '100%',
                            margin: '0 auto',
                            mb: spacing(1),
                        })}
                    >
                        <div>
                            <RowHeader
                                showActions={showActions}
                                sx={({ spacing }) => ({
                                    mt: spacing(1),
                                    px: spacing(5),
                                    py: spacing(2.5),
                                })}
                            >
                                {columns.map((columnName) => (
                                    <CellHeader key={columnName}>{t(columnName)}</CellHeader>
                                ))}
                            </RowHeader>
                        </div>
                    </ElevatedPaper>
                    <ElevatedPaper
                        sx={({ spacing }) => ({
                            py: spacing(3),
                            px: spacing(5),
                            width: 'fit-content',
                            minWidth: '100%',
                            margin: '0 auto',
                            mb: spacing(4),
                        })}
                    >
                        {isLoading &&
                            Array.from({ length: 8 }, (_, index) => index).map((_, index) => (
                                <UserRowSkeleton showActions={showActions} key={index} />
                            ))}

                        {!isLoading &&
                            !isError &&
                            usersData?.data.map((user) => (
                                <UserRow
                                    key={user.id}
                                    id={user.id}
                                    firstName={user.firstName}
                                    lastName={user.lastName}
                                    email={user.email}
                                    roles={user.roles}
                                    permissions={user.permissions}
                                    isActive={user.isActive}
                                    isAdmin={user.isAdmin}
                                    createdAt={user.createdAt}
                                    updatedAt={user.updatedAt}
                                    showActions={showActions}
                                    disableActions={!currentUser?.isAdmin && user.isAdmin}
                                />
                            ))}
                    </ElevatedPaper>
                </Box>
            </Box>
        </>
    );
};
