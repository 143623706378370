import React, { useCallback, useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { getCMSImageUrl } from '@/ui-kit/utils/getCMSImageUrl';
import { Box, styled, Typography } from '@mui/material';
import { useIsDesktop } from '@/ui-kit/hooks/useIsDesktop';
import { SliderSkeleton } from './components/SliderSkeleton';
import { useStyles } from './hooks/useStyles';
import { useGetSliderItems } from '@/bo-kit/components/ImageSlider/hooks/useGetSliderItems';
import { SliderResponseData } from '../../transport/types/SliderResponseData';
import { useTranslation } from '@/ui-kit/hooks';
import { Language } from '@/ui-kit/types';

type ImageData = {
  imageUrl: string;
  title: string;
  desc: string;
};

const settings = {
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: false,
};

const Img = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  width: '100%',
  marginBottom: theme.spacing(3),
}));

export const ImageSlider: React.FC = () => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const [doGetSliderItems, state] = useGetSliderItems();
  const [images, setImages] = useState<null | ImageData[]>(null);

  useEffect(() => {
    doGetSliderItems((i18n.resolvedLanguage as Language) ?? Language.EN);
  }, [doGetSliderItems, i18n.resolvedLanguage]);

  const setImage = React.useCallback(() => {
    const sliderImages: Array<ImageData> =
      state.data?.data?.attributes?.slider
        .map((p: SliderResponseData) => {
          const imageUrl = getCMSImageUrl(p.image);
          if (imageUrl === null) {
            return null;
          }
          return {
            imageUrl: getCMSImageUrl(p.image),
            title: p.title,
            desc: p.subtitle,
          } as ImageData;
        })
        .filter((item): item is ImageData => !!item) ?? ([] as Array<ImageData>);
    setImages(sliderImages);
  }, [state.data?.data?.attributes?.slider]);

  const resetData = useCallback(() => {
    setImages([]);
  }, []);
  useEffect(() => {
    if (state.data && !state.isError) {
      setImage();
    } else if (state.isError) {
      resetData();
    }
  }, [resetData, setImage, state.data, state.isError]);
  const isDesktop = useIsDesktop();
  return (
    <div className={classes.sliderContainer}>
      {state.isLoading && <SliderSkeleton />}
      {!state.isLoading && (
        <Slider {...settings} infinite={(images?.length || 0) > settings.slidesToShow}>
          {images?.map((item) => (
            <Box
              sx={({ spacing }) => ({
                height: isDesktop ? 'auto' : spacing(8),
              })}
              key={item.imageUrl}
            >
              <div style={{ minHeight: isDesktop ? 350 : 200 }}>
                <Img src={item.imageUrl} alt='sliderImg' />
              </div>
              <Typography variant='h3' className={classes.subTitle}>
                {item.title}
              </Typography>
              <Typography variant='body2' className={classes.paraText}>
                {item.desc}
              </Typography>
            </Box>
          ))}
        </Slider>
      )}
    </div>
  );
};
