import { ThemeType } from '@/ui-kit/theme';
import liberBlack from '@/ui-kit/theme/colors/liberBlack';
import liberWhite from '@/ui-kit/theme/colors/liberWhite';
import { Box, BoxProps, SxProps, Typography } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';

export const CellHeader: FC<PropsWithChildren<{ sx?: SxProps<ThemeType> }>> = ({
    children,
    sx,
}) => (
    <Box sx={{ minWidth: 0, ...sx }}>
        <Typography variant='body2' color='secondary' fontWeight={400} noWrap>
            {children}
        </Typography>
    </Box>
);

export const Cell: FC<{
    sx?: SxProps<ThemeType>;
    primaryText: ReactNode | string;
    secondaryText?: ReactNode | string;
}> = ({ sx, primaryText, secondaryText }) => (
    <Box sx={{ minWidth: 0, ...sx }}>
        <Typography variant='body2' fontWeight={400}>
            {primaryText}
        </Typography>
        {secondaryText && (
            <Typography
                variant='body2'
                sx={({ spacing }) => ({
                    color: liberBlack[100],
                    fontWeight: 400,
                    fontSize: spacing(1.5),
                })}
            >
                {secondaryText}
            </Typography>
        )}
    </Box>
);

const gridTemplateColumns = '8.5fr 11fr 10fr 11fr 11fr 11fr 12fr 7.5fr 7.5fr 13fr';

export const RowHeader: FC<PropsWithChildren<{ sx?: SxProps<ThemeType> }>> = ({ children }) => (
    <Box
        sx={{
            display: 'grid',
            gridTemplateColumns: gridTemplateColumns,
            columnGap: 1,
            // maxWidth: '1400px',
            width: '100%',
        }}
    >
        {children}
    </Box>
);

export const Row: FC<BoxProps & { sx?: SxProps<ThemeType> }> = ({ children, sx, onClick }) => (
    <Box
        onClick={onClick}
        sx={{
            display: 'grid',
            gridTemplateColumns: gridTemplateColumns,
            columnGap: 1,
            // maxWidth: '1400px',
            width: '100%',
            borderBottom: `1px solid ${liberWhite[100]}`,
            py: 2.5,
            '&:last-child': {
                pb: 0,
                borderBottom: 'none',
            },
            ...sx,
        }}
    >
        {children}
    </Box>
);
