import { styled } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { WithSideMenu } from '../DesktopLayout/components/SideMenu';

const Container = styled('div')(({ theme }) => ({
  minWidth: theme.breakpoints.values.xs,
  maxWidth: theme.breakpoints.values.sm,
  paddingTop: theme.spacing(9),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  width: '100%',
  height: '100%',
}));

export const DesktopDummyLayout: FC<PropsWithChildren> = ({ children }) => (
  <WithSideMenu>
    <Container>{children}</Container>
  </WithSideMenu>
);
