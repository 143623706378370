import { FC } from 'react';
import { Box, BoxProps, styled, SxProps, useTheme } from '@mui/material';
import liberWhite from '../../theme/colors/liberWhite';
import { pickTextColorBasedOnBgColorSimple } from './pickTextColorBasedOnBgColorSimple';
import { upperFirst } from 'lodash';
import { TypographySubtitle5 } from '../Typography/TypographySubtitle5';
import { ThemeType } from '../../theme';
import { Nullable } from '../../types';

type Variant = 'square' | 'smallHorizontal';

type DefaultMerchantLogoProps = {
    name: string;
    color?: Nullable<string>;
    variant?: Variant;
    sx?: SxProps<ThemeType>;
};

const Container = styled((props: BoxProps & { variant: Variant }) => <Box {...props} />, {
    shouldForwardProp: (propName) => propName !== 'variant',
})(({ theme, variant }) => ({
    width: variant === 'square' ? theme.spacing(5) : theme.spacing(8),
    height: variant === 'square' ? theme.spacing(5) : theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '1.25rem',
    lineHeight: 1,
    border: `1px solid ${liberWhite[200]}`,
    borderRadius: theme.spacing(1),
}));

export const DefaultMerchantLogo: FC<DefaultMerchantLogoProps> = ({
    name,
    color,
    variant = 'square',
    sx = {},
}) => {
    const { palette } = useTheme();
    const firstLetter = name[0] || '?';
    const backgroundColor = color ? color : palette.primary.main;
    return (
        <Container
            variant={variant}
            sx={{
                backgroundColor,
                color: pickTextColorBasedOnBgColorSimple(backgroundColor),
                ...sx,
            }}
        >
            {variant === 'square' ? (
                firstLetter.toUpperCase()
            ) : (
                <TypographySubtitle5>{upperFirst(name)}</TypographySubtitle5>
            )}
        </Container>
    );
};
