import { FC } from 'react';
import { Cell, Row } from './TableElements';
import { TransactionDirection } from '@/transport/accountTransactionsApi/types';
import { BoxProps, useTheme } from '@mui/material';
import { getStatementUTCDateTimeFormat } from '@/ui-kit/utils';
import { CopyableOrderId } from '@/components/CopyableOrderId';

type TransactionsRowProps = {
    id: string;
    date: string;
    category: string;
    direction: TransactionDirection;
    status: string;
    amount: number;
    currency: string;
    reference: string;
    referenceId: string;
} & BoxProps;

export const TransactionsRow: FC<TransactionsRowProps> = ({
    id,
    date,
    category,
    direction,
    status,
    amount,
    currency,
    reference,
    referenceId,

    onClick,
    sx,
}) => {
    const { palette } = useTheme();

    const getResultAmountColor = (value: number) => {
        if (value > 0) {
            return palette.success.dark;
        } else if (value < 0) {
            return palette.error.dark;
        } else {
            return palette.text.primary;
        }
    };
    return (
        <Row onClick={onClick} sx={sx}>
            <Cell primaryText={<CopyableOrderId value={id} start={4} end={4} />} />
            <Cell primaryText={getStatementUTCDateTimeFormat(date)} />
            <Cell primaryText={category} />
            <Cell primaryText={status} />
            <Cell
                sx={{ color: getResultAmountColor(amount) }}
                primaryText={amount}
                secondaryText={currency}
            />

            <Cell primaryText={referenceId} secondaryText={reference} />
        </Row>
    );
};
