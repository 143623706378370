import { createSvgIcon } from '@mui/material/utils';

export const AccountsIcon = createSvgIcon(
    <>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M 23.997 9.692 L 24 14.231 C 23.968 14.663 23.927 15.095 23.877 15.526 C 23.792 16.247 23.207 16.829 22.473 16.911 C 20.172 17.169 17.79 17.169 15.489 16.911 C 14.754 16.829 14.169 16.247 14.085 15.526 C 13.81 13.172 13.81 10.794 14.085 8.44 C 14.169 7.719 14.754 7.138 15.489 7.056 C 17.79 6.798 20.172 6.798 22.473 7.056 C 23.207 7.138 23.792 7.719 23.877 8.44 C 23.925 8.857 23.965 9.274 23.997 9.692 Z M 18.981 10.104 C 17.945 10.104 17.105 10.945 17.105 11.983 C 17.105 13.021 17.945 13.863 18.981 13.863 C 20.017 13.863 20.857 13.021 20.857 11.983 C 20.857 10.945 20.017 10.104 18.981 10.104 Z'
        />
        <path d='M 23.238 4.516 C 23.433 4.854 23.068 5.231 22.681 5.188 C 20.241 4.915 17.72 4.915 15.281 5.188 C 13.693 5.366 12.409 6.618 12.222 8.222 C 11.93 10.721 11.93 13.246 12.222 15.745 C 12.409 17.349 13.693 18.601 15.281 18.779 C 17.72 19.052 20.241 19.052 22.681 18.779 C 23.07 18.735 23.438 19.115 23.242 19.455 C 22.268 21.137 20.532 22.311 18.514 22.524 L 17.699 22.609 C 13.558 23.046 9.381 23.017 5.246 22.524 L 4.706 22.459 C 2.477 22.193 0.71 20.449 0.414 18.22 C -0.138 14.081 -0.138 9.886 0.414 5.747 C 0.71 3.518 2.477 1.774 4.706 1.507 L 5.246 1.443 C 9.381 0.949 13.558 0.921 17.699 1.357 L 18.514 1.443 C 20.533 1.656 22.267 2.832 23.238 4.516 Z' />
    </>,
    'AccountsIcon'
);
