import React, { useCallback, useEffect, useRef } from 'react';
import { Button, Typography } from '@mui/material';
import UserForm from './components/UserForm';
import { UserData } from '@/transport/usersApi/types';
import { useNavigate } from 'react-router-dom';
import { FormikProps } from 'formik';
import { useCreateUserMutation } from '@/transport/usersApi';
import { useNotification } from '@/components/Notification';
import {
    ManageDialog,
    ManageDialogActions,
    ManageDialogContent,
    ManageDialogTitle,
} from '@/components/ManageDialog';

const CreateUserDialog: React.FC = () => {
    const navigate = useNavigate();
    const formRef = useRef<FormikProps<UserData>>(null);

    const [createUser, { isSuccess, isLoading, isError, data: userData }] = useCreateUserMutation();

    const handleSubmit = (data: UserData) => {
        createUser(data);
    };

    const handleClose = useCallback(() => {
        navigate('/users');
    }, [navigate]);

    const handleCreate = () => {
        formRef.current?.submitForm();
    };

    const { notify } = useNotification();

    useEffect(() => {
        if (isError) {
            notify({ message: 'Error while creating user', type: 'error' });
        }
    }, [isError, notify]);

    useEffect(() => {
        if (isSuccess) {
            notify({ message: 'User created successfully', type: 'success' });
        }
    }, [handleClose, isSuccess, notify]);

    return (
        <ManageDialog open={true} onClose={handleClose}>
            <ManageDialogTitle>Create new user</ManageDialogTitle>
            <ManageDialogContent>
                {!isSuccess && (
                    <UserForm
                        initialValues={{
                            email: '',
                            firstName: '',
                            lastName: '',
                            isActive: true,
                            roles: [],
                        }}
                        onSubmit={handleSubmit}
                        formRef={formRef}
                        disabled={isLoading}
                    />
                )}
                {isSuccess && userData && (
                    <Typography variant='body2'>
                        Save user&apos;s credentials:
                        <Typography fontWeight='bold'>
                            {userData.email} : {userData.password}
                        </Typography>
                    </Typography>
                )}
            </ManageDialogContent>
            <ManageDialogActions>
                <Button color='secondary' onClick={handleClose} disabled={isLoading}>
                    Cancel
                </Button>
                <Button
                    type='submit'
                    color='primary'
                    onClick={handleCreate}
                    disabled={isLoading || isSuccess}
                >
                    Create
                </Button>
            </ManageDialogActions>
        </ManageDialog>
    );
};

export { CreateUserDialog };
