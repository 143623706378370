import { Grid, styled } from '@mui/material';

export const GridStyled = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5 / 2),
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5 / 2),
  },
}));
