import React, { FC, ReactNode } from 'react';
import { LogoIcon } from '@/ui-kit/icons';
import { IconButton, Stack, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TypographyBody3 } from '@/ui-kit/components';

type PWABannerViewProps = {
    onClose: () => void;
    onClick: () => void;
    text: ReactNode;
};

const Container = styled(Stack)(({ theme }) => ({
    background: theme.palette.background.paper,
    boxShadow: '0px -2px 6px -2px rgba(24, 39, 75, 0.12)',
    borderRadius: theme.spacing(1),
    position: 'fixed',
    width: '100%',
    bottom: 0,
    padding: theme.spacing(2, 3),
    zIndex: theme.zIndex.tooltip,
}));

export const PWABannerView: FC<PWABannerViewProps> = ({ onClose, onClick, text }) => {
    return (
        <Container direction='row' spacing={2} alignItems='center' onClick={onClick}>
            <LogoIcon
                sx={{
                    width: 32,
                    height: 32,
                }}
            />

            <TypographyBody3
                sx={({ palette }) => ({
                    color: palette.common.black,
                    flexGrow: 1,
                })}
            >
                {text}
            </TypographyBody3>

            <IconButton onClick={onClose} color='secondary'>
                <CloseIcon />
            </IconButton>
        </Container>
    );
};
