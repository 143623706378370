import { useGetAccountTransactionsStatQuery } from '@/transport/accountTransactionsApi';
import { ElevatedPaper } from '@/ui-kit/components';
import { TableHead, TableRow } from './components/TableElements';
import { Box } from '@mui/material';

const MONTHS_COUNT = 8;
const gridTemplateColumns = `repeat(${MONTHS_COUNT + 1}, 1fr)`;

export const TransactionsStat = () => {
    const { data: transactionsStatData, isLoading } = useGetAccountTransactionsStatQuery({
        monthsCount: MONTHS_COUNT,
    });
    const dates = Object.keys(transactionsStatData?.data[0].data || {});

    return (
        <Box
            sx={({ spacing }) => ({
                overflow: 'auto',
                mb: spacing(8),
            })}
        >
            <Box
                sx={{
                    minWidth: '1000px',
                }}
            >
                <ElevatedPaper
                    sx={({ spacing }) => ({
                        py: spacing(3),
                        px: spacing(5),
                        width: '100%',
                        margin: '0 auto',
                    })}
                >
                    <TableHead
                        gridTemplateColumns={gridTemplateColumns}
                        dates={dates}
                        monthsCount={MONTHS_COUNT}
                        isLoading={isLoading}
                    />
                    {transactionsStatData?.data.map((categoryData, index) => (
                        <TableRow
                            gridTemplateColumns={gridTemplateColumns}
                            categoryData={categoryData}
                            dates={dates}
                            monthsCount={MONTHS_COUNT}
                            isLoading={isLoading}
                            key={categoryData.category + index}
                        />
                    ))}
                    {isLoading &&
                        Array.from({ length: 2 }, (_, index) => index).map((_, index) => (
                            <TableRow
                                gridTemplateColumns={gridTemplateColumns}
                                monthsCount={MONTHS_COUNT}
                                isLoading={isLoading}
                                key={'skeleton' + index}
                            />
                        ))}
                </ElevatedPaper>
            </Box>
        </Box>
    );
};
