import { useGetCouponsQuery, useGetTransferVolumeQuery } from '@/transport/getAdminDataApi';
import { PageTitle } from '@/ui-kit/components';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CouponsSelect } from '../../../../components/CouponsSelect';
import TableModule from '../TableModule';
import { subWeeks } from 'date-fns';
import { getDateInYYYYMMDDCurrentTZFormat } from '@/ui-kit/utils/getDateInYYYYMMDDCurrentTZFormat';
import { ElevatedPaper } from '@/bo-kit/components';
import { Stack } from '@mui/material';
import { DateRangePicker } from '@/components/DateRangePicker';

const today = new Date();
const twoWeeksAgo = subWeeks(new Date(), 2);

const MainModule = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [dateStart, setDateStart] = useState(
        new Date(searchParams.get('dateStart') || twoWeeksAgo)
    );
    const [dateEnd, setDateEnd] = useState(new Date(searchParams.get('dateEnd') || today));
    const [selectedCoupons, setSelectedCoupons] = useState<string[]>(
        searchParams.getAll('coupons[]') || []
    );

    useEffect(() => {
        const searchParamsNew = new URLSearchParams({
            dateStart: getDateInYYYYMMDDCurrentTZFormat(dateStart),
            dateEnd: getDateInYYYYMMDDCurrentTZFormat(dateEnd),
        });

        selectedCoupons.forEach((coupon) => searchParamsNew.append('coupons[]', coupon));

        setSearchParams(searchParamsNew);
    }, [dateStart, dateEnd, selectedCoupons, setSearchParams]);

    const handleDateChange = ({
        dateStart,
        dateEnd,
    }: {
        dateStart: Date | null;
        dateEnd: Date | null;
    }) => {
        setDateStart(dateStart || twoWeeksAgo);
        setDateEnd(dateEnd || today);
    };

    const handleSelectedCouponsChange = (coupons: string[]) => {
        setSelectedCoupons(coupons);
    };

    const { data: couponData } = useGetCouponsQuery();
    const { data: transferVolumeData } = useGetTransferVolumeQuery({
        dateStart: getDateInYYYYMMDDCurrentTZFormat(dateStart),
        dateEnd: getDateInYYYYMMDDCurrentTZFormat(dateEnd),
        coupons: selectedCoupons,
    });

    const couponsAbbr = couponData?.data.map((coupon) => coupon.abbr) || [];

    return (
        <>
            <PageTitle>Search Transfer Volume</PageTitle>
            <Stack gap={4} sx={{ maxWidth: 600 }}>
                <ElevatedPaper
                    sx={({ spacing }) => ({
                        p: spacing(3),
                    })}
                >
                    <Stack gap={2}>
                        <DateRangePicker
                            date={{ dateStart, dateEnd }}
                            onDateChange={handleDateChange}
                        />

                        {/* TODO: replace with src/components/MultipleSelect */}
                        <CouponsSelect
                            coupons={couponsAbbr}
                            selectedCoupons={selectedCoupons}
                            onChange={handleSelectedCouponsChange}
                            limitTags={4}
                        />
                    </Stack>
                </ElevatedPaper>

                {transferVolumeData && <TableModule data={transferVolumeData.data} />}
            </Stack>
        </>
    );
};

export default MainModule;
