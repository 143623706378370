import { useLazyGetMerchantsStatementsQuery } from '@/transport/getAdminDataApi';
import { MerchantsStatementType } from '@/transport/getAdminDataApi/types';
import { useCallback, useEffect, useState } from 'react';

type UseGetStatementsOptions = {
    limit?: number;
    orderIds?: string[];
    coupons?: string[];
    dateStart?: string | null; // YYYY-MM-DD
    dateEnd?: string | null; // YYYY-MM-DD
    institutionIds?: string[];
    institutionCountryCodes?: string[];
    statuses?: string[];
    isTestTransfers?: boolean;
};

const DEFAULT_LIMIT = 50;

export const useGetMerchantsStatements = (options?: UseGetStatementsOptions) => {
    const {
        limit = DEFAULT_LIMIT,
        orderIds,
        coupons,
        dateStart,
        dateEnd,
        institutionIds,
        institutionCountryCodes,
        statuses,
        isTestTransfers,
    } = options ?? {};

    const [triggerGetStatements, { data, isError, isLoading, isFetching }] =
        useLazyGetMerchantsStatementsQuery();
    const [totalCount, setTotalCount] = useState(0);
    const [statementDataRaw, setStatementDataRaw] = useState<MerchantsStatementType[]>([]);

    const offset = statementDataRaw.length;
    const hasNextPage = statementDataRaw.length < totalCount;

    useEffect(() => {
        if (!dateStart || !dateEnd) return;
        setStatementDataRaw([]);
        triggerGetStatements({
            limit,
            dateStart: dateStart,
            dateEnd: dateEnd,
            coupons: coupons,
            someOrderIds: orderIds,
            institutionIds,
            institutionCountryCodes,
            statuses,
            isTestTransfers,
        });
    }, [
        triggerGetStatements,
        dateStart,
        dateEnd,
        coupons,
        orderIds,
        limit,
        institutionIds,
        institutionCountryCodes,
        statuses,
        isTestTransfers,
    ]);

    const loadNextPage = useCallback(async () => {
        if (!hasNextPage || !dateStart || !dateEnd) return;
        await triggerGetStatements({
            limit,
            offset,
            someOrderIds: orderIds,
            coupons: coupons,
            dateStart: dateStart,
            dateEnd: dateEnd,
            institutionIds,
            institutionCountryCodes,
            statuses,
            isTestTransfers,
        });
    }, [
        coupons,
        dateEnd,
        dateStart,
        hasNextPage,
        institutionCountryCodes,
        institutionIds,
        isTestTransfers,
        limit,
        offset,
        orderIds,
        statuses,
        triggerGetStatements,
    ]);

    // for data transform, if needed
    // const data = useMemo(() => statementDataRaw, [statementDataRaw]);

    useEffect(() => {
        if (data?.data && data?.paging.totalItems !== undefined) {
            // console.log('if data');
            setTotalCount(data.paging.totalItems);
            setStatementDataRaw((prevValue) => [...prevValue, ...data.data]);
        }
    }, [data]);

    return {
        loadNextPage,
        isLoading,
        isFetching,
        isError,
        hasNextPage,
        totalCount,
        data: statementDataRaw,
    };
};
