import React, { PropsWithChildren, useCallback, useState } from 'react';
import { Alert, Snackbar, AlertColor } from '@mui/material';
import { NotificationContext } from './NotificationContext';

export const NotificationProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState<string>('');
    const [severity, setSeverity] = useState<AlertColor>('info');

    const handleClose = () => {
        setOpen(false);
    };

    const notify = useCallback(
        ({ type = 'info', message }: { type?: AlertColor; message: string }) => {
            setSeverity(type);
            setMessage(message);
            setOpen(true);
        },
        []
    );

    return (
        <NotificationContext.Provider value={{ notify }}>
            {children}

            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert severity={severity}>{message}</Alert>
            </Snackbar>
        </NotificationContext.Provider>
    );
};
