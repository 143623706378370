import { createSvgIcon } from '@mui/material/utils';

export const LighteningIcon = createSvgIcon(
    <>
        <g clipPath='url(#clip0_8040_10975)'>
            <g filter='url(#filter0_d_8040_10975)' clipPath='url(#clip1_8040_10975)'>
                <path
                    d='M14.764 2.526a.522.522 0 00-.887-.38l-1.863 1.8a37.404 37.404 0 00-6.946 9.175.526.526 0 00.456.785h4.515v7.568a.522.522 0 00.877.39l.833-.764a37.339 37.339 0 007.334-9.242l.516-.917a.529.529 0 00-.456-.785h-4.38v-7.63z'
                    fill='#414FFD'
                />
            </g>
        </g>
        <defs>
            <filter
                id='filter0_d_8040_10975'
                x={3}
                y={2}
                width={18.667}
                height={24}
                filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'
            >
                <feFlood floodOpacity={0} result='BackgroundImageFix' />
                <feColorMatrix
                    in='SourceAlpha'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                />
                <feOffset dy={2} />
                <feGaussianBlur stdDeviation={1} />
                <feComposite in2='hardAlpha' operator='out' />
                <feColorMatrix values='0 0 0 0 0.666667 0 0 0 0 0.691489 0 0 0 0 1 0 0 0 1 0' />
                <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_8040_10975' />
                <feBlend in='SourceGraphic' in2='effect1_dropShadow_8040_10975' result='shape' />
            </filter>
            <clipPath id='clip0_8040_10975'>
                <path fill='#fff' d='M0 0H24V24H0z' />
            </clipPath>
            <clipPath id='clip1_8040_10975'>
                <rect width={24} height={24} rx={1} fill='#fff' />
            </clipPath>
        </defs>
    </>,
    'LighteningIcon'
);
