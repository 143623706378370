import { defaultMenuItems, SideMenuContextProvider, SideMenuItemType } from '@/bo-kit/components';
import React, { PropsWithChildren } from 'react';
import { usePermissionGuard } from './usePermissionGuard';

const hideItems = [
    SideMenuItemType.APIKEYS,
    SideMenuItemType.PAYMENTSETTINGS,
    SideMenuItemType.REWARDS,
    SideMenuItemType.STATEMENTS,
    SideMenuItemType.CATALOG,
    SideMenuItemType.PAYMENTS,
    SideMenuItemType.SETTINGS,
];

export const PermittedSideMenuContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { checkRoutePermission } = usePermissionGuard();

    const permittedMenuItems = defaultMenuItems.filter(
        (item) => !hideItems.includes(item.type) && checkRoutePermission(item.href)
    );

    return (
        <SideMenuContextProvider
            value={{
                items: permittedMenuItems,
            }}
        >
            {children}
        </SideMenuContextProvider>
    );
};
