import { FC } from 'react';
import { styled, Box, Paper } from '@mui/material';
import liberBlack from '../../theme/colors/liberBlack';

const Value = styled(Paper)(({ theme }) => ({
    background: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    color: liberBlack[200],
    padding: theme.spacing(1.5, 2),
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.fontSize,
    '& p:first-child': {
        marginTop: 0,
    },
    '& p:last-child': {
        marginBottom: 0,
    },
}));

const Label = styled('div')(function LabelStyled({ theme }) {
    return {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.fontSize,
        color: theme.palette.secondary.main,
        marginBottom: theme.spacing(2),
    };
});

type MerchantContentSectionProps = {
    label: string;
    children: React.ReactNode;
};

export const MerchantContentSection: FC<MerchantContentSectionProps> = ({ children, label }) => {
    return (
        <Box>
            <Label>{label}</Label>
            <Value elevation={4}>{children}</Value>
        </Box>
    );
};
