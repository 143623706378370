import { FC, ReactNode, useMemo } from 'react';
import { Helmet } from 'react-helmet';

const DefaultHtmlPageTitle: FC<{
  title: ReactNode;
}> = ({ title: _title }) => {
  const title = useMemo(() => (_title ? `LiberSave - ${_title}` : null), [_title]);
  return title ? (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  ) : null;
};

const LabelHtmlPageTitle: FC<{
  title: ReactNode;
}> = ({ title: _title }) => {
  const title = useMemo(
    () => (_title ? `${process.env.REACT_APP_LABEL_NAME} - ${_title}` : null),
    [_title]
  );
  return title ? (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  ) : null;
};

// use different components for labeled and unlabeled apps during the build phase
// to maintain compatibility until all applications start using the new labeled components
export const HtmlPageTitle = process.env.REACT_APP_LABEL_NAME
  ? LabelHtmlPageTitle
  : DefaultHtmlPageTitle;
