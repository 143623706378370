import { FC, useCallback, useMemo } from 'react';
import { LanguagePicker } from '@/ui-kit/components';
import { FlagGBIcon, FlagDEIcon, FlagRUIcon } from '@/ui-kit/icons/flags';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/material';
import { ThemeType } from '@/ui-kit/theme';
import { Language } from '@/ui-kit/types';

type Align = 'left' | 'right';
type VerticalAlign = 'top' | 'bottom';
type Variant = 'short' | 'full';

type LanguageSwitcherProps = {
  dropdownAlign?: Align;
  dropdownVertical?: VerticalAlign;
  variant?: Variant;
  sx?: SxProps<ThemeType>;
  onClick?: () => void;
};

export const LanguageSwitcher: FC<LanguageSwitcherProps> = ({
  dropdownAlign = 'right',
  dropdownVertical = 'bottom',
  sx,
  variant,
  onClick,
}) => {
  const { i18n, t } = useTranslation('bo-kit__common');

  const changeLocale = useCallback(
    (locale: string) => {
      i18n.changeLanguage(locale);
      onClick?.();
    },
    [i18n, onClick]
  );

  const languages = useMemo(
    () => [
      {
        code: 'en',
        icon: <FlagGBIcon />,
        name: t('English'),
      },
      {
        code: 'de',
        icon: <FlagDEIcon />,
        name: t('German'),
      },
      {
        code: 'ru',
        icon: <FlagRUIcon />,
        name: t('Russian'),
      },
    ],
    [t]
  );

  return (
    <LanguagePicker
      languages={languages}
      onChange={changeLocale}
      currentCode={(i18n.resolvedLanguage as Language) ?? Language.EN}
      dropdownAlign={dropdownAlign}
      dropdownVerticalAlign={dropdownVertical}
      sx={sx}
      variant={variant}
    />
  );
};
