import { FC } from 'react';
import { Row, Cell } from './TableElements';
import { Permission } from '@/transport/authApi/types';
import { IconButton, Stack, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { formatPermissions } from '../../utils';
import { getStatementUTCDateTimeFormat } from '@/ui-kit/utils';

type UserRoleRowProps = {
    id: string;
    code: string;
    title: string;
    permissions: Permission[];
    createdAt: string;
    updatedAt: string;
    showActions: boolean;
};

export const UserRoleRow: FC<UserRoleRowProps> = ({
    id,
    code,
    title,
    permissions,
    createdAt,
    updatedAt,
    showActions,
}) => {
    const navigate = useNavigate();
    // const { palette } = useTheme();

    // const getStatusColor = (isActivated: boolean) => {
    //     return isActivated ? palette.success.dark : palette.error.dark;
    // };

    const handleDelete = () => {
        navigate(`./delete/${id}`);
    };
    const handleUpdate = () => {
        navigate(`./update/${id}`);
    };

    return (
        <Row showActions={showActions}>
            <Cell primaryText={title} />
            <Cell primaryText={code} />
            <Cell primaryText={formatPermissions(permissions)} />
            <Cell
                primaryText={getStatementUTCDateTimeFormat(createdAt)}
                secondaryText={getStatementUTCDateTimeFormat(updatedAt)}
            />

            {showActions && (
                <Cell
                    primaryText={
                        <Stack direction='row' gap={1}>
                            <Tooltip title='Edit'>
                                <IconButton size='small' onClick={handleUpdate}>
                                    <EditIcon sx={{ fontSize: 24 }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Delete'>
                                <IconButton size='small' onClick={handleDelete}>
                                    <DeleteIcon sx={{ fontSize: 24 }} />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    }
                />
            )}
        </Row>
    );
};
