import React from 'react';
import { useTranslation } from '@/ui-kit/hooks';
import { Box, Stack, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { UsersTable } from '../components/UsersTable';

const MobileUsersListPage = () => {
    const { t } = useTranslation('statements');

    return (
        <>
            <Stack direction='row' justifyContent='space-between'>
                <Box>
                    <Typography variant='h1' fontWeight='bold' sx={{ mb: 1.25 }}>
                        {t('Users list')}
                    </Typography>
                </Box>
            </Stack>
            <UsersTable />
            <Outlet />
        </>
    );
};

export default MobileUsersListPage;
