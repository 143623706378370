import { createSvgIcon } from '@mui/material/utils';

export const IOSOutIcon = createSvgIcon(
    <>
        <path d='M11.902 1.012l.512-.551a.744.744 0 00-1.02 0zm.75 15.261V1h-1.5v15.273zm5.22-10.722L12.413.46l-1.02 1.098 5.454 5.086zM11.394.46L5.938 5.55 6.96 6.645l5.453-5.086zm0 0' />
        <path d='M15.766 10.27h2.496c.687 0 1.246.507 1.246 1.132v10.196c0 .625-.559 1.132-1.246 1.132H5.793c-.688 0-1.246-.507-1.246-1.132V11.402c0-.625.558-1.132 1.246-1.132h2.492V9.137H5.793c-1.379 0-2.492 1.015-2.492 2.265v10.196c0 1.25 1.113 2.265 2.492 2.265h12.469c1.375 0 2.492-1.015 2.492-2.265V11.402c0-1.25-1.117-2.265-2.492-2.265h-2.496zm0 0' />
        <path
            d='M3.148 11.402C3.148 10.074 4.336 9 5.793 9h2.644v1.406H5.793c-.606 0-1.098.45-1.098.996v10.196c0 .547.492.996 1.098.996h12.469c.605 0 1.093-.45 1.093-.996V11.402c0-.547-.488-.996-1.093-.996h-2.645V9h2.645c1.457 0 2.64 1.074 2.64 2.402v10.196c0 1.328-1.183 2.402-2.64 2.402H5.793c-1.457 0-2.645-1.074-2.645-2.402zm2.645-2.129c-1.297 0-2.344.957-2.344 2.13v10.195c0 1.172 1.047 2.129 2.344 2.129h12.469c1.293 0 2.343-.957 2.343-2.13V11.403c0-1.172-1.05-2.129-2.343-2.129h-2.344v.86h2.344c.77 0 1.394.566 1.394 1.27v10.195c0 .703-.625 1.27-1.394 1.27H5.793c-.77 0-1.398-.567-1.398-1.27V11.402c0-.703.628-1.27 1.398-1.27h2.344v-.859zm0 0'
            fillRule='evenodd'
        />
    </>,
    'IOSOutIcon'
);
