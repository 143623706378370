import { gatewayApi } from '@/transport/authApi';
import { gatewayApiAdminData } from '@/transport/getAdminDataApi';
import { usersApi } from '@/transport/usersApi';
import { accountTransactionsApi } from '@/transport/accountTransactionsApi';
import {
    configureStore,
    createListenerMiddleware,
    ListenerMiddlewareInstance,
} from '@reduxjs/toolkit';
import { Mutex } from 'async-mutex';
import { isDev } from '../ui-kit/utils/isDev';

let listenerMiddlewareInner: ListenerMiddlewareInstance<unknown>;
const listenerMiddlewareMutex = new Mutex();
const releaseListenerMiddlewareMutex = listenerMiddlewareMutex.acquire();

export const store = configureStore({
    reducer: {
        [gatewayApiAdminData.reducerPath]: gatewayApiAdminData.reducer,
        [gatewayApi.reducerPath]: gatewayApi.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
        [accountTransactionsApi.reducerPath]: accountTransactionsApi.reducer,
    },

    middleware: (getDefaultMiddleWare) => {
        listenerMiddlewareInner = createListenerMiddleware();
        releaseListenerMiddlewareMutex.then((release) => release());
        return getDefaultMiddleWare({ thunk: true })
            .prepend(gatewayApiAdminData.middleware)
            .prepend(gatewayApi.middleware)
            .prepend(usersApi.middleware)
            .prepend(accountTransactionsApi.middleware)
            .prepend(listenerMiddlewareInner.middleware);
    },

    devTools: isDev(),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const getListenerMiddleware = async () => {
    if (!listenerMiddlewareMutex.isLocked) {
        return listenerMiddlewareInner as ListenerMiddlewareInstance<RootState>;
    }
    return listenerMiddlewareMutex.runExclusive(
        () => listenerMiddlewareInner as ListenerMiddlewareInstance<RootState>
    );
};
