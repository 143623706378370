import { useCallback, useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { useLazyGetMerchantsQuery } from '@/transport/getAdminDataApi'; // Запрос данных мерчантов
import { StatMerchant } from '@/transport/getAdminDataApi/types';

type UseGetMerchantsOptions = {
    limit?: number;
};

const DEFAULT_LIMIT = 50;

export const useGetMerchants = (options?: UseGetMerchantsOptions) => {
    const { limit = DEFAULT_LIMIT } = options ?? {};

    const [triggerGetMerchants, { data, isError, isLoading, isFetching }] =
        useLazyGetMerchantsQuery();
    const [totalCount, setTotalCount] = useState(0);
    const [statementDataRaw, setStatementDataRaw] = useState<StatMerchant[]>([]);

    const offset = statementDataRaw.length;
    const hasNextPage = statementDataRaw.length < totalCount;

    useEffect(() => {
        setStatementDataRaw([]);
        triggerGetMerchants({
            limit,
        });
    }, [triggerGetMerchants, limit]);

    const loadNextPage = useCallback(async () => {
        if (!hasNextPage) return;
        await triggerGetMerchants({
            limit,
            offset,
        });
    }, [hasNextPage, limit, offset, triggerGetMerchants]);

    // for data transform, if needed
    // const data = useMemo(() => statementDataRaw, [statementDataRaw]);

    useEffect(() => {
        if (data?.data && data?.paging.totalItems !== undefined) {
            setTotalCount(data.paging.totalItems);
            setStatementDataRaw((prevValue) => [...prevValue, ...data.data]);
        }
    }, [data]);

    return {
        loadNextPage,
        isLoading,
        isFetching,
        isError,
        hasNextPage,
        totalCount,
        data: statementDataRaw,
    };
};
