import { Language } from '../types';

export const FallbackLanguage = Language.DE;

export const getLanguage = (language?: string | null): Language => {
    if (!language) {
        return FallbackLanguage;
    }
    const found = Object.values(Language).find((value) => value === language);
    return found ?? FallbackLanguage;
};
