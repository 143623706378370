import { createSvgIcon } from '@mui/material/utils';

export const SettingsIcon = createSvgIcon(
    <>
        <path d='M 8.246 12 C 8.246 9.927 9.927 8.246 12 8.246 C 14.073 8.246 15.753 9.927 15.753 12 C 15.753 14.073 14.073 15.753 12 15.753 C 9.927 15.753 8.246 14.073 8.246 12 Z' />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M 11.377 0.264 C 11.718 -0.088 12.282 -0.088 12.623 0.264 L 15.594 3.338 L 19.796 3.338 C 20.274 3.338 20.662 3.726 20.662 4.204 L 20.662 8.406 L 23.736 11.377 C 24.088 11.718 24.088 12.282 23.736 12.623 L 20.662 15.594 L 20.662 19.796 C 20.662 20.274 20.274 20.662 19.796 20.662 L 15.594 20.662 L 12.623 23.736 C 12.282 24.088 11.718 24.088 11.377 23.736 L 8.406 20.662 L 4.204 20.662 C 3.726 20.662 3.338 20.274 3.338 19.796 L 3.338 15.594 L 0.264 12.623 C -0.088 12.282 -0.088 11.718 0.264 11.377 L 3.338 8.406 L 3.338 4.204 C 3.338 3.726 3.726 3.338 4.204 3.338 L 8.406 3.338 L 11.377 0.264 Z M 12 6.514 C 8.97 6.514 6.514 8.97 6.514 12 C 6.514 15.03 8.97 17.486 12 17.486 C 15.03 17.486 17.486 15.03 17.486 12 C 17.486 8.97 15.03 6.514 12 6.514 Z'
        />
    </>,
    'Settings'
);
