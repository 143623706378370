import { I18nHookContextProvider } from '@/ui-kit/hooks/useTranslation';
import { theme } from '@/ui-kit/theme';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from '../store';
import { PermittedSideMenuContextProvider } from './Permissions/PermittedSideMenuContextProvider';
import { NotificationProvider } from './Notification';

export const Providers: FC<PropsWithChildren> = ({ children }) => {
    return (
        <ReduxProvider store={store}>
            <StyledEngineProvider>
                <ThemeProvider theme={theme}>
                    <I18nHookContextProvider hook={useTranslation}>
                        <PermittedSideMenuContextProvider>
                            <NotificationProvider>{children}</NotificationProvider>
                        </PermittedSideMenuContextProvider>
                    </I18nHookContextProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </ReduxProvider>
    );
};
