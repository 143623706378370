export const getStatementDateFormat = (date: string): string => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return `${da} ${mo}, ${ye}`;
};

export const getStatementTimeFormat = (date: string): string => {
    const d = new Date(date);
    return new Intl.DateTimeFormat('en-GB', { hour: '2-digit', minute: '2-digit' })
        .format(d)
        .toLowerCase();
};

export const getStatementCardDateFormat = (
    date: string,
    { splitSymbol } = { splitSymbol: '.' }
): string => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return `${da}${splitSymbol}${mo}${splitSymbol}${ye}`;
};

export const getStatementDateTimeFormat = (date: string): string => {
    return `${getStatementCardDateFormat(date, { splitSymbol: '/' })} ${getStatementTimeFormat(
        date
    )}`;
};

export const getStatementUTCDateTimeFormat = (date: string | Date): string => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric', timeZone: 'UTC' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit', timeZone: 'UTC' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit', timeZone: 'UTC' }).format(d);
    const ho = new Intl.DateTimeFormat('en', {
        hour: '2-digit',
        timeZone: 'UTC',
        hour12: false,
    })
        .format(d)
        .padStart(2, '0');
    const mi = new Intl.DateTimeFormat('en', { minute: '2-digit', timeZone: 'UTC' })
        .format(d)
        .padStart(2, '0');

    const formattedDateTime = `${ye}/${mo}/${da} ${ho}:${mi}`;
    return formattedDateTime;
};
