import { FC } from 'react';
import { Typography, Button, styled, Stack } from '@mui/material';
import { TFunction } from 'i18next';

type QRScanErrorProps = {
    onRetry: () => void;
    t: TFunction;
};

const QRScanErrorContainer = styled('div')(({ theme }) => ({
    backgroundColor: '#c3c3c6',
    padding: theme.spacing(4),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
}));

const QRScanErrorModal = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
}));

export const DefaultQRScanError: FC<QRScanErrorProps> = ({ onRetry, t }) => {
    return (
        <QRScanErrorContainer>
            <QRScanErrorModal>
                <Stack spacing={3}>
                    <Typography variant='h3'>{t('qr_scan.error_title')}</Typography>
                    <Typography variant='body1' color='secondary'>
                        {t('qr_scan.error_description')}
                    </Typography>
                    <Button onClick={onRetry} variant='contained' fullWidth>
                        {t('qr_scan.retry_button')}
                    </Button>
                </Stack>
            </QRScanErrorModal>
        </QRScanErrorContainer>
    );
};

export const LabelQRScanError: FC<QRScanErrorProps> = ({ onRetry, t }) => {
    return (
        <QRScanErrorContainer>
            <QRScanErrorModal>
                <Stack spacing={3}>
                    <Typography variant='h3'>{t('qr_scan.error_title')}</Typography>
                    <Typography variant='body1' color='secondary'>
                        {t('qr_scan.error_description', {
                            appLabelName: process.env.REACT_APP_LABEL_NAME,
                        })}
                    </Typography>
                    <Button onClick={onRetry} variant='contained' fullWidth>
                        {t('qr_scan.retry_button')}
                    </Button>
                </Stack>
            </QRScanErrorModal>
        </QRScanErrorContainer>
    );
};

// use different components for labeled and unlabeled apps during the build phase
// to maintain compatibility until all applications start using the new labeled components
// each app takes i18n-json from its own public dir, so in both ways translation will be correct
export const QRScanError = process.env.REACT_APP_LABEL_NAME ? LabelQRScanError : DefaultQRScanError;
