import { createContext, useContext, useMemo } from 'react';
import { SvgIcon } from '@mui/material';
import {
  AccountsIcon,
  CatalogIcon,
  GiftIcon,
  HomeIcon,
  KeyIcon,
  PaymentsIcon,
  SettingsIcon,
  MerchantsIcon,
  UsersIcon,
  BankAccountIcon,
} from '@/ui-kit/icons';

export enum SideMenuItemType {
  MAIN = 'Main',
  PAYMENTSETTINGS = 'Payment settings',
  APIKEYS = 'API Keys',
  REWARDS = 'Rewards',
  CATALOG = 'Catalog',
  STATEMENTS = 'Statements',
  PAYMENTS = 'Payments',
  LANGUAGE = 'Language',
  SETTINGS = 'Settings',
  LOGOUT = 'Logout',
  CUSTOM = 'custom',
  TRANSACTIONS = 'Transactions',
  TRANSFERVOLUME = 'Transfer volume',
  MERCHANTS = 'Merchants',
  USERS = 'Users',
  ACCOUNTTRANSACTIONS = 'Account transactions',
}

export type SideMenuItem = {
  type: SideMenuItemType;
  icon: typeof SvgIcon;
  href: string;
};
type SideMenuOptions = Partial<{
  items: SideMenuItem[];
  hideItems: SideMenuItemType[];
}>;

export const defaultMenuItems: SideMenuItem[] = [
  { type: SideMenuItemType.MAIN, icon: HomeIcon, href: '/' },
  { type: SideMenuItemType.APIKEYS, icon: KeyIcon, href: '/api-keys' },
  { type: SideMenuItemType.REWARDS, icon: GiftIcon, href: '/rewards' },
  { type: SideMenuItemType.CATALOG, icon: CatalogIcon, href: '/catalog' },
  { type: SideMenuItemType.STATEMENTS, icon: AccountsIcon, href: '/statements' },
  { type: SideMenuItemType.PAYMENTS, icon: PaymentsIcon, href: '/payments' },
  { type: SideMenuItemType.PAYMENTSETTINGS, icon: SettingsIcon, href: '/payment-settings' },
  { type: SideMenuItemType.TRANSACTIONS, icon: SettingsIcon, href: '/search-transactions' },
  { type: SideMenuItemType.TRANSFERVOLUME, icon: SettingsIcon, href: '/show-transfer-volume' },
  { type: SideMenuItemType.MERCHANTS, icon: MerchantsIcon, href: '/merchants' },
  { type: SideMenuItemType.USERS, icon: UsersIcon, href: '/users' },
  {
    type: SideMenuItemType.ACCOUNTTRANSACTIONS,
    icon: BankAccountIcon,
    href: '/account-transactions',
  },
];

const SideMenuContext = createContext<SideMenuOptions>({
  items: defaultMenuItems,
});

export const SideMenuContextProvider = SideMenuContext.Provider;

export const useSideMenuItems = (): Required<SideMenuOptions> => {
  const { items = defaultMenuItems, hideItems = [] } = useContext<SideMenuOptions>(SideMenuContext);
  return useMemo(
    () => ({
      items: items.filter(({ type }) => !hideItems.includes(type)),
      hideItems,
    }),
    [items, hideItems]
  );
};
