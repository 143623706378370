import { createSvgIcon } from '@mui/material/utils';

export const ChevronUpIcon = createSvgIcon(
    <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.32372 15.6805C5.75536 16.1065 6.45517 16.1065 6.8868 15.6805L12 10.6337L17.1132 15.6805C17.5448 16.1065 18.2446 16.1065 18.6763 15.6805C19.1079 15.2545 19.1079 14.5637 18.6763 14.1377L12.7815 8.31952C12.3499 7.89349 11.6501 7.89349 11.2185 8.31952L5.32372 14.1377C4.89209 14.5637 4.89209 15.2545 5.32372 15.6805Z'
    />,
    'ChevronUpIcon'
);
