import { useLazyGetAccountTransactionsQuery } from '@/transport/accountTransactionsApi';
import { AccountTransaction, TransactionDirection } from '@/transport/accountTransactionsApi/types';
import { useCallback, useEffect, useState } from 'react';

type UseGetStatementsOptions = {
    limit?: number;
    dateStart?: string | null; // YYYY-MM-DD
    dateEnd?: string | null; // YYYY-MM-DD
    categories?: string[];
    referenceIds?: string[];
    direction?: TransactionDirection;
    referenceSubstring?: string;
};

const DEFAULT_LIMIT = 50;

export const useGetAccountTransactions = (options?: UseGetStatementsOptions) => {
    const {
        limit = DEFAULT_LIMIT,
        dateStart,
        dateEnd,
        categories,
        referenceIds,
        direction,
        referenceSubstring,
    } = options ?? {};

    const [triggerGetTransactions, { data, isError, isLoading, isFetching }] =
        useLazyGetAccountTransactionsQuery();
    const [totalCount, setTotalCount] = useState(0);
    const [transactionsDataRaw, setTransactionsDataRaw] = useState<AccountTransaction[]>([]);

    const offset = transactionsDataRaw.length;
    const hasNextPage = transactionsDataRaw.length < totalCount;

    useEffect(() => {
        if (!dateStart || !dateEnd) return;
        setTransactionsDataRaw([]);
        triggerGetTransactions({
            limit,
            dateStart,
            dateEnd,
            categories,
            referenceIds,
            direction,
            referenceSubstring,
            offset: 0,
        });
    }, [
        categories,
        dateEnd,
        dateStart,
        direction,
        limit,
        referenceIds,
        referenceSubstring,
        triggerGetTransactions,
    ]);

    const loadNextPage = useCallback(async () => {
        if (!hasNextPage || !dateStart || !dateEnd) return;
        await triggerGetTransactions({
            offset,
            limit,
            dateStart,
            dateEnd,
            categories,
            referenceIds,
            direction,
            referenceSubstring,
        });
    }, [
        categories,
        dateEnd,
        dateStart,
        direction,
        hasNextPage,
        limit,
        offset,
        referenceIds,
        referenceSubstring,
        triggerGetTransactions,
    ]);

    useEffect(() => {
        if (data?.data && data?.paging.totalItems !== undefined) {
            // console.log('if data');
            setTotalCount(data.paging.totalItems);
            setTransactionsDataRaw((prevValue) => [...prevValue, ...data.data]);
        }
    }, [data]);

    return {
        loadNextPage,
        isLoading,
        isFetching,
        isError,
        hasNextPage,
        totalCount,
        data: transactionsDataRaw,
    };
};
