import { createSvgIcon } from '@mui/material/utils';

export const FlagRUIcon = createSvgIcon(
    <>
        <rect x='2' y='5' width='20' height='14' rx='2' fill='white' />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M2 14.3334H22V9.66675H2V14.3334Z'
            fill='#0C47B7'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M2 16.9999C2 18.1045 2.89543 18.9999 4 18.9999H20C21.1046 18.9999 22 18.1045 22 16.9999V14.3333H2V16.9999Z'
            fill='#E53B35'
        />
    </>,
    'FlagRUIcon'
);
