import React, { useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Alert, Button, ButtonProps, Snackbar, useTheme } from '@mui/material';

type CopyToClipboardButtonProps = {
    data: string | undefined | null;
    notificationText?: string;
} & ButtonProps;

export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
    data,
    notificationText,
    sx,
    ...props
}) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const shouldNotify = !!notificationText;

    const handleClickCopy = (event: React.MouseEvent<HTMLElement>) => {
        navigator.clipboard.writeText(data || '');
        if (shouldNotify) {
            setOpenSnackbar(true);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };
    const { palette } = useTheme();

    return (
        <>
            <Button
                onClick={handleClickCopy}
                variant='text'
                disabled={props.disabled}
                sx={{
                    color: palette.text.secondary,
                    p: 0,
                    minWidth: 'unset',
                    '&:disabled': { background: 'none' },
                    ...sx,
                }}
                {...props}
            >
                <ContentCopyIcon fontSize='small' />
            </Button>

            {shouldNotify && (
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={2000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert severity='success'>{notificationText}</Alert>
                </Snackbar>
            )}
        </>
    );
};
