import { IFaceLoader } from '@/bo-kit/components';
import React, { PropsWithChildren, Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import NotFoundPage from '@/bo-kit/components/NotFoundPage';
import { usePermissionGuard } from './usePermissionGuard';

export const RoutesPermissionGuard: React.FC<PropsWithChildren> = ({ children }) => {
    const { isLoading, isError, checkRoutePermission } = usePermissionGuard();
    const { pathname } = useLocation();

    const hasPermissionsToCurrentRoute = checkRoutePermission(pathname);

    if (!hasPermissionsToCurrentRoute) {
        return (
            <Suspense fallback={<IFaceLoader />}>
                <NotFoundPage />
            </Suspense>
        );
    }

    if (isLoading) {
        return <IFaceLoader />;
    }
    if (isError) {
        return <Navigate to='/' replace />;
    }
    return <>{children}</>;
};
