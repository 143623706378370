// tries to extract the order ID from the payment URL (with data url param base64-encoded)
// or return the same string if something is wrong

export const tryExtractOrderId = (expectUrl: string) => {
    try {
        const data = new URL(expectUrl).searchParams.get('data');
        if (!data) return expectUrl;

        const parsedData = JSON.parse(atob(data));
        return parsedData?.order_id ?? expectUrl;
    } catch {
        return expectUrl;
    }
};
