import { ThemeType } from '@/ui-kit/theme';
import liberBlack from '@/ui-kit/theme/colors/liberBlack';
import liberWhite from '@/ui-kit/theme/colors/liberWhite';
import { Box, SxProps, Typography } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';

export const CellHeader: FC<PropsWithChildren<{ sx?: SxProps<ThemeType> }>> = ({
    children,
    sx,
}) => (
    <Box sx={{ minWidth: 0, ...sx }}>
        <Typography variant='body2' color='secondary' fontWeight={400} noWrap>
            {children}
        </Typography>
    </Box>
);

export const Cell: FC<{
    sx?: SxProps<ThemeType>;
    primaryText: ReactNode | string;
    secondaryText?: ReactNode | string;
}> = ({ sx, primaryText, secondaryText }) => (
    <Box sx={{ minWidth: 0, ...sx }}>
        <Typography component='div' variant='body2' fontWeight={400}>
            {primaryText}
        </Typography>
        {secondaryText && (
            <Typography
                component='div'
                variant='body2'
                sx={({ spacing }) => ({
                    color: liberBlack[100],
                    fontWeight: 400,
                    fontSize: spacing(1.5),
                })}
            >
                {secondaryText}
            </Typography>
        )}
    </Box>
);

const gridTemplateColumnsWithActions = '19fr 27fr 12fr 13fr 10fr 10fr';
const gridTemplateColumns = '20fr 28fr 13fr 20fr 10fr';

export const RowHeader: FC<
    PropsWithChildren<{ sx?: SxProps<ThemeType>; showActions: boolean }>
> = ({ children, showActions }) => (
    <Box
        sx={{
            display: 'grid',
            gridTemplateColumns: showActions ? gridTemplateColumnsWithActions : gridTemplateColumns,
            columnGap: 1,
            // maxWidth: '1400px',
            width: '100%',
        }}
    >
        {children}
    </Box>
);

export const Row: FC<PropsWithChildren<{ sx?: SxProps<ThemeType>; showActions: boolean }>> = ({
    children,
    showActions,
}) => (
    <Box
        sx={({ spacing }) => ({
            display: 'grid',
            gridTemplateColumns: showActions ? gridTemplateColumnsWithActions : gridTemplateColumns,
            columnGap: 1,
            // maxWidth: '1400px',
            width: '100%',
            borderBottom: `1px solid ${liberWhite[100]}`,
            py: spacing(2.5),

            '&:last-child': {
                pb: 0,
                borderBottom: 'none',
            },
        })}
    >
        {children}
    </Box>
);
