import { createSvgIcon } from '@mui/material/utils';

export const UploadIcon = createSvgIcon(
    <>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M 2.06 17.638 C 2.646 17.638 3.121 18.113 3.121 18.698 L 3.121 21.526 C 3.121 21.721 3.279 21.879 3.474 21.879 L 20.44 21.879 C 20.635 21.879 20.794 21.721 20.794 21.526 L 20.794 18.698 C 20.794 18.113 21.268 17.638 21.854 17.638 C 22.439 17.638 22.914 18.113 22.914 18.698 L 22.914 21.526 C 22.914 22.892 21.806 24 20.44 24 L 3.474 24 C 2.108 24 1 22.892 1 21.526 L 1 18.698 C 1 18.113 1.475 17.638 2.06 17.638 Z'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M 10.173 17.464 C 9.445 17.464 8.839 16.909 8.775 16.184 C 8.558 13.706 8.52 11.216 8.66 8.733 C 8.311 8.714 7.962 8.691 7.613 8.666 L 5.507 8.512 C 4.695 8.453 4.241 7.548 4.68 6.862 C 6.181 4.512 8.116 2.469 10.381 0.842 L 11.225 0.236 C 11.662 -0.079 12.252 -0.079 12.689 0.236 L 13.533 0.842 C 15.798 2.469 17.733 4.512 19.234 6.862 C 19.673 7.548 19.219 8.453 18.407 8.512 L 16.301 8.666 C 15.952 8.691 15.603 8.714 15.254 8.733 C 15.394 11.216 15.356 13.706 15.139 16.184 C 15.075 16.909 14.469 17.464 13.741 17.464 L 10.173 17.464 Z'
        />
    </>,
    'UploadIcon'
);
