import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { FC } from 'react';
import { ChevronDownIcon } from '../../icons';
import * as React from 'react';
import { styled } from '@mui/material';
import liberWhite from '../../theme/colors/liberWhite';

const MobileFormSpoilerBase = styled(Accordion)({
    '&:before': {
        display: 'none',
    },
    boxShadow: 'none',
});

const MobileFormSpoilerSummaryBase = styled(AccordionSummary)(({ theme }) => ({
    padding: theme.spacing(0),
    borderBottom: `1px solid ${liberWhite[100]}`,
    filter: 'none !important',
    color: theme.palette.text.secondary,
    '& .MuiAccordionSummary-expandIconWrapper': {
        color: theme.palette.text.secondary,
    },
}));

export const MobileFormSpoiler: FC<AccordionProps> = (props) => (
    <MobileFormSpoilerBase square={true} disableGutters {...props} />
);

export const MobileFormSpoilerSummary: FC<AccordionSummaryProps> = (props) => (
    <MobileFormSpoilerSummaryBase expandIcon={<ChevronDownIcon />} {...props} />
);

export const MobileFormSpoilerDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1, 0),
}));
