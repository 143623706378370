import { Skeleton } from '@mui/material';
import { FC } from 'react';
import { Cell, Row } from './TableElements';

const PrimaryTextSkeleton: FC = () => (
    <Skeleton variant='text' sx={({ typography }) => ({ fontSize: typography.fontSize * 1.5 })} />
);

export const MerchantRowSkeleton: FC = () => {
    return (
        <Row>
            <Cell primaryText={<PrimaryTextSkeleton />} />
            <Cell primaryText={<PrimaryTextSkeleton />} secondaryText={<Skeleton />} />
            <Cell primaryText={<PrimaryTextSkeleton />} />
            <Cell primaryText={<PrimaryTextSkeleton />} />
            <Cell primaryText={<PrimaryTextSkeleton />} secondaryText={<Skeleton />} />
            <Cell primaryText={<PrimaryTextSkeleton />} secondaryText={<Skeleton />} />
            <Cell primaryText={<PrimaryTextSkeleton />} secondaryText={<Skeleton />} />
            <Cell primaryText={<PrimaryTextSkeleton />} secondaryText={<Skeleton />} />
            <Cell primaryText={<PrimaryTextSkeleton />} />
        </Row>
    );
};
