import { useGetMerchantByIdQuery } from '@/transport/getAdminDataApi';
import { ElevatedPaper } from '@/ui-kit/components';
import React from 'react';
import { useParams } from 'react-router-dom';
import genericErrorImg from '@/assets/images/genericError.svg';
import { CircularProgress, Box, Typography } from '@mui/material';
import { MerchantDetails } from './MerchantDetails';

export const MerchantInfo = () => {
    const { merchantId } = useParams();
    const {
        data: merchantData,
        isFetching,
        isError,
    } = useGetMerchantByIdQuery({ id: merchantId as string });
    return (
        <ElevatedPaper
            sx={{
                minHeight: '70vh',
                maxWidth: '900px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                p: 5,
            }}
        >
            {merchantData && <MerchantDetails data={merchantData} />}

            {isFetching && (
                <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    flexDirection='column'
                    height='100%'
                    gap={2}
                >
                    <CircularProgress size={48} />
                    <Typography>Loading...</Typography>
                </Box>
            )}
            {isError && (
                <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    flexDirection='column'
                    height='100%'
                    gap={2}
                >
                    <Box component='img' src={genericErrorImg} />
                    <Typography>Error loading data</Typography>
                </Box>
            )}
        </ElevatedPaper>
    );
};
