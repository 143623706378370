import { createSvgIcon } from '@mui/material/utils';

export const LocationIcon = createSvgIcon(
    <>
        <path
            d='M8.75 10A3.248 3.248 0 0112 6.75 3.25 3.25 0 118.75 10zm0 0'
            stroke='none'
            fillRule='nonzero'
        />
        <path
            d='M3.773 8.879A8.038 8.038 0 0111.785 1.5h.43a8.038 8.038 0 018.012 7.379 8.695 8.695 0 01-1.934 6.215L13.5 20.957a1.941 1.941 0 01-3 0l-4.793-5.863a8.695 8.695 0 01-1.934-6.215zM12 5.25A4.748 4.748 0 007.25 10 4.748 4.748 0 0012 14.75 4.748 4.748 0 0016.75 10 4.748 4.748 0 0012 5.25zm0 0'
            stroke='none'
            fillRule='evenodd'
        />
    </>,
    'LocationIcon'
);
