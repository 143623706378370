import { useGetExportedTransactionsMutation } from '@/transport/accountTransactionsApi';
import { Box, Button, ButtonProps, CircularProgress, Tooltip } from '@mui/material';
import React from 'react';

type ExportTransactionsButtonProps = {
    dateStart?: string;
    dateEnd?: string;
    categories?: string[];
    referenceIds?: string[];
    referenceSubstring?: string;
} & ButtonProps;

export const ExportTransactionsButton: React.FC<ExportTransactionsButtonProps> = ({
    dateStart,
    dateEnd,
    categories,
    referenceIds,
    referenceSubstring,
    ...props
}) => {
    const [getExportedTransactions, { isLoading }] = useGetExportedTransactionsMutation();

    const handleButtonClick = () => {
        getExportedTransactions({
            dateStart,
            dateEnd,
            categories,
            referenceIds,
            referenceSubstring,
        })
            .unwrap()
            .then((data) => {
                const excelUrl = URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = excelUrl;
                link.download = `Transactions_${dateStart}_${dateEnd}.xlsx`;
                link.click();
                URL.revokeObjectURL(excelUrl);
            });
    };

    const disabled = false;

    return (
        <Box sx={{ ...props.sx }}>
            <Tooltip title={disabled ? 'Filter transactions first' : ''}>
                <Box component={'span'}>
                    <Button
                        variant='text'
                        size='small'
                        disabled={disabled || isLoading}
                        sx={{
                            '&.Mui-disabled': {
                                backgroundColor: 'transparent',
                            },
                        }}
                        onClick={handleButtonClick}
                        startIcon={
                            isLoading ? <CircularProgress size={14} color='inherit' /> : null
                        }
                    >
                        Export to XLSX
                    </Button>
                </Box>
            </Tooltip>
        </Box>
    );
};
