import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    CouponResponseRaw,
    DataByOrderIdRequest,
    DataByOrderIdResponse,
    GetDirectoryDataRequest,
    GetDirectoryDataResponse,
    GetExportedStatementsByCouponsRequest,
    GetExportedStatementsByCouponsResponse,
    GetMerchantByIdRequest,
    GetMerchantByIdResponse,
    GetMerchantsRequest,
    GetMerchantsResponse,
    MerchantsStatementsRequest,
    MerchantsStatementsResponse,
    TransferVolumeRequest,
    TransferVolumeResponse,
} from './types';

const baseUrl = process.env.REACT_APP_STAT_BASE_URL;

const reducerPath = 'gatewayApiAdminData' as const;

export const gatewayApiAdminData = createApi({
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    reducerPath,
    baseQuery: fetchBaseQuery({ baseUrl }),
    endpoints: (build) => ({
        getCoupons: build.query<CouponResponseRaw, void>({
            query: () => ({
                url: '/api/directory/coupons',
                method: 'GET',
                credentials: 'include',
            }),
        }),
        getTransferVolume: build.query<TransferVolumeResponse, TransferVolumeRequest>({
            query: ({ dateStart, dateEnd, coupons }) => {
                const queryParams = new URLSearchParams({ dateStart, dateEnd });
                coupons?.forEach((coupon) => queryParams.append('coupons[]', coupon));
                const queryParamsString = queryParams.toString();

                return {
                    url: '/api/stat/daily-transfers-volume?' + queryParamsString,
                    method: 'GET',
                    credentials: 'include',
                };
            },
        }),
        getMerchantsStatements: build.query<
            MerchantsStatementsResponse,
            MerchantsStatementsRequest
        >({
            query: ({
                dateStart,
                dateEnd,
                coupons,
                someOrderIds,
                limit,
                offset,
                institutionIds,
                institutionCountryCodes,
                statuses,
                isTestTransfers,
            }) => {
                const queryParams = new URLSearchParams({
                    ...(typeof limit === 'number' && { limit: limit.toString() }),
                    ...(typeof offset === 'number' && { offset: offset.toString() }),
                    ...(typeof dateStart === 'string' && { dateStart }),
                    ...(typeof dateEnd === 'string' && { dateEnd }),
                    ...(isTestTransfers && { isTestTransfers: String(isTestTransfers) }),
                });
                coupons?.forEach((coupon) => queryParams.append('coupons[]', coupon));
                someOrderIds?.forEach((orderId) => queryParams.append('someOrderIds[]', orderId));
                institutionIds?.forEach((orderId) =>
                    queryParams.append('institutionIds[]', orderId)
                );
                institutionCountryCodes?.forEach((orderId) =>
                    queryParams.append('institutionCountryCodes[]', orderId)
                );
                statuses?.forEach((orderId) => queryParams.append('statuses[]', orderId));
                const queryParamsString = queryParams.toString();

                return {
                    url: '/api/stat/merchants-statements?' + queryParamsString,
                    method: 'GET',
                    credentials: 'include',
                };
            },
        }),
        getDataByOrderId: build.query<DataByOrderIdResponse, DataByOrderIdRequest>({
            query: (arg) => ({
                url: '/api/orders/' + arg,
                method: 'GET',
                credentials: 'include',
            }),
        }),
        getExportedStatementsByCoupons: build.mutation<
            GetExportedStatementsByCouponsResponse,
            GetExportedStatementsByCouponsRequest
        >({
            query: ({ coupons, dateStart, dateEnd }) => {
                return {
                    url: '/api/stat/export-statement',
                    method: 'POST',
                    credentials: 'include',
                    body: {
                        coupons,
                        dateStart,
                        dateEnd,
                        deliveryType: 'download',
                    },
                };
            },
        }),

        getMerchants: build.query<GetMerchantsResponse, GetMerchantsRequest>({
            query: ({ limit, offset } = {}) => {
                const queryParams = new URLSearchParams({
                    ...(typeof limit === 'number' && { limit: limit.toString() }),
                    ...(typeof offset === 'number' && { offset: offset.toString() }),
                });
                const queryParamsString = queryParams.toString();

                return {
                    url: '/api/merchants?' + queryParamsString,
                    method: 'GET',
                    credentials: 'include',
                };
            },
        }),
        getMerchantById: build.query<GetMerchantByIdResponse, GetMerchantByIdRequest>({
            query: ({ id }) => ({
                url: `/api/merchants/${id}`,
                method: 'GET',
                credentials: 'include',
            }),
        }),
        getDirectoryData: build.query<GetDirectoryDataResponse, GetDirectoryDataRequest>({
            query: () => ({
                url: '/api/directory/data',
                method: 'GET',
                credentials: 'include',
            }),
        }),
    }),
});

export const {
    useGetCouponsQuery,
    useGetTransferVolumeQuery,
    useGetMerchantsStatementsQuery,
    useLazyGetMerchantsStatementsQuery,
    useGetDataByOrderIdQuery,
    useGetExportedStatementsByCouponsMutation,
    useGetMerchantsQuery,
    useLazyGetMerchantsQuery,
    useGetMerchantByIdQuery,
    useGetDirectoryDataQuery,
} = gatewayApiAdminData;
