import { FC } from 'react';
import { Link as MUILink, LinkProps } from '@mui/material';
import { Link as RouterLink, To } from 'react-router-dom';

export const Link: FC<Omit<LinkProps, 'href'> & { href: To; state?: any }> = ({
    href,
    children,
    style,
    state,
    ...props
}) => (
    <MUILink {...props} component='span'>
        <RouterLink
            style={style ?? { color: 'inherit', textDecoration: 'inherit' }}
            to={href}
            state={state}
        >
            {children}
        </RouterLink>
    </MUILink>
);
