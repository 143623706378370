import { Box, styled } from '@mui/material';
import { FC } from 'react';
import { FilterTag } from '../FilterTag';
import { MerchantCategory } from '../MerchantsFilterPopup/types';

const TagsContainer = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(-0.5),
    marginRight: theme.spacing(-0.5),
}));

type MerchantFilterChipsProps = {
    categories: MerchantCategory[];
    onRemove: (category: MerchantCategory) => void;
};

export const MerchantFilterChips: FC<MerchantFilterChipsProps> = ({ categories, onRemove }) => {
    return (
        <TagsContainer>
            {categories.map((cat) => (
                <FilterTag key={cat.id} label={cat.name} onDelete={() => onRemove(cat)} />
            ))}
        </TagsContainer>
    );
};
