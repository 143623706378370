import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LoginRequest, LoginResponse, ProfileResponse } from './types';

const reducerPath = 'gatewayApi' as const;
const baseUrl = process.env.REACT_APP_STAT_BASE_URL;

export const gatewayApi = createApi({
    refetchOnReconnect: true,
    // refetchOnMountOrArgChange: true,
    reducerPath,
    baseQuery: fetchBaseQuery({ baseUrl }),
    tagTypes: ['User'],
    endpoints: (build) => ({
        login: build.mutation<LoginResponse, LoginRequest>({
            query: (body) => ({
                url: '/auth/login',
                method: 'POST',
                credentials: 'include',
                body,
            }),
            invalidatesTags: ['User'],
        }),
        logout: build.mutation<void, void>({
            query: () => ({
                url: '/auth/logout',
                method: 'POST',
                credentials: 'include',
            }),
            invalidatesTags: ['User'],
        }),
        refresh: build.query({
            query: () => ({
                url: '/auth/refresh-token',
                method: 'GET',
                credentials: 'include',
            }),
        }),
        getProfile: build.query<ProfileResponse, void>({
            query: () => ({
                url: '/api/users/profile',
                credentials: 'include',
                method: 'GET',
            }),
            providesTags: ['User'],
        }),
    }),
});

export const { useLoginMutation, useRefreshQuery, useGetProfileQuery, useLogoutMutation } =
    gatewayApi;
