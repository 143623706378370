import { styled, Link as MUILink } from '@mui/material';
import logoImg from '../../../../../images/logo.svg';
import { FC } from 'react';
import { Link } from '@/ui-kit/components';
import { useMatch } from 'react-router-dom';

const Img = styled('img')(({ theme }) => ({
  marginTop: theme.spacing(1.25),
  height: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
    height: theme.spacing(4),
  },
}));

const DefaultLogo: FC = () => {
  const isRootLocation = useMatch('/');
  const isAuthLocation = useMatch('/auth');
  const isAuthChildLocation = useMatch('/auth/*');
  if (isRootLocation || isAuthLocation) {
    return (
      <MUILink href='/'>
        <Img alt='libersave-logo' src={logoImg} />
      </MUILink>
    );
  }
  if (isAuthChildLocation) {
    return (
      <Link href='/auth'>
        <Img alt='libersave-logo' src={logoImg} />
      </Link>
    );
  }
  return (
    <Link href='/'>
      <Img alt='libersave-logo' src={logoImg} />
    </Link>
  );
};

const LabelLogo: FC = () => {
  const isRootLocation = useMatch('/');
  const isAuthLocation = useMatch('/auth');
  const isAuthChildLocation = useMatch('/auth/*');
  if (isRootLocation || isAuthLocation) {
    return (
      <MUILink href='/'>
        <Img
          alt={`${process.env.REACT_APP_LABEL_ID}-logo`}
          src={`${process.env.PUBLIC_URL}/logo.svg`}
        />
      </MUILink>
    );
  }
  if (isAuthChildLocation) {
    return (
      <Link href='/auth'>
        <Img
          alt={`${process.env.REACT_APP_LABEL_ID}-logo`}
          src={`${process.env.PUBLIC_URL}/logo.svg`}
        />
      </Link>
    );
  }
  return (
    <Link href='/'>
      <Img
        alt={`${process.env.REACT_APP_LABEL_ID}-logo`}
        src={`${process.env.PUBLIC_URL}/logo.svg`}
      />
    </Link>
  );
};

// use different components for labeled and unlabeled apps during the build phase
// to maintain compatibility until all applications start using the new labeled components
export const Logo = process.env.REACT_APP_LABEL_NAME ? LabelLogo : DefaultLogo;
