import { Box, Drawer as DrawerBase, styled, SvgIcon } from '@mui/material';
import { ElementType, FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { LogoIcon, LogoutIcon, SettingsIcon } from '@/ui-kit/icons';
import { LanguageSwitcher } from '@/bo-kit/components';
import { MenuButton } from './components/MenuButton';
import {
  SideMenuItemType,
  useSideMenuItems,
} from '@/bo-kit/components/AuthorizedLayout/hooks/useSideMenuItems';

const sideMenuWidth = 16; // in spacing(X) units

const Section: FC<PropsWithChildren> = ({ children }) => (
  <Box sx={{ flex: 0, display: 'flex', flexDirection: 'column' }}>{children}</Box>
);

const MenuIcon: FC<{ component: ElementType }> = ({ component }) => (
  <SvgIcon color='inherit' fontSize='inherit' component={component} inheritViewBox />
);

const MIN_SIDEBAR_HEIGHT = 65; // in spacing(X) units

const Drawer = styled(DrawerBase)(({ theme }) => ({
  width: theme.spacing(sideMenuWidth - 0.5),
  float: 'left',
  minHeight: theme.spacing(MIN_SIDEBAR_HEIGHT),
  height: '100vh',
  position: 'fixed',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));

export const SideMenu: FC = () => {
  const { t } = useTranslation('bo-kit__common');
  const { items, hideItems } = useSideMenuItems();

  return (
    <Drawer
      variant='permanent'
      PaperProps={{
        sx: ({ spacing }) => ({
          alignItems: 'center',
          border: 0,
          paddingX: 1,
          paddingTop: 5,
          paddingBottom: 3,
          height: `max(100%, ${spacing(74)})`,
          top: 0,
          // TODO: force it to work!
          position: 'sticky',
          borderRadius: spacing(0, 3, 3, 0),
          minWidth: 128,
          justifyContent: 'space-between',
        }),
      }}
    >
      <Section>
        <LogoIcon sx={{ fontSize: '5rem' }} />
      </Section>
      {items.length > 0 && (
        <Section>
          {items.map(({ type, icon, href }) => (
            <MenuButton
              key={href}
              icon={<MenuIcon component={icon} />}
              href={href}
              strict={type === SideMenuItemType.MAIN}
            >
              {t(type)}
            </MenuButton>
          ))}
        </Section>
      )}
      <Section>
        <LanguageSwitcher variant='short' dropdownAlign='left' />
        {!hideItems.includes(SideMenuItemType.SETTINGS) && (
          <MenuButton icon={<MenuIcon component={SettingsIcon} />} href='/settings'>
            {t('Settings')}
          </MenuButton>
        )}
        {!hideItems.includes(SideMenuItemType.LOGOUT) && (
          <MenuButton icon={<MenuIcon component={LogoutIcon} />} href='/logout'>
            {t('Log out')}
          </MenuButton>
        )}
      </Section>
    </Drawer>
  );
};

const SideMenuAncestor = styled('div')(({ theme }) => ({
  height: '100%',
  width: `calc(100vw - ${theme.spacing(sideMenuWidth)})`,
  float: 'right',
  zIndex: -1,
}));

export const WithSideMenu: FC<PropsWithChildren> = ({ children }) => (
  <>
    <SideMenu />
    <SideMenuAncestor>{children}</SideMenuAncestor>
  </>
);
