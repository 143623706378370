import React from 'react';
import {
    TextField,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
    Checkbox,
    ListItemText,
    OutlinedInput,
    Box,
    FormHelperText,
} from '@mui/material';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as Yup from 'yup';
import { RoleData } from '@/transport/usersApi/types';
import { Permission } from '@/transport/authApi/types';
import { formatPermission, formatPermissions } from '../../utils';

const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    code: Yup.string().required('Code is required'),
    permissions: Yup.array().required('Permissions are required'),
});

interface UserRoleFormProps {
    initialValues?: RoleData;
    onSubmit: (values: RoleData) => void;
    formRef: React.RefObject<FormikProps<RoleData>>;
    disabled?: boolean;
}

const UserRoleForm: React.FC<UserRoleFormProps> = ({
    initialValues,
    onSubmit,
    formRef,
    disabled = true,
}) => {
    const permissionData = Object.values(Permission);

    return (
        <Formik
            initialValues={
                initialValues || {
                    title: '',
                    code: '',
                    permissions: [],
                }
            }
            innerRef={formRef}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ values, handleChange, setFieldValue, touched, errors }) => (
                <Form>
                    <Box
                        sx={({ spacing }) => ({
                            display: 'flex',
                            flexDirection: 'column',
                            gap: spacing(3),
                        })}
                    >
                        <Field
                            as={TextField}
                            name='title'
                            label='Title'
                            fullWidth
                            variant='outlined'
                            error={touched.title && !!errors.title}
                            helperText={touched.title && errors.title}
                            disabled={disabled}
                        />
                        <Field
                            as={TextField}
                            name='code'
                            label='Code'
                            fullWidth
                            variant='outlined'
                            error={touched.code && !!errors.code}
                            helperText={touched.code && errors.code}
                            disabled={disabled}
                        />

                        <FormControl
                            fullWidth
                            variant='outlined'
                            error={touched.permissions && !!errors.permissions}
                            disabled={disabled}
                        >
                            <InputLabel>Permissions</InputLabel>
                            <Select
                                multiple
                                name='permissions'
                                value={values.permissions}
                                onChange={(event) =>
                                    setFieldValue('permissions', event.target.value)
                                }
                                input={<OutlinedInput label='Permissions' />}
                                renderValue={(value) => formatPermissions(value)}
                            >
                                {permissionData.map((permission) => (
                                    <MenuItem key={permission} value={permission}>
                                        <Checkbox
                                            checked={values.permissions.includes(permission)}
                                        />
                                        <ListItemText primary={formatPermission(permission)} />
                                    </MenuItem>
                                ))}
                            </Select>
                            {touched.permissions && errors.permissions && (
                                <FormHelperText>{errors.permissions}</FormHelperText>
                            )}
                        </FormControl>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default UserRoleForm;
