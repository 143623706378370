import React from 'react';
import {
    AccountCircle,
    // MonetizationOn
} from '@mui/icons-material';

import { StatMerchantExtended } from '@/transport/getAdminDataApi/types';
import { Box, Typography, Divider, Chip, Stack } from '@mui/material';
import { AmountRow } from '@/components/InfoDetails/AmountRow';
import { InfoCard, InfoContent, InfoTitle } from '@/components/InfoDetails/InfoItem';
import { formatMerchantFullName } from '@/pages/MerchantsList/utils';

type MerchantDetailsProps = {
    data: StatMerchantExtended;
};

const HARDCODED_CURR = 'EUR';

export const MerchantDetails: React.FC<MerchantDetailsProps> = ({ data }) => {
    const formattedDate =
        data.lastOperationDate && new Date(data.lastOperationDate).toLocaleString();
    const userName = formatMerchantFullName(data.firstName, data.lastName, data.legalName) || 'N/A';

    return (
        <Box width={'100%'}>
            <Box display='flex' justifyContent='space-between' alignItems='center' mb={4}>
                <Typography variant='h2' fontWeight='bold'>
                    Merchant Details
                </Typography>
                {/* <StatusChip status={data.isActivated ? 'ACTIVE' : 'INACTIVE'} /> */}
            </Box>

            <Box sx={{ mb: 4 }}>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: { sm: '1fr', md: '1fr 1fr' },
                        mb: 3,
                        gap: 3,
                        alignItems: 'start',
                    }}
                >
                    <InfoCard>
                        <InfoTitle>Merchant ID</InfoTitle>
                        <InfoContent>{data.id}</InfoContent>
                        <Divider sx={{ my: 1 }} />
                    </InfoCard>

                    <InfoCard>
                        <InfoTitle>Email</InfoTitle>
                        <InfoContent>{data.email}</InfoContent>
                        <Divider sx={{ my: 1 }} />
                    </InfoCard>
                </Box>

                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: { sm: '1fr', md: '1fr 1fr 1fr' },
                        mb: 3,
                        gap: 3,
                        alignItems: 'start',
                    }}
                >
                    <InfoCard>
                        <InfoTitle>Name</InfoTitle>
                        <Box display='flex' alignItems='center'>
                            <AccountCircle sx={{ mr: 1 }} />
                            <InfoContent>{userName}</InfoContent>
                        </Box>
                        <Divider sx={{ my: 1 }} />
                    </InfoCard>

                    <InfoCard>
                        <InfoTitle>Coupon</InfoTitle>
                        <Box display='flex' alignItems='center'>
                            <Chip
                                label={data.coupon}
                                sx={{ color: 'white', fontWeight: 'bold', mr: 1 }}
                                color='secondary'
                                size='small'
                            />
                            <Typography
                                sx={({ palette }) => ({
                                    fontSize: 16,
                                    color: palette.text.secondary,
                                })}
                            >
                                (Rate: {data.stat.couponExchangeRate})
                            </Typography>
                        </Box>
                        <Divider sx={{ my: 1 }} />
                    </InfoCard>

                    <InfoCard>
                        <InfoTitle>Last Operation</InfoTitle>
                        <InfoContent>{data.lastOperationTariff}</InfoContent>
                        <Typography variant='caption'>{formattedDate}</Typography>
                        <Divider sx={{ my: 1 }} />
                    </InfoCard>
                </Box>
            </Box>

            <Box>
                <InfoTitle>Financial Summary</InfoTitle>

                <Stack direction='column' gap={1}>
                    <AmountRow
                        title='Bank Balance'
                        amount={data.balances.bank}
                        currency={HARDCODED_CURR}
                    />
                    <AmountRow
                        title='Platform Fee'
                        amount={data.balances.platformFee}
                        currency={HARDCODED_CURR}
                    />
                    <AmountRow
                        title='Stat Balance'
                        amount={data.stat.balance}
                        currency={HARDCODED_CURR}
                        // isBold
                    />
                    <AmountRow
                        title='Platform Fee Debt'
                        amount={data.stat.debt.platformFee}
                        currency={HARDCODED_CURR}
                    />
                    <AmountRow
                        title='Platform Income Total'
                        amount={data.platformIncome.total}
                        currency={HARDCODED_CURR}
                    />
                </Stack>
            </Box>
            <Box mt={4}>
                <InfoTitle>Withdrawal</InfoTitle>

                <Stack direction='column' gap={1}>
                    <AmountRow
                        title='Available'
                        amount={data.stat.withdrawal.available}
                        currency={HARDCODED_CURR}
                    />
                    <AmountRow
                        title='Total'
                        amount={data.stat.withdrawal.total}
                        currency={HARDCODED_CURR}
                        isBold={true}
                    />
                </Stack>
            </Box>

            <Box mt={4}>
                <InfoTitle>Sales Statistics</InfoTitle>

                <Stack direction='column' gap={1}>
                    <AmountRow
                        title='Total Redeemed'
                        amount={data.stat.sales.redeemedTotal}
                        currency={HARDCODED_CURR}
                    />
                    <AmountRow
                        title='Total Hold'
                        amount={data.stat.sales.holdTotal}
                        currency={HARDCODED_CURR}
                    />
                    <AmountRow
                        title='Total Sold'
                        amount={data.stat.sales.soldTotal}
                        currency={HARDCODED_CURR}
                    />
                    <AmountRow
                        title='Total Issued'
                        amount={data.stat.sales.issuedTotal}
                        currency={HARDCODED_CURR}
                        isBold={true}
                    />
                </Stack>
            </Box>

            <Box mt={4}>
                <InfoTitle>Sold</InfoTitle>

                <Stack direction='column' gap={1}>
                    <AmountRow
                        title='Today'
                        amount={data.stat.sales.soldToday}
                        currency={HARDCODED_CURR}
                    />
                    <AmountRow
                        title='This Month'
                        amount={data.stat.sales.soldMonth}
                        currency={HARDCODED_CURR}
                    />
                    {/* <Divider /> */}
                    <AmountRow
                        title='Total'
                        amount={data.stat.sales.soldTotal}
                        currency={HARDCODED_CURR}
                        isBold
                    />
                </Stack>
            </Box>

            <Box mt={4}>
                <InfoTitle>Revenue</InfoTitle>

                <Stack direction='column' gap={1}>
                    <AmountRow
                        title='Today'
                        amount={data.stat.revenue.today}
                        currency={HARDCODED_CURR}
                    />
                    <AmountRow
                        title='This Month'
                        amount={data.stat.revenue.month}
                        currency={HARDCODED_CURR}
                    />
                    {/* <Divider /> */}
                    <AmountRow
                        title='Total'
                        amount={data.stat.revenue.total}
                        currency={HARDCODED_CURR}
                        isBold
                    />
                </Stack>
            </Box>
        </Box>
    );
};
