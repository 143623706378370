import { createSvgIcon } from '@mui/material/utils';

export const BagIcon = createSvgIcon(
    <>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M 5.738 3.607 L 5.738 5.469 L 3.518 5.648 C 1.949 5.775 0.668 6.957 0.417 8.511 C 0.362 8.849 0.312 9.188 0.267 9.527 C 0.245 9.695 0.335 9.857 0.488 9.93 L 0.589 9.978 C 7.704 13.347 16.223 13.347 23.338 9.978 L 23.439 9.93 C 23.592 9.858 23.682 9.695 23.66 9.527 C 23.615 9.188 23.565 8.849 23.511 8.511 C 23.259 6.957 21.979 5.775 20.409 5.648 L 18.189 5.469 L 18.189 3.607 C 18.189 2.472 17.358 1.508 16.236 1.339 L 14.637 1.099 C 12.865 0.834 11.063 0.834 9.291 1.099 L 7.692 1.339 C 6.569 1.508 5.738 2.472 5.738 3.607 Z M 14.345 3.044 C 12.766 2.807 11.161 2.807 9.582 3.044 L 7.983 3.283 C 7.823 3.307 7.704 3.445 7.704 3.607 L 7.704 5.331 C 10.542 5.168 13.386 5.168 16.223 5.331 L 16.223 3.607 C 16.223 3.445 16.104 3.307 15.944 3.283 L 14.345 3.044 Z'
        />
        <path d='M 23.914 12.27 C 23.904 12.086 23.712 11.971 23.544 12.046 C 16.243 15.279 7.685 15.279 0.384 12.046 C 0.216 11.971 0.023 12.086 0.014 12.27 C -0.12 14.775 0.015 17.291 0.417 19.774 C 0.668 21.329 1.949 22.51 3.518 22.637 L 5.972 22.835 C 9.96 23.157 13.967 23.157 17.956 22.835 L 20.409 22.637 C 21.979 22.51 23.259 21.329 23.511 19.774 C 23.913 17.291 24.047 14.775 23.914 12.27 Z' />
    </>,
    'BagIcon'
);
