import { WithSideMenu } from '@/bo-kit/components';
import { styled } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

const ContainerInner = styled('div')(({ theme }) => ({
    // minWidth: theme.breakpoints.values.lg,
    maxWidth: '2000px',
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    height: '100%',
}));

export const StatDesktopAuthorizedLayout: FC<PropsWithChildren> = ({ children }) => (
    <WithSideMenu>
        <ContainerInner>{children}</ContainerInner>
    </WithSideMenu>
);
