import { Permission } from '@/transport/authApi/types';

const parmissionsMap = {
    [Permission.MerchantsRead]: 'Merchants [Read]',
    [Permission.MerchantsWithdrawals]: 'Merchants Withdrawals [Read]',
    [Permission.OrdersRead]: 'Orders [Read]',
    [Permission.RolesRead]: 'Roles [Read]',
    [Permission.RolesEdit]: 'Roles [Edit]',
    [Permission.StatRead]: 'Statements [Read]',
    [Permission.UsersEdit]: 'Users [Edit]',
    [Permission.UsersRead]: 'Users [Read]',
    [Permission.AccountTransactionsRead]: 'Account Transactions [Read]',
    [Permission.AccountTransactionsEdit]: 'Account Transactions [Edit]',
};

export const formatPermissions = (permissions: Permission[] = []) => {
    return permissions.map(formatPermission).join(', ');
};

export const formatPermission = (permission: Permission) => {
    return parmissionsMap[permission] || permission;
};
