import { Context, createContext, FC, PropsWithChildren, useContext } from 'react';
import {
    TransProps,
    UseTranslationOptions,
    UseTranslationResponse,
    Trans as TransBase,
} from 'react-i18next';
import { DefaultNamespace, KeyPrefix, Namespace } from 'i18next';

type UseTranslationHookType<
    N extends Namespace = DefaultNamespace,
    TKPrefix extends KeyPrefix<N> = undefined
> = (
    ns?: N | Readonly<N>,
    options?: UseTranslationOptions<TKPrefix>
) => UseTranslationResponse<N, TKPrefix>;

const I18nHookContext = createContext<any>(undefined);
I18nHookContext.displayName = 'I18nHookContext';

export const I18nHookContextProvider: FC<PropsWithChildren<{ hook: UseTranslationHookType }>> = ({
    hook,
    children,
}) => {
    return <I18nHookContext.Provider value={hook}>{children}</I18nHookContext.Provider>;
};

const DefaultI18nContext = createContext<string | undefined>(undefined);
DefaultI18nContext.displayName = 'DefaultI18nContext';

export const DefaultI18nNSContextProvider: FC<PropsWithChildren<{ ns: string }>> = ({
    ns,
    children,
}) => {
    return <DefaultI18nContext.Provider value={ns}>{children}</DefaultI18nContext.Provider>;
};

export const useTranslation = <
    N extends Namespace = DefaultNamespace,
    TKPrefix extends KeyPrefix<N> = undefined
>(
    ...args: Parameters<UseTranslationHookType<N, TKPrefix>>
): ReturnType<UseTranslationHookType<N, TKPrefix>> => {
    const [ns, options] = args;
    const useTranslationBase = useContext<UseTranslationHookType<N, TKPrefix> | undefined>(
        I18nHookContext
    );
    if (!useTranslationBase) {
        throw new Error(
            "No useTranslationBase hooks defined with react context, pls implement: '<I18nHookContextProvider hooks={useTranslation}>{children}</I18nHookContextProvider>'"
        );
    }

    const contextNs = useContext<N>(DefaultI18nContext as unknown as Context<N>);
    return useTranslationBase(ns ?? contextNs, options);
};

export const Trans: FC<Omit<TransProps<any>, 'ns'> & { ns?: string }> = (props) => {
    const { t } = useTranslation(props.ns);

    return <TransBase {...props} t={t} />;
};
