import { Skeleton } from '@mui/material';
import { FC } from 'react';
import { Cell, Row } from './TableElements';

const PrimaryTextSkeleton: FC = () => (
    <Skeleton variant='text' sx={({ typography }) => ({ fontSize: typography.fontSize * 1.5 })} />
);

export const UserRoleRowSkeleton = ({ showActions }: { showActions: boolean }) => {
    return (
        <Row showActions={showActions}>
            <Cell primaryText={<PrimaryTextSkeleton />} />
            <Cell primaryText={<PrimaryTextSkeleton />} />
            <Cell primaryText={<PrimaryTextSkeleton />} />
            <Cell primaryText={<PrimaryTextSkeleton />} secondaryText={<Skeleton />} />
            <Cell primaryText={<PrimaryTextSkeleton />} />
            {showActions && <Cell primaryText={<PrimaryTextSkeleton />} />}
        </Row>
    );
};
