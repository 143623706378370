import { Permission } from '@/transport/authApi/types';
import React, { PropsWithChildren } from 'react';
import { usePermissionGuard } from './usePermissionGuard';

type PermissionGuardProps = {
    allowingPermission: Permission;
    forbiddenElement?: React.ReactNode;
    loadingElement?: React.ReactNode;
    errorElement?: React.ReactNode;
    allowedElement?: React.ReactNode;
} & PropsWithChildren;

export const PermissionGuard: React.FC<PermissionGuardProps> = ({
    allowingPermission,
    forbiddenElement = null,
    loadingElement = null,
    errorElement = null,
    allowedElement = null,
    children,
}) => {
    const { checkPermission, isError, isLoading } = usePermissionGuard();
    const hasPermission = checkPermission(allowingPermission);

    if (!hasPermission) {
        return <>{forbiddenElement}</>;
    }

    if (isLoading) {
        return <>{loadingElement}</>;
    }
    if (isError) {
        return <>{errorElement}</>;
    }
    return <>{children || allowedElement}</>;
};
