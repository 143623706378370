import { styled, Typography } from '@mui/material';
import { desktopBreakpoint } from '@/ui-kit/hooks/useIsDesktop';

export const PageTitle = styled(Typography)(
    ({ theme: { palette, typography, spacing, breakpoints } }) => ({
        color: palette.common.black,
        fontWeight: typography.fontWeightBold,
        [breakpoints.only('xs')]: {
            fontSize: `${typography.h2.fontSize} !important`,
            marginBottom: `${spacing(3)} !important`,
        },
        [breakpoints.up(desktopBreakpoint)]: {
            fontSize: `${typography.h1.fontSize} !important`,
            marginBottom: `${spacing(5)} !important`,
        },
    })
);
