import { FC, PropsWithChildren } from 'react';
import { SnackbarProvider as SnackbarProviderBase } from 'notistack';
import { AlertErrorNotistack, AlertSuccessNotistack } from '../Alert';

export const SnackbarProvider: FC<PropsWithChildren> = ({ children }) => {
    return (
        <SnackbarProviderBase
            Components={{
                error: AlertErrorNotistack,
                success: AlertSuccessNotistack,
            }}
        >
            {children}
        </SnackbarProviderBase>
    );
};
