import { createSvgIcon } from '@mui/material/utils';

export const ChevronRightIcon = createSvgIcon(
    <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.31952 18.6763C7.89349 18.2446 7.89349 17.5448 8.31952 17.1132L13.3663 12L8.31952 6.8868C7.89349 6.45517 7.89349 5.75536 8.31952 5.32372C8.74555 4.89209 9.43627 4.89209 9.8623 5.32372L15.6805 11.2185C16.1065 11.6501 16.1065 12.3499 15.6805 12.7815L9.8623 18.6763C9.43627 19.1079 8.74555 19.1079 8.31952 18.6763Z'
    />,
    'ChevronRightIcon'
);
