import { FC } from 'react';
import { useTranslation } from '@/ui-kit/hooks';
import { Row, Cell } from './TableElements';
import { formatMerchantFullName } from '../utils';
import { getStatementUTCDateTimeFormat } from '@/ui-kit/utils';

type MerchantRowProps = {
    coupon: string;
    email: string;
    firstName: string | null;
    lastName: string | null;
    legalName: string | null;
    id: string;
    isActivated: boolean;
    isRemoved: boolean;

    bankBalance: number;
    platformFee: number;
    platformIncome: number;
    lastOperationDate: string | null;
    lastOperationTariff: string | null;
};

export const MerchantRow: FC<MerchantRowProps> = ({
    coupon,
    email,
    firstName,
    lastName,
    legalName,
    id,
    isActivated,
    isRemoved,

    bankBalance,
    platformFee,
    platformIncome,
    lastOperationDate,
    lastOperationTariff,
}) => {
    const { t } = useTranslation('merchants');
    // const { palette } = useTheme();

    // const getStatusColor = (isActivated: boolean) => {
    //     return isActivated ? palette.success.dark : palette.error.dark;
    // };

    return (
        <Row>
            <Cell primaryText={id} />
            <Cell
                primaryText={legalName || 'N/A'}
                secondaryText={formatMerchantFullName(firstName, lastName)}
            />
            <Cell primaryText={coupon} />
            <Cell primaryText={email} />
            <Cell primaryText={bankBalance.toFixed(2)} secondaryText={t('CURR')} />
            <Cell primaryText={platformIncome.toFixed(2)} secondaryText={t('CURR')} />
            <Cell primaryText={platformFee.toFixed(2)} secondaryText={t('CURR')} />
            <Cell
                primaryText={lastOperationTariff}
                secondaryText={
                    lastOperationDate && getStatementUTCDateTimeFormat(lastOperationDate)
                }
            />
            <Cell
                sx={{
                    fontWeight: 'bold',
                }}
                primaryText={
                    (isActivated ? t('Activated') : t('Deactivated')) +
                    '\n' +
                    (isRemoved ? t('Removed') : t('Not removed'))
                }
            />
        </Row>
    );
};
