import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from './components/Container';
import { Content } from './components/Content';
import { FullScreenContainer } from './components/FullScreenContainer';
import { NonAuthorizedLayoutHeader } from './components/Header';

export const NonAuthorizedLayout: FC = () => (
  <Container>
    <FullScreenContainer>
      <NonAuthorizedLayoutHeader />
      <Content>
        <Outlet />
      </Content>
    </FullScreenContainer>
  </Container>
);

// ADHOC: support lazy import
export default NonAuthorizedLayout;
