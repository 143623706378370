import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionsPage } from '../components/';
import { Outlet } from 'react-router-dom';

const MobileAccountTransactionsPage: FC = () => {
    const { t } = useTranslation('statements');

    return (
        <>
            <Stack direction='row' justifyContent='space-between'>
                <Box>
                    <Typography variant='h1' fontWeight='bold' sx={{ mb: 1.25 }}>
                        {t('Account transactions')}
                    </Typography>
                </Box>
            </Stack>
            <TransactionsPage />
            <Outlet />
        </>
    );
};

export default MobileAccountTransactionsPage;
