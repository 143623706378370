import { useCallback, useLayoutEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { subMonths } from 'date-fns';
import { getDateInYYYYMMDDCurrentTZFormat } from '@/ui-kit/utils/getDateInYYYYMMDDCurrentTZFormat';

const today = new Date();
const monthAgo = subMonths(new Date(), 1);
const todayStringified = getDateInYYYYMMDDCurrentTZFormat(today);
const monthAgoStringified = getDateInYYYYMMDDCurrentTZFormat(monthAgo);

export const useTransactionFilters = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    useLayoutEffect(() => {
        // console.log('useLayoutEffect');
        // let isChanged = false;
        if (!searchParams.get('dateStart')) {
            searchParams.set('dateStart', monthAgoStringified);
            // isChanged = true;
        }
        if (!searchParams.get('dateEnd')) {
            searchParams.set('dateEnd', todayStringified);
            // isChanged = true;
        }
        if (!searchParams.has('isTestTransfers')) {
            searchParams.set('isTestTransfers', 'false');
            // isChanged = true;
        }

        // if (isChanged) {
        setSearchParams(searchParams);
        // }
    }, [searchParams, setSearchParams]);

    const getSearchParamArray = useCallback(
        (key: string): string[] => {
            return searchParams.getAll(key) || [];
        },
        [searchParams]
    );

    const setSearchParamArray = (key: string, value: string[]): void => {
        searchParams.delete(key);
        value.forEach((item) => searchParams.append(key, item));
        setSearchParams(searchParams);
    };

    const setSearchParam = (key: string, value: string): void => {
        searchParams.set(key, value);
        setSearchParams(searchParams);
    };

    const dateStart = useMemo(
        () => searchParams.get('dateStart') || monthAgoStringified,
        [searchParams]
    );
    const dateEnd = useMemo(() => searchParams.get('dateEnd') || todayStringified, [searchParams]);
    const selectedCoupons = useMemo(() => getSearchParamArray('coupons[]'), [getSearchParamArray]);
    const orderIds = useMemo(() => getSearchParamArray('someOrderIds[]'), [getSearchParamArray]);
    const institutionIds = useMemo(
        () => getSearchParamArray('institutionIds[]'),
        [getSearchParamArray]
    );
    const countryCodes = useMemo(
        () => getSearchParamArray('institutionCountryCodes[]'),
        [getSearchParamArray]
    );
    const statementStatuses = useMemo(
        () => getSearchParamArray('statuses[]'),
        [getSearchParamArray]
    );
    const showTestTransfers = useMemo(
        () => searchParams.get('isTestTransfers') === 'true',
        [searchParams]
    );

    return {
        dateStart,
        dateEnd,
        selectedCoupons,
        orderIds,
        institutionIds,
        countryCodes,
        statementStatuses,
        showTestTransfers,

        setDateStart: (date: Date) =>
            setSearchParam('dateStart', getDateInYYYYMMDDCurrentTZFormat(date)),
        setDateEnd: (date: Date) =>
            setSearchParam('dateEnd', getDateInYYYYMMDDCurrentTZFormat(date)),
        setSelectedCoupons: (coupons: string[]) => setSearchParamArray('coupons[]', coupons),
        setOrderIds: (orderIds: string[]) => setSearchParamArray('someOrderIds[]', orderIds),
        setInstitutionIds: (institutionIds: string[]) =>
            setSearchParamArray('institutionIds[]', institutionIds),
        setCountryCodes: (countryCodes: string[]) =>
            setSearchParamArray('institutionCountryCodes[]', countryCodes),
        setStatementStatuses: (statuses: string[]) => setSearchParamArray('statuses[]', statuses),
        setShowTestTransfers: (show: boolean) =>
            setSearchParam('isTestTransfers', show ? 'true' : 'false'),
    };
};
