import { Box } from '@mui/material';
import { TransactionFilters } from './components/TransactionFilters';
import { TransactionTable } from './components/TransactionTable';

export const TransactionList = () => {
    return (
        <Box>
            <TransactionFilters />
            <TransactionTable />
        </Box>
    );
};
