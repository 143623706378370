import { Box } from '@mui/material';
import { createSvgIcon } from '@mui/material/utils';

export const DefaultLogoIcon = createSvgIcon(
    <>
        <path
            d='M 14.713 1.55 L 9.287 1.55 L 0 17.734 L 2.727 22.42 L 21.273 22.42 L 24 17.734 L 14.713 1.55 Z'
            fill='#E63537'
        />
        <path
            d='M 19.257 17.905 L 18.519 19.155 C 12.043 17.962 5.482 19.155 5.425 19.155 L 4.261 17.053 C 4.289 17.025 8.152 12.709 10.793 5.639 L 13.179 5.639 C 13.009 6.973 12.355 8.62 12.242 8.819 C 10.821 12.028 9.287 14.554 8.095 16.258 C 10.111 16.088 12.895 15.974 15.905 16.23 C 15.735 16.003 15.593 15.776 15.451 15.605 C 14.741 15.548 13.633 15.435 11.645 15.491 L 10.538 13.532 C 10.566 13.475 12.469 10.523 13.605 6.661 C 14.087 7.882 14.627 9.018 15.139 10.04 C 14.741 11.091 14.315 12.113 13.832 12.936 C 15.195 12.965 16.275 13.078 16.956 13.192 C 18.32 15.378 19.456 16.712 19.683 16.968 C 19.711 16.997 19.711 17.025 19.711 17.053 L 19.257 17.905 Z'
            fill='#F8F8F8'
        />
    </>,
    'LogoIcon'
);

export const LabelLogoIcon = ({ ...props }) => {
    return (
        <Box
            component={'img'}
            src={`${process.env.PUBLIC_URL}/logo-icon.svg`}
            alt='logo'
            height={80}
            width={80}
            {...props}
        />
    );
};

// use different components for labeled and unlabeled apps during the build phase
// to maintain compatibility until all applications start using the new labeled components
export const LogoIcon = process.env.REACT_APP_LABEL_NAME ? LabelLogoIcon : DefaultLogoIcon;
