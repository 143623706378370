import { createSvgIcon } from '@mui/material/utils';

export const RefillIcon = createSvgIcon(
    <>
        <path
            d='M15.285.695C-1.27-4.277-5.848 18.84 8.906 23.406c14.758 4.528 21.489-18.183 6.38-22.71zm0 0'
            fill='#01ba69'
        />
        <path
            d='M17.129 6.04a99.832 99.832 0 01-7.516 9.335c-.707-1.957-1.449-3.875-2.152-5.832-.473-1.262-2.39-.691-1.957.57.898 2.489 1.84 4.977 2.738 7.461.274.696 1.098 1.063 1.684.45 3.207-3.422 6.144-7.094 8.883-10.926.824-1.102-.899-2.16-1.68-1.059zm0 0'
            fill='#f8f8f8'
        />
    </>,
    'RefillIcon'
);
