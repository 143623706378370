import React from 'react';
import { useTranslation } from '@/ui-kit/hooks';
import { Box, Button, Stack } from '@mui/material';
import { ElevatedPaper } from '@/bo-kit/components/ElevatedPaper';
// import InfiniteScroll from 'react-infinite-scroll-component';
import { UserRoleRowSkeleton } from './components/UserRoleRowSkeleton';
import { UserRoleRow } from './components/UserRoleRow';
import { CellHeader, RowHeader } from './components/TableElements';
import { useNavigate } from 'react-router-dom';
import { useGetUserRolesQuery } from '@/transport/usersApi';
import GroupIcon from '@mui/icons-material/Group';
import { usePermissionGuard } from '@/components/Permissions/usePermissionGuard';
import { Permission } from '@/transport/authApi/types';
import { PermissionGuard } from '@/components/Permissions/PermissionGuard';

export const UserRolesTable: React.FC = () => {
    const { t } = useTranslation('Users');
    const { checkPermission } = usePermissionGuard();
    const showActions = checkPermission(Permission.RolesEdit);

    const columns = ['Name', 'Code', 'Permissions', 'Created', ...(showActions ? ['Actions'] : [])];

    const { data: rolesData, isError, isLoading } = useGetUserRolesQuery();

    // const loadNextPage = () => {};
    // const hasNextPage = true;

    const navigate = useNavigate();

    const handleCreateRole = () => {
        navigate('/users/roles/create');
    };

    return (
        <>
            <PermissionGuard allowingPermission={Permission.RolesEdit}>
                <Stack
                    direction='row-reverse'
                    sx={({ spacing }) => ({
                        mb: spacing(1),
                        gap: spacing(1),
                    })}
                >
                    <Button variant='outlined' startIcon={<GroupIcon />} onClick={handleCreateRole}>
                        Create role
                    </Button>
                </Stack>
            </PermissionGuard>
            <Box sx={{ overflow: 'auto' }}>
                <Box sx={{ minWidth: '1000px', position: 'relative' }}>
                    <ElevatedPaper
                        sx={({ spacing }) => ({
                            py: spacing(3),
                            px: spacing(5),
                            width: 'fit-content',
                            minWidth: '100%',
                            margin: '0 auto',
                            mb: spacing(1),
                        })}
                    >
                        <div>
                            <RowHeader
                                showActions={showActions}
                                sx={({ spacing }) => ({
                                    mt: spacing(1),
                                    px: spacing(5),
                                    py: spacing(2.5),
                                })}
                            >
                                {columns.map((columnName) => (
                                    <CellHeader key={columnName}>{t(columnName)}</CellHeader>
                                ))}
                            </RowHeader>
                        </div>
                    </ElevatedPaper>
                    <ElevatedPaper
                        sx={({ spacing }) => ({
                            py: spacing(3),
                            px: spacing(5),
                            width: 'fit-content',
                            minWidth: '100%',
                            margin: '0 auto',
                            mb: spacing(4),
                        })}
                    >
                        {isLoading &&
                            Array.from({ length: 8 }, (_, index) => index).map((_, index) => (
                                <UserRoleRowSkeleton showActions={showActions} key={index} />
                            ))}
                        {/* <InfiniteScroll
                        dataLength={rolesData?.data.length || 0}
                        next={loadNextPage}
                        hasMore={hasNextPage}
                        loader={<UserRoleRowSkeleton />}
                    > */}
                        {!isLoading &&
                            !isError &&
                            rolesData?.data.map((role) => (
                                // <div
                                //     key={role.id}
                                //     onClick={() => {
                                //         handleClickroleRow(role.id);
                                //     }}
                                //     style={{ cursor: 'pointer' }}
                                // >
                                <UserRoleRow
                                    key={role.id}
                                    id={role.id}
                                    title={role.title}
                                    code={role.code}
                                    permissions={role.permissions}
                                    createdAt={role.createdAt}
                                    updatedAt={role.updatedAt}
                                    showActions={showActions}
                                />
                                // </div>
                            ))}
                        {/* </InfiniteScroll> */}
                    </ElevatedPaper>
                </Box>
            </Box>
        </>
    );
};
