import { Paper, styled, PaperProps } from '@mui/material';

type LinkProps = {
  component?: string;
  href?: string;
  target?: string;
};

export const ElevatedPaper = styled(
  (
    props: PaperProps &
      LinkProps & {
        background?: string;
      }
  ) => <Paper elevation={4} {...props} />
)(({ theme, background }) => ({
  padding: theme.spacing(2),
  background,
  display: 'block',
  textDecoration: 'none',
}));
