import { enUS, de, ru, Locale } from 'date-fns/locale'; // import all locales we need
import { Language } from '@/ui-kit/types';

const dateLocalesMap = {
    en: enUS,
    de: de,
    ru: ru,
};

export const getDateFnsLocale = (language: Language): Locale => dateLocalesMap[language] ?? enUS;
