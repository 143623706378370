import { FC } from 'react';
import { useTranslation } from '@/ui-kit/hooks';
import { Row, Cell } from './TableElements';
import { formatRoles, formatUserFullName } from '../../utils';
import { Permission } from '@/transport/authApi/types';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';
import { useNavigate } from 'react-router-dom';
import { useGetUserRolesQuery } from '@/transport/usersApi';
import { getStatementUTCDateTimeFormat } from '@/ui-kit/utils';

type UserRowProps = {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    roles: string[];
    permissions: Permission[];
    isActive: boolean;
    isAdmin: boolean;
    createdAt: string;
    updatedAt: string;
    showActions: boolean;
    disableActions?: boolean;
};

export const UserRow: FC<UserRowProps> = ({
    id,
    email,
    firstName,
    lastName,
    roles,
    permissions,
    isActive,
    isAdmin,
    createdAt,
    updatedAt,
    showActions,
    disableActions,
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation('users');
    const { data: rolesData } = useGetUserRolesQuery();
    // const { palette } = useTheme();

    // const getStatusColor = (isActivated: boolean) => {
    //     return isActivated ? palette.success.dark : palette.error.dark;
    // };

    const handleResetPassword = () => {
        navigate(`./reset/${id}`);
    };
    const handleDelete = () => {
        navigate(`./delete/${id}`);
    };
    const handleUpdate = () => {
        navigate(`./update/${id}`);
    };

    return (
        <Row showActions={showActions}>
            <Cell primaryText={formatUserFullName(firstName, lastName)} />
            <Cell primaryText={email} />
            <Cell
                primaryText={
                    <>
                        {isAdmin && (
                            <Box display='flex' alignItems='center' fontWeight='bold'>
                                <LocalPoliceIcon fontSize='small' /> ADMIN
                            </Box>
                        )}
                        {rolesData?.data && formatRoles(rolesData?.data, roles)}
                    </>
                }
            />
            <Cell
                primaryText={getStatementUTCDateTimeFormat(createdAt)}
                secondaryText={getStatementUTCDateTimeFormat(updatedAt)}
            />
            <Cell
                sx={{
                    fontWeight: 'bold',
                }}
                primaryText={isActive ? t('Active') : t('Not active')}
            />

            {showActions && (
                <Cell
                    primaryText={
                        <Stack direction='row' gap={1}>
                            <Tooltip title='Edit'>
                                <IconButton
                                    size='small'
                                    onClick={handleUpdate}
                                    disabled={disableActions}
                                >
                                    <EditIcon sx={{ fontSize: 24 }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Delete'>
                                <IconButton
                                    size='small'
                                    onClick={handleDelete}
                                    disabled={disableActions}
                                >
                                    <DeleteIcon sx={{ fontSize: 24 }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Reset password'>
                                <IconButton
                                    size='small'
                                    onClick={handleResetPassword}
                                    disabled={disableActions}
                                >
                                    <LockResetIcon sx={{ fontSize: 24 }} />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    }
                />
            )}
        </Row>
    );
};
