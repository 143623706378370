import { FC, ReactNode } from 'react';
import { ElevatedPaper } from '../ElevatedPaper';
import { styled, Stack, Typography, Box, PaperProps } from '@mui/material';

type BigFeatureButtonProps = {
    title: string;
    description: string;
    icon: ReactNode;

    paperProps: PaperProps;
};

const Container = styled(ElevatedPaper)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    cursor: 'pointer',
}));

export const ElevatedLargeButton: FC<BigFeatureButtonProps> = ({
    title,
    description,
    icon,
    paperProps,
}) => {
    return (
        <Container {...paperProps}>
            <Stack direction='row' spacing={2} justifyContent='space-between'>
                <Stack direction='column' justifyContent='space-around' gap={0}>
                    <Typography
                        variant='h4'
                        sx={{
                            fontSize: {
                                xs: '1rem',
                                sm: '1.25rem',
                            },
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant='body2'
                        color='secondary'
                        sx={{
                            mt: 1.5,
                            xs: '0.75rem',
                            sm: '1rem',
                        }}
                    >
                        {description}
                    </Typography>
                </Stack>
                <Box display='flex' alignItems='center'>
                    {icon}
                </Box>
            </Stack>
        </Container>
    );
};
