import { PageTitle } from '@/ui-kit/components';
import { Box, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionsPage } from '../components/';
import { Outlet } from 'react-router-dom';

const DesktopAccountTransactionsPage: FC = () => {
    const { t } = useTranslation('statements');

    return (
        <Box
            sx={({ spacing }) => ({
                pb: spacing(5),
            })}
        >
            <Stack direction='row' justifyContent='space-between'>
                <Box>
                    <PageTitle>{t('Account transactions')}</PageTitle>
                </Box>
            </Stack>
            <TransactionsPage />
            <Outlet />
        </Box>
    );
};

export default DesktopAccountTransactionsPage;
