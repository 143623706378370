import { createSvgIcon } from '@mui/material/utils';

export const FriendIcon = createSvgIcon(
    <>
        <path d='M 4.92 5.547 C 4.92 3.036 6.985 1 9.532 1 C 12.08 1 14.145 3.036 14.145 5.547 C 14.145 8.059 12.08 10.095 9.532 10.095 C 6.985 10.095 4.92 8.059 4.92 5.547 Z' />
        <path d='M 0 17.068 C 0 14.556 2.065 12.52 4.612 12.52 L 5.032 12.52 C 5.259 12.52 5.484 12.556 5.7 12.625 L 6.764 12.968 C 8.453 13.512 10.266 13.545 11.97 13.067 C 11.385 14.101 11.07 15.274 11.07 16.479 C 11.07 18.091 11.633 19.646 12.654 20.886 C 8.961 21.117 5.248 20.938 1.586 20.348 C 0.671 20.201 0 19.422 0 18.509 L 0 17.068 Z' />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M 22.287 20.565 C 23.384 19.481 24 18.012 24 16.479 C 24 14.947 23.384 13.477 22.287 12.394 C 21.189 11.31 19.701 10.701 18.15 10.701 C 16.598 10.701 15.11 11.31 14.013 12.394 C 12.916 13.477 12.3 14.947 12.3 16.479 C 12.3 18.012 12.916 19.481 14.013 20.565 C 15.11 21.648 16.598 22.257 18.15 22.257 C 19.701 22.257 21.189 21.648 22.287 20.565 Z M 21.07 14.168 C 21.282 13.959 21.626 13.959 21.838 14.168 C 22.05 14.377 22.05 14.717 21.838 14.926 L 17.65 19.062 C 17.452 19.258 17.134 19.272 16.919 19.095 L 14.825 17.372 C 14.595 17.182 14.564 16.844 14.755 16.616 C 14.947 16.389 15.29 16.358 15.52 16.548 L 17.233 17.958 L 21.07 14.168 Z'
        />
    </>,
    'FriendIcon'
);
