import { Box, Chip, styled } from '@mui/material';
import React, { FC } from 'react';

const CategoryTag = styled(Chip)(({ theme }) => ({
    borderRadius: theme.spacing(0.5),
    margin: theme.spacing(0.5),
}));

const CategoryContainer = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1.5),
    marginLeft: `${theme.spacing(-0.5)} !important`,
    marginRight: `${theme.spacing(-0.5)} !important`,
}));

type MerchantContentCategoriesProps = {
    categories: string[];
};

export const MerchantContentCategories: FC<MerchantContentCategoriesProps> = ({ categories }) => {
    return (
        <CategoryContainer>
            {categories.map((category, index) => (
                <CategoryTag key={index} label={category} />
            ))}
        </CategoryContainer>
    );
};
