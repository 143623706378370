import React, { useCallback, useEffect, useRef } from 'react';
import { Button } from '@mui/material';
import UserRoleForm from './components/UserRoleForm';
import { RoleData } from '@/transport/usersApi/types';
import { useNavigate } from 'react-router-dom';
import { FormikProps } from 'formik';
import { useCreateUserRoleMutation } from '@/transport/usersApi';
import { useNotification } from '@/components/Notification';
import {
    ManageDialog,
    ManageDialogActions,
    ManageDialogContent,
    ManageDialogTitle,
} from '@/components/ManageDialog';

const CreateUserRoleDialog: React.FC = () => {
    const navigate = useNavigate();
    const formRef = useRef<FormikProps<RoleData>>(null);

    const [createUserRole, { isSuccess, isLoading, isError }] = useCreateUserRoleMutation();

    const handleSubmit = (data: RoleData) => {
        createUserRole(data);
    };

    const handleClose = useCallback(() => {
        navigate('/users/roles');
    }, [navigate]);

    const handleCreate = () => {
        formRef.current?.submitForm();
    };

    const { notify } = useNotification();

    useEffect(() => {
        if (isError) {
            notify({ message: 'Error while creating role', type: 'error' });
        }
    }, [isError, notify]);

    useEffect(() => {
        if (isSuccess) {
            notify({ message: 'Role created successfully', type: 'success' });
            handleClose();
        }
    }, [handleClose, isSuccess, notify]);

    return (
        <ManageDialog open={true} onClose={handleClose}>
            <ManageDialogTitle>Create new role</ManageDialogTitle>
            <ManageDialogContent>
                <UserRoleForm
                    initialValues={{
                        title: '',
                        code: '',
                        permissions: [],
                    }}
                    onSubmit={handleSubmit}
                    formRef={formRef}
                    disabled={isLoading}
                />
            </ManageDialogContent>
            <ManageDialogActions>
                <Button color='secondary' onClick={handleClose} disabled={isLoading}>
                    Cancel
                </Button>
                <Button
                    type='submit'
                    color='primary'
                    onClick={handleCreate}
                    disabled={isLoading || isSuccess}
                >
                    Create
                </Button>
            </ManageDialogActions>
        </ManageDialog>
    );
};

export { CreateUserRoleDialog };
