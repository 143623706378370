import React from 'react';
import { useTranslation } from '@/ui-kit/hooks';
import { Box, Stack, Typography, IconButton } from '@mui/material';
import { MerchantInfo } from '../components/MerchantInfo';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const MobileMerchantInfoPage = () => {
    const { t } = useTranslation('statements');
    const navigate = useNavigate();

    return (
        <>
            <Stack direction='row' justifyContent='space-between'>
                <Box>
                    <Typography variant='h1' fontWeight='bold' sx={{ mb: 1.25 }}>
                        <IconButton onClick={() => navigate('../')}>
                            <ArrowBackIcon />
                        </IconButton>
                        {t('Merchant info')}
                    </Typography>
                </Box>
            </Stack>
            <MerchantInfo />
        </>
    );
};

export default MobileMerchantInfoPage;
