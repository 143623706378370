import { createSvgIcon } from '@mui/material/utils';

export const GiftIcon = createSvgIcon(
    <>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M 4.723 4.104 C 4.723 1.837 6.56 0 8.826 0 C 10.096 0 11.23 0.576 11.983 1.482 C 12.736 0.576 13.871 0 15.14 0 C 17.406 0 19.244 1.837 19.244 4.104 C 19.244 5.056 18.919 5.933 18.375 6.629 L 19.559 6.629 C 21.303 6.629 22.716 8.043 22.716 9.786 L 22.716 11.364 C 22.716 11.887 22.292 12.311 21.769 12.311 L 13.309 12.311 C 13.1 12.311 12.93 12.142 12.93 11.933 L 12.93 7.563 C 12.573 7.334 12.254 7.051 11.983 6.726 C 11.713 7.051 11.393 7.334 11.036 7.563 L 11.036 11.933 C 11.036 12.142 10.867 12.311 10.657 12.311 L 2.197 12.311 C 1.674 12.311 1.25 11.887 1.25 11.364 L 1.25 9.786 C 1.25 8.043 2.664 6.629 4.407 6.629 L 5.592 6.629 C 5.047 5.933 4.723 5.056 4.723 4.104 Z M 11.036 4.104 C 11.036 2.884 10.047 1.894 8.826 1.894 C 7.606 1.894 6.617 2.884 6.617 4.104 C 6.617 5.324 7.606 6.314 8.826 6.314 C 10.047 6.314 11.036 5.324 11.036 4.104 Z M 12.93 4.104 C 12.93 5.324 13.92 6.314 15.14 6.314 C 16.36 6.314 17.35 5.324 17.35 4.104 C 17.35 2.884 16.36 1.894 15.14 1.894 C 13.92 1.894 12.93 2.884 12.93 4.104 Z'
        />
        <path d='M 11.036 14.395 C 11.036 14.186 10.867 14.016 10.657 14.016 L 3.964 14.016 C 3.452 14.016 3.014 14.385 2.927 14.89 C 2.647 16.526 2.647 18.198 2.927 19.835 L 3.211 21.487 C 3.401 22.6 4.305 23.453 5.427 23.578 L 6.772 23.729 C 8.061 23.873 9.355 23.963 10.65 24 C 10.862 24.006 11.036 23.835 11.036 23.623 L 11.036 14.395 Z' />
        <path d='M 13.317 24 C 13.105 24.006 12.93 23.835 12.93 23.623 L 12.93 14.395 C 12.93 14.186 13.1 14.016 13.309 14.016 L 20.003 14.016 C 20.515 14.016 20.952 14.385 21.039 14.89 C 21.319 16.526 21.319 18.198 21.039 19.835 L 20.756 21.487 C 20.565 22.6 19.661 23.453 18.539 23.578 L 17.194 23.729 C 15.905 23.873 14.611 23.963 13.317 24 Z' />
    </>,
    'GiftIcon'
);
