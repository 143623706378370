import { Box, styled } from '@mui/material';

export const OrderModalPaper = styled(Box)(({ theme }) => ({
    borderRadius: '8px',
    width: '100%',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(4),

    // [theme.breakpoints.down('sm')]: {
    //     // backgroundColor: theme.palette.secondary.main,
    //     padding: theme.spacing(2),
    // },
}));
