import React, { FC } from 'react';
import liberBlack from '@/ui-kit/theme/colors/liberBlack';
import { styled, Link as LinkBase } from '@mui/material';
import { Trans } from '@/ui-kit/hooks/useTranslation';
import { TypographyBody3 } from '@/ui-kit/components';

const Link = styled(LinkBase)(({ theme }) => ({
    color: liberBlack[300],
    fontWeight: theme.typography.fontWeightRegular,
    textDecoration: 'none',
}));

type OperationalCompanyProps = {
    link: string;
    name: string;
};

export const OperationalCompany: FC<OperationalCompanyProps> = ({ name, link }) => {
    return (
        <TypographyBody3
            sx={{
                color: liberBlack[200],
                pt: 2,
            }}
        >
            <Trans
                ns='ui-kit__merchant'
                i18nKey='merchant_content.operated_by'
                values={{
                    company: name,
                }}
                components={{
                    a: (
                        <Link
                            target='_blank'
                            href={link}
                            sx={(theme) => ({
                                textDecoration: 'underline',
                                fontWeight: theme.typography.fontWeightMedium,
                            })}
                        />
                    ),
                }}
            />
        </TypographyBody3>
    );
};
