import { createSvgIcon } from '@mui/material/utils';

export const AttachIcon = createSvgIcon(
    <path
        d='M2.25 12A6.752 6.752 0 019 5.25h8A4.748 4.748 0 0121.75 10 4.748 4.748 0 0117 14.75H8.855A2.752 2.752 0 016.105 12c0-1.52 1.235-2.75 2.75-2.75h7.86a.75.75 0 010 1.5h-7.86a1.251 1.251 0 000 2.5H17a3.25 3.25 0 000-6.5H9A5.251 5.251 0 003.75 12 5.251 5.251 0 009 17.25h7.715a.75.75 0 010 1.5H9A6.752 6.752 0 012.25 12zm0 0'
        stroke='none'
        fillRule='evenodd'
    />,
    'AttachIcon'
);
