import React, { useCallback, useEffect, useRef } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import UserForm from './components/UserForm';
import { UserData } from '@/transport/usersApi/types';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikProps } from 'formik';
import { useGetUsersQuery, useUpdateUserMutation } from '@/transport/usersApi';
import { useNotification } from '@/components/Notification';
import {
    ManageDialog,
    ManageDialogActions,
    ManageDialogContent,
    ManageDialogTitle,
} from '@/components/ManageDialog';

const UpdateUserDialog: React.FC = () => {
    const navigate = useNavigate();
    const { userId } = useParams();
    const formRef = useRef<FormikProps<UserData>>(null);

    const { data: usersData } = useGetUsersQuery();
    const [updateUser, { isSuccess, isLoading, isError }] = useUpdateUserMutation();

    const handleSubmit = (data: UserData) => {
        if (!userId) return;
        updateUser({ id: userId, ...data });
    };

    const handleClose = useCallback(() => {
        navigate('/users');
    }, [navigate]);

    const handleUpdate = () => {
        formRef.current?.submitForm();
    };

    const { notify } = useNotification();

    useEffect(() => {
        if (isError) {
            notify({ message: 'Error while updating user', type: 'error' });
        }
    }, [isError, notify]);

    useEffect(() => {
        if (isSuccess) {
            handleClose();
            notify({ message: 'User updated successfully', type: 'success' });
        }
    }, [handleClose, isSuccess, notify]);

    const currentUserData = usersData?.data.find((user) => user.id === userId);

    return (
        <ManageDialog open={true} onClose={handleClose}>
            <ManageDialogTitle>Update user</ManageDialogTitle>
            <ManageDialogContent>
                {currentUserData && (
                    <UserForm
                        initialValues={{
                            email: currentUserData.email,
                            firstName: currentUserData.firstName,
                            lastName: currentUserData.lastName,
                            isActive: currentUserData.isActive,
                            roles: currentUserData.roles,
                        }}
                        onSubmit={handleSubmit}
                        formRef={formRef}
                        disabled={isLoading}
                    />
                )}
                {!currentUserData && (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}
            </ManageDialogContent>
            <ManageDialogActions>
                <Button color='secondary' onClick={handleClose} disabled={isLoading}>
                    Cancel
                </Button>
                <Button type='submit' color='primary' onClick={handleUpdate} disabled={isLoading}>
                    Update
                </Button>
            </ManageDialogActions>
        </ManageDialog>
    );
};

export { UpdateUserDialog };
