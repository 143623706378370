import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    AccountTransactionsRequest,
    AccountTransactionsResponse,
    AccountTransactionsStatRequest,
    AccountTransactionsStatResponse,
    ExportedTransactionsRequest,
    ExportedTransactionsResponse,
    TransactionCategoriesRequest,
    TransactionCategoriesResponse,
} from './types';

const baseUrl = process.env.REACT_APP_STAT_BASE_URL;

const reducerPath = 'accountTransactionsApi' as const;

export const accountTransactionsApi = createApi({
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    reducerPath,
    baseQuery: fetchBaseQuery({ baseUrl }),
    endpoints: (build) => ({
        getAccountTransactions: build.query<
            AccountTransactionsResponse,
            AccountTransactionsRequest
        >({
            query: ({
                dateStart,
                dateEnd,
                categories,
                referenceIds,
                direction,
                referenceSubstring,
                limit,
                offset,
            }) => {
                const queryParams = new URLSearchParams({
                    ...(typeof limit === 'number' && { limit: limit.toString() }),
                    ...(typeof offset === 'number' && { offset: offset.toString() }),
                    ...(typeof dateStart === 'string' && { dateStart }),
                    ...(typeof dateEnd === 'string' && { dateEnd }),
                    ...(typeof direction === 'string' && { direction }),
                    ...(typeof referenceSubstring === 'string' && { referenceSubstring }),
                });
                categories?.forEach((category) => queryParams.append('categories[]', category));
                referenceIds?.forEach((referenceId) =>
                    queryParams.append('referenceIds[]', referenceId)
                );
                const queryParamsString = queryParams.toString();

                return {
                    url: '/api/account-transactions?' + queryParamsString,
                    method: 'GET',
                    credentials: 'include',
                };
            },
        }),
        getTransactionCategories: build.query<
            TransactionCategoriesResponse,
            TransactionCategoriesRequest
        >({
            query: () => {
                return {
                    url: '/api/account-transactions/categories',
                    method: 'GET',
                    credentials: 'include',
                };
            },
        }),
        getExportedTransactions: build.mutation<
            ExportedTransactionsResponse,
            ExportedTransactionsRequest
        >({
            query: () => {
                return {
                    url: '/api/account-transactions/xlsx',
                    method: 'GET',
                    credentials: 'include',
                    responseHandler: (response) => response.blob(),
                };
            },
        }),
        getAccountTransactionsStat: build.query<
            AccountTransactionsStatResponse,
            AccountTransactionsStatRequest
        >({
            query: ({ monthsCount, categories } = {}) => {
                const queryParams = new URLSearchParams({
                    ...(typeof monthsCount === 'number' && { monthsCount: monthsCount.toString() }),
                });
                categories?.forEach((category) => queryParams.append('categories[]', category));

                const queryParamsString = queryParams.toString();

                return {
                    url: '/api/account-transactions/stat?' + queryParamsString,
                    method: 'GET',
                    credentials: 'include',
                };
            },
        }),
    }),
});

export const {
    useLazyGetAccountTransactionsQuery,
    useGetTransactionCategoriesQuery,
    useGetExportedTransactionsMutation,
    useGetAccountTransactionsStatQuery,
} = accountTransactionsApi;
