import { useGetExportedStatementsByCouponsMutation } from '@/transport/getAdminDataApi';
import { useTranslation } from '@/ui-kit/hooks';
import { Box, Button, ButtonProps, CircularProgress, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';

type ExportStatementsButtonProps = {
    dateStart: string;
    dateEnd: string;
    coupons: string[];
} & ButtonProps;

export const ExportStatementsButton: React.FC<ExportStatementsButtonProps> = ({
    dateStart,
    dateEnd,
    coupons,
    ...props
}) => {
    const { t } = useTranslation('statements');

    const [getExportedStatements, { data, isLoading }] =
        useGetExportedStatementsByCouponsMutation();

    const handleButtonClick = () => {
        getExportedStatements({
            dateStart,
            dateEnd,
            coupons,
        });
    };

    useEffect(() => {
        if (data?.downloadUrl) {
            window.open(data.downloadUrl);
        }
    }, [data]);

    const disabled = coupons.length === 0;

    return (
        <Box sx={{ ...props.sx }}>
            <Tooltip title={disabled ? t('Select coupons first') : ''}>
                <Box component={'span'}>
                    <Button
                        variant='text'
                        size='small'
                        disabled={disabled || isLoading}
                        sx={{
                            '&.Mui-disabled': {
                                backgroundColor: 'transparent',
                            },
                        }}
                        onClick={handleButtonClick}
                        startIcon={
                            isLoading ? <CircularProgress size={14} color='inherit' /> : null
                        }
                    >
                        {t('Export to XLSX')}
                    </Button>
                </Box>
            </Tooltip>
        </Box>
    );
};
