import { Language, Nullable } from '@/ui-kit/types';
import { monthFormatter } from './monthFormatter';
import { monthStandaloneFormatter } from './monthStandaloneFormatter';
import { capitalizeFormatter } from './capitalizeFormatter';

export type Formatter = (value: unknown, language: Language) => Nullable<string>;

const ADDITIONAL_I18N_FORMATTERS: Map<string, Formatter> = new Map([
    ['month', monthFormatter],
    ['monthStandalone', monthStandaloneFormatter],
    ['capitalize', capitalizeFormatter],
]);

export const applyFormatters = (
    value: unknown,
    _formats: string,
    language: Language
): string | null => {
    const formats = _formats.split(',').map((format) => format.trim());
    const result = formats.reduce(
        (result, format) => {
            const formatter = ADDITIONAL_I18N_FORMATTERS.get(format);
            if (!formatter) {
                return result;
            }
            const formattedValue = formatter(result.value, language);
            if (formattedValue === null) {
                return result;
            }
            return {
                value: formattedValue,
                formatted: true,
            };
        },
        { value, formatted: false }
    );

    if (!result.formatted) {
        return null;
    }
    return String(result.value);
};
