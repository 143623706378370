import { FC, ReactNode } from 'react';
import { Container, Grid, Stack, styled } from '@mui/material';
import { ResponsiveLazyRoute } from '@/bo-kit/components';

export const RightColumnImage = styled('img')({
  height: 'auto',
  width: '50vw',
  maxWidth: '100%',
});

export const LeftColumnContainer = styled('div')(({ theme: { breakpoints } }) => ({
  maxWidth: breakpoints.values.sm / 2,
}));

export const NonAuthorizedContentWithImageLayout: FC<{
  content: ReactNode;
  image: ReactNode;
  title?: ReactNode;
  implementation?: 'css' | 'js';
}> = ({ content, image, title, implementation }) => (
  <ResponsiveLazyRoute
    suspense={false}
    implementation={implementation}
    title={title}
    desktop={
      <Container disableGutters fixed>
        <Grid container direction='row' spacing={3} alignItems='center'>
          <Grid item xs={6} container>
            <LeftColumnContainer>{content}</LeftColumnContainer>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'center' }}>
            {image}
          </Grid>
        </Grid>
      </Container>
    }
    mobile={
      <Stack spacing={3} width='100%'>
        <div style={{ textAlign: 'center' }}>{image}</div>
        <div>{content}</div>
      </Stack>
    }
  />
);
