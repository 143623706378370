import React, { useState } from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';
import liberWhite from '@/ui-kit/theme/colors/liberWhite';

type TokenizedAutocompleteProps = {
    label?: string;
    placeholder?: string;
    onChange: (tags: string[]) => void;
    value: string[];
    formatTagBeforeAdd?: (tag: string) => string;
};

export const TokenizedAutocomplete: React.FC<TokenizedAutocompleteProps> = ({
    onChange,
    value,
    label,
    placeholder,
    formatTagBeforeAdd,
}) => {
    const setTags = onChange;
    const tags = value;
    // const [tags, setTags] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>('');

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if ((event.key === 'Enter' || event.key === ' ') && inputValue.trim()) {
            event.preventDefault();
            addTags([inputValue.trim()]);
        }
    };

    const handlePaste = (event: React.ClipboardEvent) => {
        event.preventDefault();
        const pasteData = event.clipboardData.getData('text');
        const pastedTags = pasteData
            .split(/[\s,]+/)
            .map((tag) => tag.trim())
            .filter((tag) => tag && !tags.includes(tag));

        addTags(pastedTags);
    };

    const handleChange = (event: React.SyntheticEvent, value: string[]) => {
        setTags(value);
    };

    const addTags = (newTags: string[]) => {
        const newTagList = [...tags];

        newTags.forEach((tag) => {
            const formattedTag = formatTagBeforeAdd ? formatTagBeforeAdd(tag) : tag;
            const trimmedTag = formattedTag.trim();

            if (tag && trimmedTag && !tags.includes(tag)) {
                newTagList.push(trimmedTag);
            }
        });

        setTags(newTagList);
        setInputValue('');
    };

    return (
        <Autocomplete
            multiple
            freeSolo
            value={tags}
            onChange={handleChange}
            options={[]}
            inputValue={inputValue}
            onInputChange={(_, newInputValue) => setInputValue(newInputValue.trim())}
            renderTags={(value: string[], getTagProps) =>
                value.map((option: string, index: number) => (
                    <Chip label={option} {...getTagProps({ index })} key={index} />
                ))
            }
            limitTags={1}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant='outlined'
                    label={label}
                    placeholder={placeholder}
                    onKeyDown={handleKeyDown}
                    onPaste={handlePaste}
                    sx={(theme) => ({
                        [theme.breakpoints.up('sm')]: {
                            position: 'relative',
                            height: 56,
                            '& .MuiInputBase-root': {
                                position: 'absolute',
                                display: 'flex',
                                flexDirection: 'row',
                                bgcolor: liberWhite[400],
                            },
                        },
                    })}
                />
            )}
        />
    );
};
