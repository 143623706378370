import { TransfersType } from '@/transport/getAdminDataApi/types';
import { Box, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { OrderModalPaper } from './OrderModalPaper';

type TransfersTableType = {
    data: TransfersType[];
};

const cellTitles = [
    'id',
    'txtype',
    'amount',
    'balance',
    'currency',
    'acc_no',
    'acc_other',
    'tariff',
    'plan',
    'ref_id',
    'ctime',
    'description_src',
    'description_dst',
    'action_code_src',
    'action_code_dst',
];

export const TransfersTable = ({ data }: TransfersTableType) => {
    return (
        <OrderModalPaper sx={{ marginTop: '30px' }}>
            <Box sx={{ mb: 4 }}>
                <Typography variant='h2'>Order Transfers Details</Typography>
            </Box>

            <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            {cellTitles.map((title, index) => (
                                <TableCell
                                    key={index}
                                    align='center'
                                    sx={{ fontSize: 14, p: '12px' }}
                                >
                                    {title}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row: Record<string, string | number>) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {cellTitles.map((title, index) => (
                                    <TableCell
                                        key={index}
                                        align='center'
                                        sx={{ fontSize: 14, p: '12px' }}
                                    >
                                        {row[title]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </OrderModalPaper>
    );
};
