import { useLoginMutation } from '@/transport/authApi';
import { FormError } from '@/ui-kit/components';
import liberRed from '@/ui-kit/theme/colors/liberRed';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
    Avatar,
    Box,
    Button,
    Container,
    CssBaseline,
    TextField,
    ThemeProvider,
    Typography,
    createTheme,
} from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { transformError } from './utils';
import { Form, Formik } from 'formik';

const theme = createTheme();

export default function AuthPage() {
    const navigate = useNavigate();
    const [triggerLogin, { data: loginData, isLoading, error }] = useLoginMutation();

    useEffect(() => {
        if (loginData) {
            navigate('/');
        }
    }, [loginData, navigate]);

    const errorMessage = transformError(error);

    return (
        <ThemeProvider theme={theme}>
            <Container component='main' maxWidth='xs'>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: liberRed[200] }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component='h1' variant='h5'>
                        Sign in
                    </Typography>
                    <Formik
                        initialValues={{ email: '', password: '' }}
                        // validationSchema={Yup.object({
                        //     email: Yup.string().email('Invalid email address').required('Required'),
                        //     password: Yup.string().required('Required'),
                        // })}
                        onSubmit={async (values) => {
                            await triggerLogin(values);
                        }}
                    >
                        {({ handleChange, handleSubmit, values }) => (
                            <Form onSubmit={handleSubmit}>
                                <TextField
                                    margin='normal'
                                    required
                                    fullWidth
                                    id='email'
                                    label='Email Address'
                                    name='email'
                                    autoComplete='email'
                                    autoFocus
                                    value={values.email}
                                    onChange={handleChange}
                                />
                                <TextField
                                    margin='normal'
                                    required
                                    fullWidth
                                    name='password'
                                    label='Password'
                                    type='password'
                                    id='password'
                                    autoComplete='current-password'
                                    value={values.password}
                                    onChange={handleChange}
                                />
                                {errorMessage && <FormError text={errorMessage} />}
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    sx={{
                                        mt: 3,
                                        mb: 2,
                                        bgcolor: liberRed[400],
                                        '&:hover': { bgcolor: liberRed[300] },
                                    }}
                                    disabled={isLoading}
                                >
                                    Sign In
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
