import { FC } from 'react';
import { Paper, styled, Typography } from '@mui/material';
import { SuccessIcon as SuccessIconBase } from '../../icons';
import liberGreen from '../../theme/colors/liberGreen';

type CashbackBlockType2Props = {
    title: string;
    coverImage: string;
};

const Container = styled(Paper)(({ theme }) => ({
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,

    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
}));

const SuccessIcon = styled(SuccessIconBase)(({ theme }) => ({
    height: theme.spacing(2),
    width: theme.spacing(2),
    fill: liberGreen[400],
    marginRight: theme.spacing(1),
}));

export const CashbackBlockWide: FC<CashbackBlockType2Props> = ({ title, coverImage }) => {
    return (
        <Container
            elevation={4}
            sx={{
                backgroundImage: `url(${coverImage})`,
            }}
        >
            <SuccessIcon />
            <Typography
                variant='h4'
                sx={{
                    fontWeight: '600',
                }}
            >
                {title}
            </Typography>
        </Container>
    );
};
