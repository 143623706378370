import { IFaceLoader } from '@/bo-kit/components';
import { useGetProfileQuery } from '@/transport/authApi';
import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

export const CheckAuthComponent: FC<{
    children?: ReactNode;
}> = ({ children }) => {
    const { isError, isFetching, isUninitialized } = useGetProfileQuery();

    if (isFetching || isUninitialized) {
        return <IFaceLoader />;
    }
    if (isError) {
        return <Navigate to='/auth' replace />;
    }
    return <>{children}</>;
};

export default CheckAuthComponent;
