import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const MessageBlock: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate('/'), [navigate]);
  return (
    <>
      <Typography variant='h3' sx={{ mb: 3 }}>
        {t('Page not found :(')}
      </Typography>
      <Typography variant='body2' sx={{ mb: 4 }} color='textSecondary'>
        {t('This page is missing or you assembled the link incorrectly')}
      </Typography>
      <Button variant='contained' onClick={goBack} fullWidth>
        {t('Go back')}
      </Button>
    </>
  );
};
