import { styled } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { WithSideMenu } from './components/SideMenu';

export const getDesktopLayoutColumnGridGap = () => 'max(calc(100% / 24), 98px)';
export const getDesktopLayoutRowGridGap = () => 'max(calc(100% / 24), 56px)';

const ContainerInner = styled('div')(({ theme }) => ({
  minWidth: theme.breakpoints.values.lg,
  maxWidth: theme.breakpoints.values.xl,
  paddingTop: theme.spacing(5),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  height: '100%',
}));

export const DesktopLayout: FC<PropsWithChildren> = ({ children }) => (
  <WithSideMenu>
    <ContainerInner>{children}</ContainerInner>
  </WithSideMenu>
);
