import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DatePickerLocalizationProvider } from './DatePickerLocalizationProvider';
import { useTranslation } from '@/ui-kit/hooks';
import { format } from 'date-fns';

interface DateRangePickerProps {
    date: {
        dateStart: Date;
        dateEnd: Date;
    };
    onDateChange: (date: { dateStart: Date | null; dateEnd: Date | null }) => void;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({ date, onDateChange }) => {
    const { dateStart, dateEnd } = date;

    // inner states are needed for manual date input case
    // to pass upside only valid dates, the event of which is triggered by onAccept
    const [innerDateStart, setInnerDateStart] = useState<Date | null>(dateStart);
    const [innerDateEnd, setInnerDateEnd] = useState<Date | null>(dateEnd);

    const { t } = useTranslation('statements');

    return (
        <>
            <DatePickerLocalizationProvider>
                <DatePicker
                    value={innerDateStart}
                    label={t('Date from')}
                    onChange={setInnerDateStart}
                    onAccept={(value) => onDateChange({ ...date, dateStart: value })}
                    slotProps={{
                        textField: { variant: 'outlined' },
                    }}
                    maxDate={dateEnd}
                    format='yyyy/MM/dd'
                    dayOfWeekFormatter={(date) => format(date, 'EEEEEE')}
                />
                <DatePicker
                    value={innerDateEnd}
                    label={t('Date to')}
                    onChange={setInnerDateEnd}
                    onAccept={(value) => onDateChange({ ...date, dateEnd: value })}
                    slotProps={{
                        textField: { variant: 'outlined' },
                    }}
                    minDate={dateStart}
                    maxDate={new Date()}
                    format='yyyy/MM/dd'
                    dayOfWeekFormatter={(date) => format(date, 'EEEEEE')}
                />
            </DatePickerLocalizationProvider>
        </>
    );
};
