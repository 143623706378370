import { styled, AlertProps, Alert, alertClasses } from '@mui/material';
import React, { useCallback, forwardRef, FC } from 'react';
import { useSnackbar, SnackbarContent, CustomContentProps } from 'notistack';

export const AlertSuccess = styled((props: AlertProps) => (
    <Alert severity='success' icon={false} {...props} />
))(({ theme }) => ({
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderLeft: `${theme.spacing(1.5)} solid ${theme.palette.success.main}`,

    [`& .${alertClasses.message}`]: {
        color: theme.palette.success.main,
    },
}));

export const AlertSuccessNotistack: FC<CustomContentProps> = forwardRef<
    HTMLDivElement,
    CustomContentProps
>((props: CustomContentProps, ref) => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
        closeSnackbar(props.id);
    }, [props.id, closeSnackbar]);

    return (
        <SnackbarContent ref={ref}>
            <AlertSuccess onClose={handleDismiss} children={props.message} />
        </SnackbarContent>
    );
});

AlertSuccessNotistack.displayName = 'AlertErrorNotistack';
