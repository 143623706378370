import { createSvgIcon } from '@mui/material/utils';

export const FlagGBIcon = createSvgIcon(
    <>
        <rect x={2} y={5} width={20} height={14} rx={2} fill='#fff' />
        <mask
            id='a'
            style={{
                maskType: 'alpha',
            }}
            maskUnits='userSpaceOnUse'
            x={2}
            y={5}
            width={20}
            height={14}
        >
            <rect x={2} y={5} width={20} height={14} rx={2} fill='#fff' />
        </mask>
        <g mask='url(#a)'>
            <path fill='#0A17A7' d='M2 5H22V19H2z' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M1.084 3.658L9.619 9.3V4.067h4.762V9.3l8.535-5.642 1.065 1.548-6.748 4.46H22v4.667h-4.767l6.748 4.46-1.065 1.549-8.535-5.642v5.233H9.619V14.7l-8.535 5.642-1.065-1.548 6.748-4.46H2V9.666h4.767L.019 5.206l1.065-1.548z'
                fill='#fff'
            />
            <path
                d='M15.334 9.433L24.381 3.6M16.295 14.588l8.11 5.357M7.718 9.417L-.742 3.83M8.636 14.523l-9.377 6.094'
                stroke='#DB1F35'
                strokeWidth={0.666667}
                strokeLinecap='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2 13.4h8.571V19h2.858v-5.6H22v-2.8h-8.571V5H10.57v5.6H2v2.8z'
                fill='#E6273E'
            />
        </g>
    </>,
    'FlagGBIcon'
);
