import { TransferVolumeResponse } from '@/transport/getAdminDataApi/types';
import { formatNumber, getDateInYYYYMMDDFormat } from '@/utils';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function TableModule({ data }: TransferVolumeResponse) {
    return (
        <TableContainer component={Paper} sx={{ minWidth: 320, maxWidth: 768 }}>
            <Table aria-label='simple table'>
                <TableHead>
                    <TableRow>
                        <TableCell align='left'>Date</TableCell>
                        <TableCell align='right'>Partial Complete</TableCell>
                        <TableCell align='right'>Approved</TableCell>
                        <TableCell align='right'>Collected</TableCell>
                        <TableCell align='right'>Pending</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='left'>
                                {getDateInYYYYMMDDFormat(new Date(row.date))}
                            </TableCell>
                            <TableCell align='right'>
                                {formatNumber(row.totalAmountProcessed)}
                            </TableCell>
                            <TableCell align='right'>
                                {formatNumber(row.totalAmountApproved)}
                            </TableCell>
                            <TableCell align='right'>
                                {formatNumber(row.totalAmountCollected)}
                            </TableCell>
                            <TableCell align='right'>
                                {formatNumber(row.totalAmountApproved - row.totalAmountCollected)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
