import { useGetDataByOrderIdQuery } from '@/transport/getAdminDataApi';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

import { TransfersTable } from './components/TransfersTable';
import { OrderInfo } from './components/OrderInfo';
import { CloseIcon } from '@/ui-kit/icons';
import { useIsDesktop } from '@/ui-kit/hooks';
import { OpenBanking, WebhookEvents } from './components/BankingData';
import genericErrorImg from '@/assets/images/genericError.svg';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

export default function OrderDialog() {
    const navigate = useNavigate();
    const { orderId } = useParams();
    const { currentData, isError, isFetching } = useGetDataByOrderIdQuery(orderId as string, {
        skip: !orderId,
    });

    const [searchParams] = useSearchParams();

    const handleClose = () => {
        navigate({
            pathname: '/search-transactions',
            search: searchParams.toString(),
        });
    };

    const isDesktop = useIsDesktop();

    return (
        <Dialog
            open={!!orderId}
            onClose={handleClose}
            fullWidth
            maxWidth={'lg'}
            fullScreen={!isDesktop}
            PaperProps={{ sx: { padding: { xs: 2, sm: 4 }, pt: { xs: 8 }, height: '100%' } }}
        >
            {!isDesktop && (
                <IconButton
                    onClick={handleClose}
                    sx={({ palette, ...theme }) => ({
                        position: 'fixed',
                        right: 8,
                        top: 8,
                        background: palette.common.white,
                        boxShadow: theme.shadows[3],
                    })}
                >
                    <CloseIcon />
                </IconButton>
            )}
            {/* <Box> */}
            {isFetching && (
                <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    flexDirection='column'
                    height='100%'
                    gap={2}
                >
                    <CircularProgress size={48} />
                    <Typography>Loading...</Typography>
                </Box>
            )}

            {isError && (
                <Typography>
                    <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        flexDirection='column'
                        height='100%'
                        gap={2}
                    >
                        <Box component='img' src={genericErrorImg} />
                        <Typography>Error loading data</Typography>
                    </Box>
                </Typography>
            )}
            {!isFetching && currentData && (
                <>
                    <OrderInfo data={currentData} />
                    <OpenBanking openbankingData={currentData.openbanking?.data} />
                    <WebhookEvents webhookEventsData={currentData.webhookEvents} />
                    <TransfersTable data={currentData?.transfers} />
                </>
            )}
        </Dialog>
    );
}
