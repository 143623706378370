import { styled } from '@mui/material';
import { FC, ReactElement } from 'react';

const MerchantLogoImg = styled('img')(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
}));

const MerchantLogoContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1.5),
  left: theme.spacing(1.5),
  right: theme.spacing(1.5),
  height: theme.spacing(3),
  display: 'flex',
  justifyContent: 'center',
}));

export const VerticalCouponMerchantLogo: FC<{ image: ReactElement | string }> = ({ image }) => (
  <MerchantLogoContainer>
    {typeof image !== 'string' ? image : <MerchantLogoImg src={image} />}
  </MerchantLogoContainer>
);
