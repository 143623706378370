import React, { FC, useMemo } from 'react';
import {
    Box,
    Typography,
    Card,
    styled,
    CardContent as CardContentBase,
    Skeleton,
    BoxProps,
} from '@mui/material';
import { Asterisk, DefaultMerchantLogo } from '../';
import liberWhite from '../../theme/colors/liberWhite';
import { getCDNImageUrl } from '../../utils/getCDNImageUrl';
import { formatPercent } from '../../utils/formatPercent';
import { MerchantCardImage } from './MerchantCardImage';
import { LikeIcon as LikeIconBase } from '../../icons';
import { Nullable } from '../../types';

export const DEFAULT_IMAGE = getCDNImageUrl('/default_image.png');

const LikeIcon = styled(LikeIconBase)(({ theme }) => ({
    height: theme.spacing(2),
    width: theme.spacing(2),

    marginRight: theme.spacing(0.5),
}));

const CardContainer = styled(Card)(({ theme }) => ({
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    position: 'relative',
}));

const PercentTitle = styled(Typography)({
    lineHeight: 1,
});

const CardTitleContainer = styled(Box)({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
    height: '100%',
});

const MerchantName = styled(Typography)(({ theme }) => ({
    lineHeight: 1,
    overflow: 'hidden',
    fontWeight: theme.typography.fontWeightRegular,
}));

const MerchantVotes = styled(Typography)(() => ({
    lineHeight: 1,
    alignItems: 'center',
    display: 'flex',
}));

const CardContent = styled(CardContentBase)(({ theme: { spacing } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: spacing(2),
    alignItems: 'center',
    '&:last-child': {
        paddingBottom: spacing(2),
    },
}));

export const MerchantLogo = styled('img')(({ theme }) => ({
    flex: 0,
    display: 'block',
    borderRadius: theme.spacing(1),
    width: theme.spacing(5),
    height: theme.spacing(5),
    objectFit: 'cover',
    border: `1px solid ${liberWhite[200]}`,
}));

export type MerchantCardProps = {
    className?: string;
    voted?: boolean;
    voteCount?: number;
    type: 'voting' | 'active';
    color?: Nullable<string>;
    categories?: string[];
    image?: string;
    name: string;
    logo?: string;
    firstCashback?: number;
    cashback?: number;
    height?: string;
    width?: string;
    onClick?: () => void;
    sx?: BoxProps['sx'];
};

export const MerchantCard: FC<MerchantCardProps> = ({
    firstCashback = 0,
    cashback = 0,
    categories,
    image,
    name: merchantName,
    logo,
    height = '100%',
    width = '100%',
    onClick,
    color,
    voteCount,
    type,
    voted,
    sx,
    className,
}) => {
    const isFirstCashback = useMemo(() => !!firstCashback, [firstCashback]);
    return (
        <CardContainer
            className={className}
            sx={sx}
            elevation={4}
            onClick={onClick}
            style={{
                height,
                width,
            }}
        >
            <MerchantCardImage
                categories={categories}
                image={image}
                voteCount={type === 'voting' ? voteCount : null}
            />
            <CardContent>
                {type === 'voting' ? (
                    <CardTitleContainer>
                        <MerchantName variant='h4' color='textSecondary' noWrap>
                            {merchantName}
                        </MerchantName>

                        <MerchantVotes variant='h5'>
                            <LikeIcon
                                sx={(theme) => ({
                                    color: theme.palette.primary.main,
                                })}
                            />
                            {voteCount}
                        </MerchantVotes>
                    </CardTitleContainer>
                ) : (
                    <CardTitleContainer>
                        <PercentTitle variant='h3'>
                            {formatPercent(firstCashback || cashback)}
                            {isFirstCashback && firstCashback !== cashback && <Asterisk />}
                        </PercentTitle>
                        <MerchantName variant='h4' color='textSecondary' noWrap>
                            {merchantName}
                        </MerchantName>
                    </CardTitleContainer>
                )}

                {logo ? (
                    <MerchantLogo src={logo} />
                ) : (
                    <DefaultMerchantLogo name={merchantName} color={color} />
                )}
            </CardContent>
        </CardContainer>
    );
};

export const MerchantCardSkeleton: FC = () => (
    <Skeleton
        variant='rectangular'
        width='100%'
        height='100%'
        animation='wave'
        sx={({ spacing }) => ({ borderRadius: spacing(2) })}
    ></Skeleton>
);
