import { DesktopDummyLayout, IFaceLoader, MobileLayout } from '@/bo-kit/components';
import { useIsDesktop } from '@/ui-kit/hooks/useIsDesktop';
import { FC, ReactNode, Suspense, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { StatDesktopAuthorizedLayout } from './StatAuthorizedLayout/DesktopLayout';

export const AuthorizedLayout: FC<{ useDummyLayoutForDesktop?: boolean; children?: ReactNode }> = ({
    useDummyLayoutForDesktop,
    children,
}) => {
    const isDesktop = useIsDesktop();
    const Layout = useMemo(
        () =>
            isDesktop
                ? useDummyLayoutForDesktop
                    ? DesktopDummyLayout
                    : StatDesktopAuthorizedLayout
                : MobileLayout,
        [isDesktop, useDummyLayoutForDesktop]
    );
    return (
        <Suspense fallback={<IFaceLoader />}>
            <Layout>
                <Outlet />
                {children}
            </Layout>
        </Suspense>
    );
};

// ADHOC: support lazy import
export default AuthorizedLayout;
