import { useCallback, useMemo } from 'react';
import { useGetProfileQuery } from '@/transport/authApi';
import { Permission } from '@/transport/authApi/types';

const hasPermission = (allowingPermission: Permission, permissions: Permission[]) =>
    permissions.includes(allowingPermission);

const routePermissionRules: { route: string; permission: Permission }[] = [
    { route: '/search-transactions', permission: Permission.StatRead },
    { route: '/show-transfer-volume', permission: Permission.StatRead },
    { route: '/merchants', permission: Permission.MerchantsRead },
    { route: '/users', permission: Permission.UsersRead },
    { route: '/users/create', permission: Permission.UsersEdit },
    { route: '/users/update', permission: Permission.UsersEdit },
    { route: '/users/delete', permission: Permission.UsersEdit },
    { route: '/users/reset', permission: Permission.UsersEdit },
    { route: '/users/roles', permission: Permission.RolesRead },
    { route: '/users/roles/create', permission: Permission.RolesEdit },
    { route: '/users/roles/update', permission: Permission.RolesEdit },
    { route: '/users/roles/delete', permission: Permission.RolesEdit },
    { route: '/account-transactions', permission: Permission.AccountTransactionsRead },
];

const hasPermissionToRoute = (fullRoute: string, permissions: Permission[] = []) => {
    let appropriateRule: null | {
        route: string;
        permission: Permission;
    } = null;

    // finds the longest routes match, e.g. if user is on /users/delete page,
    // it will return rule for /users/delete, not for /users
    // TODO: split path to [users, delete] and compare each element consistently,
    // the appropriate rule will be with the largest number of matching parts
    for (const rule of routePermissionRules) {
        if (!fullRoute.includes(rule.route)) continue;
        if (!appropriateRule || appropriateRule.route.length < rule.route.length) {
            appropriateRule = rule;
        }
    }

    if (appropriateRule) {
        return permissions.includes(appropriateRule.permission);
    }

    return true;
};

export const usePermissionGuard = () => {
    const { isError, isFetching, isUninitialized, data: profileData } = useGetProfileQuery();
    const permissions = useMemo(() => profileData?.permissions || [], [profileData?.permissions]);

    const checkPermission = useCallback(
        (allowingPermission: Permission) => hasPermission(allowingPermission, permissions),
        [permissions]
    );

    const checkRoutePermission = useCallback(
        (route: string) => hasPermissionToRoute(route, permissions),
        [permissions]
    );

    return {
        checkPermission,
        checkRoutePermission,
        permissions: permissions,
        isError,
        isLoading: isFetching || isUninitialized,
    };
};
