import { ElevatedPaper } from '@/bo-kit/components/ElevatedPaper';
import { MerchantsStatementType } from '@/transport/getAdminDataApi/types';
import { useTranslation } from '@/ui-kit/hooks';
import { Box } from '@mui/material';
import { CellHeader, RowHeader } from './TableElements';
import { TransactionRowSkeleton } from './TransactionRowSkeleton';
import { formatSummary } from '../utils/formatSummary';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGetMerchantsStatements } from '../hooks/useGetMerchantsStatements';
import { TransactionRow } from './TransactionRow';
import { useTransactionFilters } from '../hooks/useTransactionFilters';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CopyToClipboardButton } from '../../OrderDialog/components/OrderInfo/components/CopyToClipboardButton';

const columns = [
    'Transaction date',
    'Amount',
    'Direct payment',
    'Fee',
    'Cashback',
    'Result amount',
    'Institution',
    'Order ID',
    'Coupon',
    'Status',
];

export const TransactionTable = () => {
    const { t } = useTranslation('statements');
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const handleClickOrder = (id: string) => {
        navigate({
            pathname: `/search-transactions/${id}`,
            search: searchParams.toString(),
        });
    };

    const {
        dateStart,
        dateEnd,
        selectedCoupons,
        orderIds,
        institutionIds,
        countryCodes,
        statementStatuses,
        showTestTransfers,
    } = useTransactionFilters();

    const {
        data: merchantsStatementsData,
        isError,
        isLoading,
        loadNextPage,
        hasNextPage,
    } = useGetMerchantsStatements({
        dateStart: dateStart,
        dateEnd: dateEnd,
        coupons: selectedCoupons,
        orderIds: orderIds,
        institutionIds: institutionIds,
        institutionCountryCodes: countryCodes,
        statuses: statementStatuses,
        isTestTransfers: showTestTransfers,
    });

    // const handleClickSummary = () => {
    //     if (!merchantsStatementsData) return;
    //     navigator.clipboard.writeText(formatSummary(orderIds, merchantsStatementsData));
    // };

    return (
        <Box sx={{ overflow: 'auto' }}>
            <Box
                sx={{
                    minWidth: '1000px',
                    pb: 2,
                }}
            >
                <ElevatedPaper
                    sx={({ spacing }) => ({
                        mt: spacing(4),
                        py: spacing(3),
                        px: spacing(5),
                        width: 'fit-content',
                        minWidth: '100%',
                        margin: '0 auto',
                        mb: spacing(1),
                    })}
                >
                    <RowHeader
                        sx={({ spacing }) => ({
                            mt: spacing(1),
                            px: spacing(5),
                            py: spacing(2.5),
                        })}
                    >
                        {columns.map((columnName) => (
                            <CellHeader key={columnName}>
                                {t(columnName)}
                                {columnName === 'Status' && (
                                    // TODO: Remove CopySummary component, move CopyToClipboardButton in shared
                                    <CopyToClipboardButton
                                        data={formatSummary(orderIds, merchantsStatementsData)}
                                        notificationText='Summary of current orders status copied to clipboard!'
                                        sx={{ ml: 1 }}
                                    />
                                    // <CopySummary onClick={handleClickSummary} />
                                )}
                            </CellHeader>
                        ))}
                    </RowHeader>
                </ElevatedPaper>
                <ElevatedPaper
                    sx={({ spacing }) => ({
                        mt: spacing(4),
                        py: spacing(3),
                        px: spacing(5),
                        width: 'fit-content',
                        minWidth: '100%',
                        margin: '0 auto',
                    })}
                >
                    {isLoading &&
                        Array.from({ length: 8 }, (_, index) => index).map((_, index) => (
                            <TransactionRowSkeleton key={index} />
                        ))}

                    {!isError && !isLoading && (
                        <InfiniteScroll
                            dataLength={merchantsStatementsData.length}
                            next={loadNextPage}
                            hasMore={hasNextPage}
                            loader={<TransactionRowSkeleton />}
                        >
                            {merchantsStatementsData?.map((item: MerchantsStatementType, index) => (
                                <TransactionRow
                                    key={item.id + item.datetime + index}
                                    onClick={() => {
                                        handleClickOrder(item.id);
                                    }}
                                    sx={{ cursor: 'pointer' }}
                                    date={item.datetime}
                                    merchantOrderId={item.merchantOrderId}
                                    currency={item.currency}
                                    coupon={item.coupon}
                                    isDirectPayment={item.isDirectPayment}
                                    amount={item.amount}
                                    fee={item.fee}
                                    cashback={item.cashback}
                                    resultAmount={item.resultAmount}
                                    status={item.status}
                                    countryCode={item.institutionCountryCode}
                                    institutionId={item.institutionId}
                                />
                            ))}
                        </InfiniteScroll>
                    )}
                </ElevatedPaper>
            </Box>
        </Box>
    );
};
