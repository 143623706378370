import { Box, Divider, Drawer, DrawerProps, IconButton, List, ListItem } from '@mui/material';
import { FC, Suspense, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon, LogoutIcon, SettingsIcon } from '@/ui-kit/icons';
import { MenuIcon } from './components/MenuIcon';
import { MobileLayoutMenuButton } from './components/MenuButton';
import {
  SideMenuItem,
  SideMenuItemType,
  useSideMenuItems,
} from '@/bo-kit/components/AuthorizedLayout/hooks/useSideMenuItems';
import { LanguageSwitcher } from '@/bo-kit/components';

const MainItemsAndLanguage: FC<{
  items: SideMenuItem[];
  hideItems: SideMenuItemType[];
  onMenuItemClickClosePopup: () => void;
}> = ({ items, hideItems, onMenuItemClickClosePopup }) => {
  const { t } = useTranslation('bo-kit__common');
  const mainItemsExist = items.length > 0;
  const showLanguageItem = !hideItems.includes(SideMenuItemType.LANGUAGE);
  const showDivider = mainItemsExist && showLanguageItem;
  return (
    <>
      {mainItemsExist &&
        items.map(({ type, icon, href }) => (
          <ListItem key={href} disablePadding>
            <MobileLayoutMenuButton
              icon={<MenuIcon component={icon} />}
              href={href}
              onClick={onMenuItemClickClosePopup}
              strict={type === SideMenuItemType.MAIN}
            >
              {t(type)}
            </MobileLayoutMenuButton>
          </ListItem>
        ))}
      {showDivider && <Divider sx={{ mt: 3, mb: 3 }} />}
      {showLanguageItem && (
        <ListItem disablePadding>
          <Suspense>
            <LanguageSwitcher
              variant='full'
              dropdownAlign='left'
              sx={({ spacing, palette }) => ({
                pl: spacing(2.5),
                color: palette.text.secondary,
              })}
              onClick={onMenuItemClickClosePopup}
            />
          </Suspense>
        </ListItem>
      )}
    </>
  );
};

export const MobileLayoutSideMenu: FC<Pick<DrawerProps, 'onClose' | 'open'>> = ({
  onClose,
  open,
}) => {
  const { t } = useTranslation('bo-kit__common');
  const onMenuItemClickClosePopup = useCallback(() => {
    onClose?.({}, 'escapeKeyDown');
  }, [onClose]);
  const { items, hideItems } = useSideMenuItems();

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor='right'
      elevation={10}
      sx={{}}
      PaperProps={{
        sx: ({ palette }) => ({
          pl: 2,
          pt: 2,
          pb: 2,
          minWidth: 'min(100vw, 272px)',
          backgroundColor: palette.background.default,
        }),
      }}
    >
      <Box sx={{ textAlign: 'right', pr: 2 }}>
        <IconButton onClick={onMenuItemClickClosePopup} color='secondary'>
          <CloseIcon />
        </IconButton>
      </Box>
      <List>
        <MainItemsAndLanguage
          hideItems={hideItems}
          items={items}
          onMenuItemClickClosePopup={onMenuItemClickClosePopup}
        />
        {!hideItems.includes(SideMenuItemType.SETTINGS) && (
          <ListItem disablePadding>
            <MobileLayoutMenuButton
              icon={<MenuIcon component={SettingsIcon} />}
              href='/settings'
              onClick={onMenuItemClickClosePopup}
            >
              {t('Settings')}
            </MobileLayoutMenuButton>
          </ListItem>
        )}
        {!hideItems.includes(SideMenuItemType.LOGOUT) && (
          <ListItem disablePadding>
            <MobileLayoutMenuButton
              icon={<MenuIcon component={LogoutIcon} />}
              href='/logout'
              onClick={onMenuItemClickClosePopup}
            >
              {t('Log out')}
            </MobileLayoutMenuButton>
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};
