import liberBlack from '../colors/liberBlack';
import { ThemeType } from '..';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: ThemeType) => ({
    pageTitle: {
        color: liberBlack[300],
        '&.MuiTypography-root': {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 2 * theme.typography.fontSize,
            [theme.breakpoints.down('sm')]: {
                fontSize: 1.5 * theme.typography.fontSize,
            },
        },
    },
    commonText: {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightRegular,
        [theme.breakpoints.up('xs')]: {
            marginRight: theme.spacing(2),
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: 0,
        },
    },
    btnControl: {
        height: theme.spacing(6),
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: 0.875 * theme.typography.fontSize,
        borderRadius: theme.spacing(1.25),
        textTransform: 'inherit',
    },
    LinkText: {
        '& span': {
            fontSize: 0.875 * theme.typography.fontSize,
            color: theme.palette.text.secondary,
        },
        '& a': {
            color: liberBlack[200],
            fontWeight: theme.typography.fontWeightMedium,
            marginLeft: theme.spacing(0.625),
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    perferencesHeaderTitle: {
        marginRight: theme.spacing(12),
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        },
    },
    couponActive: {
        background: 'transparent',
    },
    couponInactive: {
        filter: 'brightness(75%)',
    },
    listItemTextFont: {
        fontSize: 0.8 * theme.typography.fontSize,
        color: liberBlack[100],
    },
}));
