import { LiberSaveFrontend, UserType } from '../../bo-kit/types';

const MERCHANT_USER_TYPES = [UserType.MerchantIssuer, UserType.Merchant];
const CUSTOMER_USER_TYPES = [UserType.CustomerLegal, UserType.Customer];
const ALL_USER_TYPES = [...MERCHANT_USER_TYPES, ...CUSTOMER_USER_TYPES];

export const getUserTypeByFrontend = (frontend: LiberSaveFrontend): Array<UserType> => {
    switch (frontend) {
        case LiberSaveFrontend.CC:
            return CUSTOMER_USER_TYPES;
        case LiberSaveFrontend.MC:
            return MERCHANT_USER_TYPES;
        default:
            return ALL_USER_TYPES;
    }
};
export const getCurrentFrontend = (): LiberSaveFrontend =>
    (process.env.REACT_APP_LS_FRONTEND_TYPE as LiberSaveFrontend) ?? LiberSaveFrontend.AUTH;
export const isAuthFrontend = (): boolean => getCurrentFrontend() === LiberSaveFrontend.AUTH;
