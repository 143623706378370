import React, { FC } from 'react';

import {
    Box,
    CircularProgress as CircularProgressBase,
    CircularProgressProps,
    styled,
    circularProgressClasses,
} from '@mui/material';

export const CircularProgressBackground = styled(CircularProgressBase)(({ theme }) => ({
    color: theme.palette.secondary.main,
}));

export const CircularProgressMeter = styled(CircularProgressBase)(({ theme }) => ({
    color: theme.palette.common.white,
    position: 'absolute',
    left: 0,

    [`& .${circularProgressClasses.circle}`]: {
        strokeLinecap: 'round',
    },
}));

export const CircularProgress: FC<CircularProgressProps> = (props) => {
    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
            }}
        >
            <CircularProgressBackground variant='determinate' size={80} {...props} value={100} />
            <CircularProgressMeter variant='determinate' size={80} value={33} {...props} />
        </Box>
    );
};
