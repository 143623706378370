import { createSvgIcon } from '@mui/material/utils';

export const ChevronDownIcon = createSvgIcon(
    <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.32372 8.31952C5.75536 7.89349 6.45517 7.89349 6.8868 8.31952L12 13.3663L17.1132 8.31952C17.5448 7.89349 18.2446 7.89349 18.6763 8.31952C19.1079 8.74555 19.1079 9.43627 18.6763 9.8623L12.7815 15.6805C12.3499 16.1065 11.6501 16.1065 11.2185 15.6805L5.32372 9.8623C4.89209 9.43627 4.89209 8.74555 5.32372 8.31952Z'
    />,
    'ChevronDown'
);
