import { Slide } from '@mui/material';
import { ComponentProps, forwardRef, Ref } from 'react';
import { DialogShowAbstractTransition } from './DialogShowAbstractTransition';

export const DialogShowUpTransition = forwardRef(function Transition(
    props: Omit<ComponentProps<typeof DialogShowAbstractTransition>, 'direction'>,
    ref: Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} />;
});
