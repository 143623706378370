import { Container } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { Header } from './components/Header';
import { mobileLayoutContentPadding } from '@/ui-kit/constants';

export const MobileLayout: FC<PropsWithChildren> = ({ children }) => (
  <>
    <Header />
    <Container
      maxWidth={false}
      sx={({ spacing }) => ({
        padding: spacing(mobileLayoutContentPadding),
        pt: spacing(mobileLayoutContentPadding),
        height: '100%',
      })}
    >
      {children}
    </Container>
  </>
);
