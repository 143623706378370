import React, { useCallback, useEffect, useRef } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import UserRoleForm from './components/UserRoleForm';
import { RoleData } from '@/transport/usersApi/types';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikProps } from 'formik';
import { useGetUserRolesQuery, useUpdateUserRoleMutation } from '@/transport/usersApi';
import { useNotification } from '@/components/Notification';
import {
    ManageDialog,
    ManageDialogActions,
    ManageDialogContent,
    ManageDialogTitle,
} from '@/components/ManageDialog';

const UpdateUserRoleDialog: React.FC = () => {
    const navigate = useNavigate();
    const { roleId } = useParams();
    const formRef = useRef<FormikProps<RoleData>>(null);

    const { data: rolesData } = useGetUserRolesQuery();
    const [updateUser, { isSuccess, isLoading, isError }] = useUpdateUserRoleMutation();

    const handleSubmit = (data: RoleData) => {
        if (!roleId) return;
        updateUser({ id: roleId, ...data });
    };

    const handleClose = useCallback(() => {
        navigate('/users/roles');
    }, [navigate]);

    const handleUpdate = () => {
        formRef.current?.submitForm();
    };

    const { notify } = useNotification();

    useEffect(() => {
        if (isError) {
            notify({ message: 'Error while updating role', type: 'error' });
        }
    }, [isError, notify]);

    useEffect(() => {
        if (isSuccess) {
            handleClose();
            notify({ message: 'Role updated successfully', type: 'success' });
        }
    }, [handleClose, isSuccess, notify]);

    const currentRoleData = rolesData?.data.find((role) => role.id === roleId);

    return (
        <ManageDialog open={true} onClose={handleClose}>
            <ManageDialogTitle>Update role</ManageDialogTitle>
            <ManageDialogContent>
                {currentRoleData && (
                    <UserRoleForm
                        initialValues={{
                            title: currentRoleData.title,
                            code: currentRoleData.code,
                            permissions: currentRoleData.permissions,
                        }}
                        onSubmit={handleSubmit}
                        formRef={formRef}
                        disabled={isLoading}
                    />
                )}
                {!currentRoleData && (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}
            </ManageDialogContent>
            <ManageDialogActions>
                <Button color='secondary' onClick={handleClose} disabled={isLoading}>
                    Cancel
                </Button>
                <Button type='submit' color='primary' onClick={handleUpdate} disabled={isLoading}>
                    Update
                </Button>
            </ManageDialogActions>
        </ManageDialog>
    );
};

export { UpdateUserRoleDialog };
