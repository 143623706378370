import { useCallback, useLayoutEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { subMonths } from 'date-fns';
import { getDateInYYYYMMDDCurrentTZFormat } from '@/ui-kit/utils/getDateInYYYYMMDDCurrentTZFormat';
import { TransactionDirection } from '@/transport/accountTransactionsApi/types';

const today = new Date();
const monthAgo = subMonths(new Date(), 1);
const todayStringified = getDateInYYYYMMDDCurrentTZFormat(today);
const monthAgoStringified = getDateInYYYYMMDDCurrentTZFormat(monthAgo);

export const useAccountTransactionsFilters = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    useLayoutEffect(() => {
        if (!searchParams.get('dateStart')) {
            searchParams.set('dateStart', monthAgoStringified);
        }
        if (!searchParams.get('dateEnd')) {
            searchParams.set('dateEnd', todayStringified);
        }
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);

    const getSearchParamArray = useCallback(
        (key: string): string[] => {
            return searchParams.getAll(key) || [];
        },
        [searchParams]
    );

    const setSearchParamArray = useCallback(
        (key: string, value: string[]): void => {
            searchParams.delete(key);
            value.forEach((item) => searchParams.append(key, item));
            setSearchParams(searchParams);
        },
        [searchParams, setSearchParams]
    );

    const setSearchParam = useCallback(
        (key: string, value: string | undefined): void => {
            if (typeof value === 'undefined') {
                searchParams.delete(key);
            } else {
                searchParams.set(key, value);
            }
            setSearchParams(searchParams);
        },
        [searchParams, setSearchParams]
    );

    const dateStart = useMemo(
        () => searchParams.get('dateStart') || monthAgoStringified,
        [searchParams]
    );
    const dateEnd = useMemo(() => searchParams.get('dateEnd') || todayStringified, [searchParams]);
    const categories = useMemo(() => getSearchParamArray('categories[]'), [getSearchParamArray]);
    const referenceIds = useMemo(
        () => getSearchParamArray('referenceIds[]'),
        [getSearchParamArray]
    );
    const direction = useMemo(
        () => (searchParams.get('direction') as TransactionDirection) || undefined,
        [searchParams]
    );
    const referenceSubstring = useMemo(
        () => searchParams.get('referenceSubstring') || undefined,
        [searchParams]
    );

    return {
        dateStart,
        dateEnd,
        categories,
        referenceIds,
        direction,
        referenceSubstring,

        setDateStart: useCallback(
            (date: Date) => setSearchParam('dateStart', getDateInYYYYMMDDCurrentTZFormat(date)),
            [setSearchParam]
        ),
        setDateEnd: useCallback(
            (date: Date) => setSearchParam('dateEnd', getDateInYYYYMMDDCurrentTZFormat(date)),
            [setSearchParam]
        ),
        setCategories: useCallback(
            (coupons: string[]) => setSearchParamArray('categories[]', coupons),
            [setSearchParamArray]
        ),
        setReferenceIds: useCallback(
            (referenceIds: string[]) => setSearchParamArray('referenceIds[]', referenceIds),
            [setSearchParamArray]
        ),
        setDirection: useCallback(
            (direction: TransactionDirection | undefined) => setSearchParam('direction', direction),
            [setSearchParam]
        ),
        setReferenceSubstring: useCallback(
            (substring: string) => setSearchParam('referenceSubstring', substring),
            [setSearchParam]
        ),
    };
};
