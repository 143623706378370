import { compose } from './fp';
import { getACUrl, getCCUrl, getMCUrl } from './getExternalLoginUrl';
/**
 * Verify url to be the valid CC or MC url and return null for some unwanted external URL, like 'https://fake.com'.
 * Current function "unfold" returnUrl:
 * 1. {MC}/some-context -> https://libersave.com/mc/some-context
 * 2. {CC}/some-context -> https://libersave.com/cc/some-context
 * @param url
 */

const returnURLWhitelist = [getCCUrl(), getMCUrl(), getACUrl()] as const;

export const sanitizeReturnUrl = compose(
    (returnUrl: string) => returnUrl.replace('{CC}', getCCUrl()),
    (returnUrl) => returnUrl.replace('{MC}', getMCUrl()),
    (returnUrl) =>
        returnURLWhitelist.some((whitelistedUrl) => returnUrl.startsWith(whitelistedUrl))
            ? returnUrl
            : null
);

export const sanitizeReturnUrlAndRedirect = compose(
    (returnUrl: string, extraParams?: Record<string, unknown>) => ({
        returnUrl: sanitizeReturnUrl(returnUrl),
        extraParams,
    }),
    ({ returnUrl, extraParams }) => {
        if (!returnUrl) {
            return;
        }
        if (!extraParams) {
            document.location.replace(returnUrl);
            return;
        }
        try {
            const returnUrlObject = new URL(returnUrl);
            Object.entries(extraParams).forEach(
                ([key, value]) =>
                    value !== undefined && returnUrlObject.searchParams.set(key, String(value))
            );
            document.location.replace(returnUrlObject.toString());
        } catch (e) {
            console.error('Cannot handle returnUrl', { returnUrl });
            return;
        }
    }
);
