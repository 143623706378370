import { FC, ReactElement } from 'react';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import { Breakpoint, Box } from '@mui/material';

export const ResponsivePage: FC<{
    desktop: ReactElement;
    mobile: ReactElement;
    breakpoint?: Breakpoint;
    implementation?: 'css' | 'js';
}> = ({ desktop, mobile, implementation = 'css', breakpoint = 'sm' }) => {
    const isDesktop = useIsDesktop();
    if (implementation === 'css') {
        return (
            <>
                <Box
                    sx={{
                        display: { xs: 'none', [breakpoint]: 'block' },
                    }}
                    children={desktop}
                />
                <Box
                    sx={{
                        display: { xs: 'block', [breakpoint]: 'none' },
                    }}
                    children={mobile}
                />
            </>
        );
    }
    return isDesktop ? desktop : mobile;
};
