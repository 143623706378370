import { createSvgIcon } from '@mui/material/utils';

export const TopUpQRIcon = createSvgIcon(
    <>
        <path
            d='M4.8 3C3.806 3 3 3.805 3 4.8v2.696c0 .996.805 1.8 1.8 1.8h2.696c.996 0 1.8-.804 1.8-1.8V4.801c0-.996-.804-1.801-1.8-1.801zm0 1.8h2.696v2.696H4.801zm0 0M16.492 3c-.992 0-1.797.805-1.797 1.8v2.696c0 .996.805 1.8 1.797 1.8h2.7c.992 0 1.8-.804 1.8-1.8V4.801c0-.996-.808-1.801-1.8-1.801zm0 1.8h2.7v2.696h-2.7zm0 0M3 16.492c0-.992.805-1.797 1.8-1.797h2.696c.996 0 1.8.805 1.8 1.797v2.7c0 .992-.804 1.8-1.8 1.8H4.801c-.996 0-1.801-.808-1.801-1.8zm4.496 0H4.801v2.7h2.695zm0 0'
            fillRule='evenodd'
        />
        <path d='M11.996 3a.899.899 0 00-.898.898c0 .497.402.903.898.903h.008a.902.902 0 00.898-.903.899.899 0 00-.898-.898zm0 0M12.895 7.496a.899.899 0 00-1.797 0v2.7a.905.905 0 01-.903.902H7.496a.899.899 0 000 1.797h2.7c.718 0 1.402-.286 1.91-.79.503-.507.789-1.19.789-1.91zm0 0M3.898 11.098a.899.899 0 000 1.797h.012a.899.899 0 000-1.797zm0 0M15.594 11.098a.899.899 0 000 1.797h.898a.902.902 0 00.903-.899.902.902 0 00-.903-.898zm0 0M20.992 11.996a.9.9 0 00-1.8 0v.008a.9.9 0 001.8 0zm0 0M12.895 15.594a.899.899 0 00-1.797 0v.008c0 .496.402.902.898.902a.902.902 0 00.899-.902zm0 0M17.395 14.695a2.692 2.692 0 00-2.7 2.7v2.695a.9.9 0 101.797 0v-2.695c0-.243.098-.47.266-.637a.899.899 0 01.637-.266h2.695a.9.9 0 100-1.797zm0 0M12.895 19.191a.899.899 0 00-1.797 0v.899a.9.9 0 101.797 0zm0 0M20.992 20.09a.9.9 0 00-1.8 0v.012a.9.9 0 001.8 0zm0 0' />
    </>,
    'TopUpQRIcon'
);
