import { Box, Typography } from '@mui/material';
import { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const IFaceLoader: FC<{ title?: ReactNode }> = ({ title: _title }) => {
  const { t } = useTranslation('bo-kit__common');
  const title = useMemo(() => _title || t('Loading...'), [_title, t]);
  return (
    <Box height='100%' display='flex' alignItems='center' justifyContent='center'>
      <Typography variant='h4' color='textSecondary'>
        {title}
      </Typography>
    </Box>
  );
};
