import { createSvgIcon } from '@mui/material/utils';

export const SuccessIcon = createSvgIcon(
    <path
        d='M12 21c4.969 0 9-4.031 9-9s-4.031-9-9-9-9 4.031-9 9 4.031 9 9 9zm4.344-11.098a.526.526 0 000-.746.55.55 0 00-.766 0l-4.656 4.567-2.5-2.45a.55.55 0 00-.766 0 .526.526 0 000 .747l2.883 2.824c.211.207.55.207.762 0zm0 0'
        stroke='none'
        fillRule='evenodd'
    />,
    'SuccessIcon'
);
