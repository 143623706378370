import React, { useCallback, useEffect } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUsersQuery, useResetUserPasswordMutation } from '@/transport/usersApi';
import { useNotification } from '@/components/Notification';
import {
    ManageDialog,
    ManageDialogActions,
    ManageDialogContent,
    ManageDialogTitle,
} from '@/components/ManageDialog';

const ResetUserPasswordDialog: React.FC = () => {
    const navigate = useNavigate();
    const { userId } = useParams();

    const { data: usersData } = useGetUsersQuery();
    const [resetUserPassword, { isSuccess, isLoading, isError, data: userData }] =
        useResetUserPasswordMutation();

    const handleClose = useCallback(() => {
        navigate('/users');
    }, [navigate]);

    const handleReset = () => {
        if (!userId) return;
        resetUserPassword({ id: userId });
    };

    const { notify } = useNotification();

    useEffect(() => {
        if (isError) {
            notify({ message: "Error while resetting user's password", type: 'error' });
        }
    }, [isError, notify]);

    useEffect(() => {
        if (isSuccess) {
            notify({ message: "User's password reset successfully", type: 'success' });
        }
    }, [handleClose, isSuccess, notify]);

    const currentUserData = usersData?.data.find((user) => user.id === userId);

    return (
        <ManageDialog open={true} onClose={handleClose}>
            <ManageDialogTitle>Reset user password</ManageDialogTitle>
            <ManageDialogContent>
                {!currentUserData && (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}

                {!isSuccess && currentUserData && (
                    <Typography variant='body2'>
                        Are you sure want to reset the password for &quot;
                        {currentUserData.firstName} {currentUserData.lastName}&quot; (
                        {currentUserData.email})?
                    </Typography>
                )}

                {isSuccess && userData && (
                    <Typography variant='body2'>
                        Save user&apos;s new password:
                        <Typography fontWeight='bold'>{userData.newPassword}</Typography>
                    </Typography>
                )}
            </ManageDialogContent>
            <ManageDialogActions>
                <Button
                    color='secondary'
                    onClick={handleClose}
                    disabled={isLoading || !currentUserData}
                >
                    Cancel
                </Button>
                <Button
                    type='submit'
                    color='primary'
                    onClick={handleReset}
                    disabled={isLoading || !currentUserData || isSuccess}
                >
                    Reset
                </Button>
            </ManageDialogActions>
        </ManageDialog>
    );
};

export { ResetUserPasswordDialog };
