import { createSvgIcon } from '@mui/material/utils';

export const LockOpenIcon = createSvgIcon(
    <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M 10.25 1.929 C 9.939 1.974 9.634 2.056 9.342 2.172 C 7.602 2.865 6.57 4.666 6.852 6.517 L 6.891 6.774 C 6.939 7.091 7.023 7.403 7.14 7.702 L 8.29 10.647 C 11.044 10.46 13.808 10.476 16.559 10.696 L 18.441 10.846 C 19.658 10.943 20.653 11.858 20.85 13.064 C 21.305 15.842 21.305 18.676 20.85 21.454 C 20.653 22.659 19.658 23.575 18.441 23.672 L 16.559 23.822 C 13.588 24.059 10.603 24.059 7.632 23.822 L 5.75 23.672 C 4.533 23.575 3.539 22.659 3.341 21.454 C 2.886 18.676 2.886 15.842 3.341 13.064 C 3.539 11.858 4.533 10.943 5.75 10.846 L 6.325 10.8 L 5.382 8.388 C 5.215 7.959 5.095 7.513 5.026 7.058 L 4.987 6.801 C 4.573 4.082 6.089 1.437 8.644 0.42 C 9.073 0.249 9.521 0.129 9.978 0.062 C 12.7 -0.334 15.335 1.199 16.336 3.76 L 16.431 4.002 C 16.598 4.431 16.718 4.877 16.787 5.332 L 16.965 6.5 C 17.017 6.843 16.781 7.164 16.438 7.216 L 15.816 7.311 C 15.473 7.363 15.152 7.127 15.1 6.784 L 14.922 5.617 C 14.874 5.299 14.79 4.988 14.673 4.688 L 14.579 4.447 C 13.898 2.703 12.103 1.659 10.25 1.929 Z M 12.096 15.372 C 11.054 15.372 10.209 16.217 10.209 17.259 C 10.209 18.301 11.054 19.145 12.096 19.145 C 13.138 19.145 13.982 18.301 13.982 17.259 C 13.982 16.217 13.138 15.372 12.096 15.372 Z'
    />,
    'LockIcon'
);
