export enum MerchantsCatalogSortField {
    NAME = 'name',
    RECENTLY = 'recently',
    CASHBACK = 'cashback',
}

export type MerchantCategory = {
    id: number;
    name: string;
};

export type MerchantFilters = {
    categories: number[];
    sort: MerchantsCatalogSortField;
};
