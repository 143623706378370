import React from 'react';
import {
    TextField,
    Switch,
    FormControlLabel,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
    Checkbox,
    ListItemText,
    OutlinedInput,
    Typography,
    Box,
    FormHelperText,
} from '@mui/material';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as Yup from 'yup';
import { UserData } from '@/transport/usersApi/types';
import { useGetUserRolesQuery } from '@/transport/usersApi';
import { formatRoles } from '../../utils';

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format').required('Email is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    roles: Yup.array().required('Roles are required'), // .min(1, 'At least one role is required'),
});

interface UserFormProps {
    initialValues?: UserData;
    onSubmit: (values: UserData) => void;
    formRef: React.RefObject<FormikProps<UserData>>;
    disabled?: boolean;
    // onCancel: () => void;
}

const UserForm: React.FC<UserFormProps> = ({
    initialValues,
    onSubmit,
    formRef,
    disabled = true,
    // onCancel
}) => {
    const { data: rolesData } = useGetUserRolesQuery();

    return (
        <Formik
            initialValues={
                initialValues || {
                    email: '',
                    firstName: '',
                    lastName: '',
                    isActive: true,
                    roles: [],
                }
            }
            innerRef={formRef}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ values, handleChange, setFieldValue, touched, errors }) => (
                <Form>
                    <Box
                        sx={({ spacing }) => ({
                            display: 'flex',
                            flexDirection: 'column',
                            gap: spacing(3),
                        })}
                    >
                        <Field
                            as={TextField}
                            name='email'
                            label='Email'
                            fullWidth
                            variant='outlined'
                            error={touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                            disabled={disabled}
                        />
                        <Field
                            as={TextField}
                            name='firstName'
                            label='First Name'
                            fullWidth
                            variant='outlined'
                            error={touched.firstName && !!errors.firstName}
                            helperText={touched.firstName && errors.firstName}
                            disabled={disabled}
                        />
                        <Field
                            as={TextField}
                            name='lastName'
                            label='Last Name'
                            fullWidth
                            variant='outlined'
                            error={touched.lastName && !!errors.lastName}
                            helperText={touched.lastName && errors.lastName}
                            disabled={disabled}
                        />

                        <FormControl
                            fullWidth
                            variant='outlined'
                            error={touched.roles && !!errors.roles}
                            disabled={disabled}
                        >
                            <InputLabel>Roles</InputLabel>
                            <Select
                                multiple
                                name='roles'
                                value={values.roles}
                                onChange={(event) => setFieldValue('roles', event.target.value)}
                                input={<OutlinedInput label='Roles' />}
                                renderValue={(value) => formatRoles(rolesData?.data, value)}
                                disabled={!rolesData}
                            >
                                {rolesData &&
                                    rolesData.data.map((role) => (
                                        <MenuItem key={role.id} value={role.code}>
                                            <Checkbox checked={values.roles.includes(role.code)} />
                                            <ListItemText primary={role.title} />
                                        </MenuItem>
                                    ))}
                            </Select>
                            {touched.roles && errors.roles && (
                                <FormHelperText>{errors.roles}</FormHelperText>
                            )}
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Switch
                                    name='isActive'
                                    checked={values.isActive}
                                    onChange={handleChange}
                                    color='primary'
                                />
                            }
                            label={<Typography variant='body2'>Active user</Typography>}
                            disabled={disabled}
                        />
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default UserForm;
