import CircularProgressBase, { CircularProgressProps } from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Typography, styled } from '@mui/material';
import liberRed from '@/ui-kit/theme/colors/liberRed';
import liberWhite from '@/ui-kit/theme/colors/liberWhite';
import { FC } from 'react';

const Numerator = styled('span')(({ theme }) => ({ color: theme.palette.primary.main }));
const Denominator = styled('span')(({ theme }) => ({ color: theme.palette.text.secondary }));

export const CircularProgress: FC<
  CircularProgressProps & {
    value: number;
    numerator: string;
    denominator: string;
  }
> = (props) => (
  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
    <CircularProgress
      sx={{ color: liberRed[400], zIndex: 10 }}
      variant='determinate'
      size={80}
      thickness={4}
      {...props}
    />
    <CircularProgressBase
      sx={{ position: 'absolute', left: 0, color: liberWhite[200] }}
      variant='determinate'
      size={80}
      thickness={4}
      value={100}
    />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant='h4' component='div' color='text.secondary'>
        <Numerator>{props.numerator}</Numerator>
        <Denominator>/{props.denominator}</Denominator>
      </Typography>
    </Box>
  </Box>
);
