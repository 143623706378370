import { FC } from 'react';
import { NonAuthorizedLayoutWithoutOutlet } from '../../components';
import { DefaultI18nNSContextProvider, useTranslation } from '@/ui-kit/hooks/useTranslation';
import {
  NonAuthorizedContentWithImageLayout,
  RightColumnImage,
} from '@/bo-kit/components/NonAuthorizedContentWithImageLayout';
import { MessageBlock } from '@/bo-kit/components/NotFoundPage/components/MessageBlock';
import notfoundImg from '@/bo-kit/components/NotFoundPage/images/notfound.svg';

const Inner: FC = () => {
  const { t } = useTranslation('bo-kit__common');
  return (
    <NonAuthorizedContentWithImageLayout
      title={t('Page not found')}
      content={<MessageBlock />}
      image={<RightColumnImage src={notfoundImg} />}
    />
  );
};

export const NotFoundPageResponsive: FC = () => (
  <DefaultI18nNSContextProvider ns='bo-kit__errorpages'>
    <NonAuthorizedLayoutWithoutOutlet>
      <Inner />
    </NonAuthorizedLayoutWithoutOutlet>
  </DefaultI18nNSContextProvider>
);

// ADHOC: support lazy import
export default NotFoundPageResponsive;
