import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

export const transformError = (error: FetchBaseQueryError | SerializedError | undefined) => {
    if (!error) return;
    if ('status' in error) {
        const httpError = error as FetchBaseQueryError;

        const errorData = httpError.data as
            | { error: { code: string; message: string } }
            | undefined;

        // no predefined error codes, so there may be inaccuracies
        if (errorData && errorData?.error?.code) {
            return 'Invalid credentials';
        }
        return 'Network error';
    } else {
        // const clientError = error as SerializedError;
        return 'Unknown error';
    }
};
