import React, { FC, PropsWithChildren, ReactElement } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { Button, styled } from '@mui/material';

const VerticalIconButton = styled(Button)(({ theme }) => ({
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  '&:hover': {
    color: theme.palette.primary.main,
  },
  '&:active': {
    color: theme.palette.primary.main,
  },
  '& .MuiButton-startIcon': {
    marginRight: 0,
    marginLeft: 0,
    fontSize: theme.typography.fontSize,
    lineHeight: 1,
    textAlign: 'center',
  },
  '& > a': {
    color: 'inherit',
    textDecoration: 'inherit',
    '& > .label': {
      textAlign: 'center',
    },
  },
}));

type MenuButtonProps = (PropsWithChildren & {
  icon: ReactElement;
  disabled?: boolean;
  strict?: boolean;
}) &
  ({ href: string } | { onClick: () => void });

export const MenuButton: FC<MenuButtonProps> = ({ children, icon, disabled, strict, ...props }) => {
  let href: string | undefined;
  let onClick: (() => void) | undefined;
  if ('href' in props) {
    href = props.href;
  } else if ('onClick' in props) {
    onClick = props.onClick;
  }
  const isActive = useMatch(href ? (strict ? href : `${href}/*`) : '') && href;

  const buttonProps = href ? { LinkComponent: Link, to: href } : { onClick };

  return (
    <VerticalIconButton
      {...buttonProps}
      color={isActive ? 'primary' : 'secondary'}
      startIcon={icon}
      disabled={disabled}
    >
      <div className='label'>{children}</div>
    </VerticalIconButton>
  );
};
