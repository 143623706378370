import { rest, RestRequest } from 'msw';
// import { requestURL } from './buyCouponOB';
/**
 * Start local frontend with env parameter: 'REACT_APP_MSW_MOCK_AUTH_TOKEN=eyJhbGciOi...jt9jhpLDlJSck' to force some handler to mock responses,
 * like 'referral-program' or 'session/profile'
 * @param req
 */

const isAuthTokenToBeMocked = (req: RestRequest): boolean =>
    req.headers.get('authorization') === `Bearer ${process.env.REACT_APP_MSW_MOCK_AUTH_TOKEN}`;

export const handlers = [
    rest.get(`${process.env.REACT_APP_API_GATEWAY_URL}/content/cms/cities`, (req, res, ctx) => {
        // return res(ctx.status(200), ctx.json(require('./topMerchants.json')));
        return req.passthrough();
    }),
    // rest.post(
    //     `${process.env.REACT_APP_API_GATEWAY_URL}/payment/simple-checkout`,
    //     (req: RestRequest, res, ctx) => {
    //         const requestBody = (req as RestRequest).body;
    //         if (
    //             typeof requestBody === 'object' &&
    //             requestBody !== null &&
    //             'payment_method' in requestBody
    //         ) {
    //             if (requestBody?.payment_method && requestBody?.payment_method === 'stripe') {
    //                 // eslint-disable-next-line @typescript-eslint/no-var-requires
    //                 return res(ctx.status(200), ctx.json(require('./simpleCheckoutstripe.json')));
    //             } else {
    //                 // eslint-disable-next-line @typescript-eslint/no-var-requires
    //                 return res(ctx.status(200), ctx.json(require('./simpleCheckoutlsp2p.json')));
    //             }
    //         }
    //     }
    // ),
    // rest.post(
    //     `${process.env.REACT_APP_API_GATEWAY_URL}/mc/transfer/buy-coupon`,
    //     (req, res, ctx) => {
    //         // eslint-disable-next-line @typescript-eslint/no-var-requires
    //         return res(ctx.status(200), ctx.json(require('./buyCoupon.json')));
    //     }
    // ),
    rest.get(`${process.env.REACT_APP_API_GATEWAY_URL}/wallet`, async (req, res, ctx) => {
        // return res(ctx.status(200), ctx.json(require('./wallet_maria_c27_QA.json')));
        return req.passthrough();
    }),
    rest.get(`${process.env.REACT_APP_API_GATEWAY_URL}/wallet/cashback`, (req, res, ctx) => {
        // return res(ctx.status(200), ctx.json(require('./cashback.json')));
        return req.passthrough();
    }),
    rest.get(
        `${process.env.REACT_APP_API_GATEWAY_URL}/statements/ee733640-7724-11ee-80e6-75d04bbd3cd9/d19bf08d-e97d-4199-8791-1f65a984c5b4`,
        (req, res, ctx) => {
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            return res(ctx.status(200), ctx.json(require('./statements.json')));
        }
    ),
    rest.get(`${process.env.REACT_APP_API_GATEWAY_URL}/user/profile`, (req, res, ctx) => {
        if (isAuthTokenToBeMocked(req)) {
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            return res(ctx.status(200), ctx.json(require('./profile.json')));
        }
        return req.passthrough();
    }),
    rest.get(`${process.env.REACT_APP_API_GATEWAY_URL}/referral-program`, (req, res, ctx) => {
        if (isAuthTokenToBeMocked(req)) {
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            return res(ctx.status(200), ctx.json(require('./referralProgram.json')));
        }
        return req.passthrough();
    }),
    rest.post(
        `${process.env.REACT_APP_API_GATEWAY_URL}/transfer/ob-status-transfer`,
        (req, res, ctx) => {
            if (isAuthTokenToBeMocked(req)) {
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                return res(ctx.status(200), ctx.json(require('./referralProgram.json')));
            }
            return req.passthrough();
        }
    ),
];
