import { styled } from '@mui/material';
import { DetailedHTMLProps, FC, HTMLAttributes, PropsWithChildren } from 'react';

const Inner = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
});
const Outer = styled(
    (
        props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
            background?: string;
        }
    ) => <div {...props} />,
    { shouldForwardProp: (propName) => propName !== 'background' }
)(({ background }) => ({
    width: '100%',
    paddingBottom: '100%',
    position: 'relative',
    background,
}));

/**
 *
 * @param background
 * @param children
 * @param className - used to support styling: `styled(SquareBox)`
 * @constructor
 */

export const SquareBox: FC<PropsWithChildren<{ background?: string; className?: string }>> = ({
    background,
    children,
    className,
}) => (
    <Outer
        className={className}
        background={background}
        sx={{
            background,
        }}
    >
        <Inner>{children}</Inner>
    </Outer>
);
