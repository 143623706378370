import { Role } from '@/transport/usersApi/types';

export function formatUserFullName(
    firstName: string | null | undefined = null,
    lastName: string | null | undefined = null,
    labelName: string | null | undefined = null
): string {
    let namePart = '';

    if (firstName && lastName) {
        namePart = `${firstName} ${lastName}`;
    } else if (firstName) {
        namePart = firstName;
    } else if (lastName) {
        namePart = lastName;
    }

    if (labelName) {
        return namePart ? `${labelName} (${namePart})` : labelName;
    }

    return namePart;
}

export const formatRoles = (roles: Role[] = [], codes: string[] = []) => {
    return codes.map((code) => roles.find((role) => role.code === code)?.title).join(', ');
};
