import React from 'react';
import { useTranslation } from '@/ui-kit/hooks';
import { Box, Stack } from '@mui/material';
import { PageTitle } from '@/ui-kit/components';
import { Outlet } from 'react-router-dom';
import { UsersTable } from '../components/UsersTable';

const DesktopUsersListPage = () => {
    const { t } = useTranslation('statements');

    return (
        <Box
            sx={({ spacing }) => ({
                pb: spacing(5),
            })}
        >
            <Stack direction='row' justifyContent='space-between'>
                <Box>
                    <PageTitle>{t('Users list')}</PageTitle>
                </Box>
            </Stack>
            <UsersTable />
            <Outlet />
        </Box>
    );
};

export default DesktopUsersListPage;
