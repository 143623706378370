export const getDateInYYYYMMDDCurrentTZFormat = (date: string | Date | number) => {
    // Returns the user's date taking into account the timezone in which he is located.
    // It is necessary, for example, in datepicker

    const dateObj = new Date(date);

    const formatter = new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });

    const [day, month, year] = formatter.format(dateObj).split('/');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
};
