import { FC, useCallback, useMemo } from 'react';
import {
  List as ListBase,
  ListItem,
  ListItemButton as ListItemButtonBase,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import bgLogoIconV1 from './images/bgLogoPart1_button.svg';
import bgLogoIconV2 from './images/bgLogoPart2_button.svg';
import liberWhite from '@/ui-kit/theme/colors/liberWhite';
import { PageDialog } from '@/ui-kit/components';
import { BagIcon, PersonIcon } from '@/ui-kit/icons';
import { UserType } from '@/bo-kit/types';
import { getUserTypeName } from '@/bo-kit/utils/getUserTypeName';

const liberBlue = {
  violet: '#414FFD',
};

const List = styled(ListBase)(({ theme: { palette, spacing } }) => ({
  '& > .MuiListItem-root:not(:last-child)': {
    marginBottom: spacing(3),
  },
}));

const ListItemButton = styled(
  (props: ListItemButtonProps & { background: 'even' | 'odd' }) => (
    <ListItemButtonBase {...props} />
  ),
  { shouldForwardProp: (propName) => propName !== 'background' }
)(({ background, theme: { shape, spacing, palette } }) => ({
  minHeight: spacing(12),
  border: `2px solid ${liberWhite[200]}`,
  borderRadius: shape.borderRadius,
  background: `left no-repeat url(${background === 'even' ? bgLogoIconV1 : bgLogoIconV2}) ${
    palette.common.white
  }`,
}));

export const ChooseLoginModal: FC<{
  btnCaption: string;
  roles: Array<UserType>;
  onCancel: () => void;
  onSelect: (type: UserType) => void;
}> = ({ roles, onCancel, onSelect, btnCaption }) => {
  const rolesSorted = useMemo(
    () => [...roles].sort((a, b) => (a === b ? 0 : a > b ? 1 : -1)),
    [roles]
  );
  const { t } = useTranslation('bo-kit__signin');
  const getHandleClick = useCallback((userType: UserType) => () => onSelect(userType), [onSelect]);
  return (
    <PageDialog open onClose={onCancel} closeVariant='cross' title={t('Log In')}>
      <Stack spacing={4}>
        <Typography variant='h3' color='common.black'>
          {t('Choose an account:')}
        </Typography>
        <List>
          {rolesSorted.map((role, key) => (
            <ListItem key={key}>
              <ListItemButton
                background={key % 2 === 0 ? 'even' : 'odd'}
                onClick={getHandleClick(role)}
                dense
              >
                <ListItemText primaryTypographyProps={{ variant: 'h4' }}>
                  {t(btnCaption, { ROLE: t(getUserTypeName(role)) })}
                </ListItemText>
                <ListItemIcon>
                  {[UserType.Customer, UserType.CustomerLegal].includes(role) ? (
                    <PersonIcon sx={{ color: liberBlue['violet'] }} />
                  ) : (
                    <BagIcon color='primary' />
                  )}
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Stack>
    </PageDialog>
  );
};
