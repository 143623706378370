import { createSvgIcon } from '@mui/material/utils';

export const BookIcon = createSvgIcon(
    <>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.563 5.277a8.958 8.958 0 00-7.936-.636.997.997 0 00-.627.926v9.993a.946.946 0 001.298.879 7.336 7.336 0 016.498.52l.973.584a.43.43 0 00.231.063.43.43 0 00.23-.063l.974-.584a7.336 7.336 0 016.498-.52.946.946 0 001.298-.88V5.568a.997.997 0 00-.627-.926 8.958 8.958 0 00-7.936.636L12 5.539l-.437-.262zm1.08 2.047a.643.643 0 00-1.286 0v8.143a.643.643 0 001.286 0V7.324z'
        />
        <path d='M4.05 18.075a5.571 5.571 0 015.614 0l.932.544c.868.506 1.94.506 2.808 0l.932-.544a5.571 5.571 0 015.614 0l.088.051a.643.643 0 01-.648 1.11l-.088-.05a4.286 4.286 0 00-4.319 0l-.931.543a4.071 4.071 0 01-4.103 0l-.932-.543a4.285 4.285 0 00-4.32 0l-.087.05a.643.643 0 11-.648-1.11l.088-.051z' />
    </>,
    'BookIcon'
);
