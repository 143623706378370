import { createSvgIcon } from '@mui/material/utils';

export const StatementsIcon = createSvgIcon(
    <>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M 11.417 2.853 C 8.216 0.931 4.301 0.618 0.836 2.005 C 0.331 2.206 0 2.695 0 3.239 L 0 16.563 C 0 17.455 0.901 18.066 1.731 17.735 C 4.568 16.599 7.773 16.857 10.395 18.429 L 11.692 19.207 C 11.792 19.267 11.897 19.293 12 19.291 C 12.103 19.293 12.208 19.267 12.308 19.207 L 13.605 18.429 C 16.227 16.857 19.432 16.599 22.269 17.735 C 23.099 18.066 24 17.455 24 16.563 L 24 3.239 C 24 2.695 23.669 2.206 23.164 2.005 C 19.699 0.618 15.784 0.931 12.583 2.853 L 12 3.202 L 11.417 2.853 Z M 12.857 5.582 C 12.857 5.109 12.473 4.726 12 4.726 C 11.527 4.726 11.143 5.109 11.143 5.582 L 11.143 16.439 C 11.143 16.913 11.527 17.297 12 17.297 C 12.473 17.297 12.857 16.913 12.857 16.439 L 12.857 5.582 Z'
        />
        <path d='M 1.4 19.917 C 3.713 18.567 6.573 18.567 8.885 19.917 L 10.128 20.642 C 11.285 21.317 12.715 21.317 13.872 20.642 L 15.115 19.917 C 17.427 18.567 20.287 18.567 22.6 19.917 L 22.717 19.985 C 23.127 20.223 23.265 20.749 23.027 21.158 C 22.788 21.566 22.263 21.705 21.853 21.466 L 21.736 21.398 C 19.957 20.359 17.757 20.359 15.977 21.398 L 14.736 22.122 C 13.045 23.109 10.955 23.109 9.265 22.122 L 8.023 21.398 C 6.243 20.359 4.043 20.359 2.264 21.398 L 2.147 21.466 C 1.737 21.705 1.212 21.566 0.973 21.158 C 0.736 20.749 0.873 20.223 1.283 19.985 L 1.4 19.917 Z' />
    </>,
    'StatementsIcon'
);
