export type LoginRequest = {
    email: string;
    password: string;
};

export type LoginResponse = UserProfile;

export type ProfileResponse = UserProfile;

export enum Permission {
    UsersRead = 'users_read',
    UsersEdit = 'users_edit',
    RolesRead = 'roles_read',
    RolesEdit = 'roles_edit',
    StatRead = 'stat_read',
    OrdersRead = 'orders_read',
    MerchantsRead = 'merchants_read',
    MerchantsWithdrawals = 'merchants_withdrawals',
    AccountTransactionsRead = 'account_transactions_read',
    AccountTransactionsEdit = 'account_transactions_edit',

    // NotExistTestPermission = 'not_exist_test',
}

type UserProfile = {
    id: string;
    createdAt: string;
    updatedAt: string;
    email: string;
    firstName: string;
    lastName: string;
    isActive: boolean;
    isAdmin: boolean;
    roles: string[];
    permissions: Permission[];
};
