import React from 'react';
import { useTranslation } from '@/ui-kit/hooks';
import { Box, Stack, Typography } from '@mui/material';
import { MerchantsTable } from '../components/MerchantsTable';

const MobileMerchantsListPage = () => {
    const { t } = useTranslation('statements');

    return (
        <>
            <Stack direction='row' justifyContent='space-between'>
                <Box>
                    <Typography variant='h1' fontWeight='bold' sx={{ mb: 1.25 }}>
                        {t('Merchants list')}
                    </Typography>
                </Box>
            </Stack>
            <MerchantsTable />
        </>
    );
};

export default MobileMerchantsListPage;
