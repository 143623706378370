import React, { useEffect, useState } from 'react';
import { useAccountTransactionsFilters } from '../hooks/useAccountTransactionsFilters';
import { subMonths } from 'date-fns';
import { Box, MenuItem, TextField } from '@mui/material';
import { DateRangePicker } from '@/components/DateRangePicker';
import { ElevatedPaper } from '@/ui-kit/components';
import { useGetTransactionCategoriesQuery } from '@/transport/accountTransactionsApi';
import { MultipleSelect } from '@/components/MultipleSelect';
import { TransactionDirection } from '@/transport/accountTransactionsApi/types';
import { TokenizedAutocomplete } from '@/components/TokenizedAutocomplete';
import { useDebounce } from '@/ui-kit/hooks';
import { ExportTransactionsButton } from './ExportTransactionsButton';

const today = new Date();
const monthAgo = subMonths(new Date(), 1);

export const TransactionsFilters = () => {
    const {
        dateStart,
        dateEnd,
        categories,
        direction,
        referenceIds,

        setDateStart,
        setDateEnd,
        setCategories,
        setDirection,
        setReferenceIds,
        setReferenceSubstring,
    } = useAccountTransactionsFilters();

    const handleDateChange = ({
        dateStart,
        dateEnd,
    }: {
        dateStart: Date | null;
        dateEnd: Date | null;
    }) => {
        setDateStart(dateStart || monthAgo);
        setDateEnd(dateEnd || today);
    };

    const { data: categoriesData } = useGetTransactionCategoriesQuery();
    const categoryNames = categoriesData?.data?.map((category) => category.subject) || [];
    const transactionDirections = [
        { label: 'All', value: 'all' },
        { label: 'Inbound', value: 'inbound' },
        { label: 'Outbound', value: 'outbound' },
    ];

    const [referenceQuery, setReferenceQuery] = useState('');
    const referenceQueryDebounced = useDebounce(referenceQuery, 500);

    useEffect(() => {
        setReferenceSubstring(referenceQueryDebounced);
    }, [referenceQueryDebounced, setReferenceSubstring]);

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    height: 0,
                    position: 'relative',
                }}
            >
                <ExportTransactionsButton
                    sx={{ position: 'absolute', bottom: 0 }}
                    dateStart={dateStart}
                    dateEnd={dateEnd}
                    categories={categories}
                    referenceIds={referenceIds}
                    referenceSubstring={referenceQueryDebounced}
                />
            </Box>

            <ElevatedPaper
                sx={({ spacing }) => ({
                    py: spacing(3),
                    px: spacing(5),
                    width: '100%',
                    margin: '0 auto',
                    mb: spacing(4),
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    columnGap: '10px',
                    rowGap: '10px',
                })}
            >
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr 1fr',
                        columnGap: '10px',
                        rowGap: '10px',
                        width: '100%',
                        margin: '0 auto',
                        '@media (max-width: 768px)': {
                            gridTemplateColumns: '1fr',
                        },
                    }}
                >
                    <DateRangePicker
                        date={{ dateStart: new Date(dateStart), dateEnd: new Date(dateEnd) }}
                        onDateChange={handleDateChange}
                    />
                    <TextField
                        sx={{
                            gridColumn: 'span 2',
                            '@media (max-width: 768px)': {
                                gridColumn: 'span 1',
                            },
                        }}
                        label='Reference search'
                        placeholder='Search by reference substring'
                        value={referenceQuery}
                        onChange={(event) => setReferenceQuery(event.target.value)}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr',
                        columnGap: '10px',
                        rowGap: '10px',
                        width: '100%',
                        margin: '0 auto',
                        '@media (max-width: 768px)': {
                            gridTemplateColumns: '1fr',
                        },
                    }}
                >
                    <MultipleSelect
                        label='Categories'
                        placeholder='Filter by categories'
                        options={categoryNames}
                        value={categories}
                        onChange={setCategories}
                        limitTags={1}
                    />
                    <TextField
                        select
                        label='Direction'
                        defaultValue={'all'}
                        placeholder='Filter by direction'
                        value={direction}
                        onChange={(event) => {
                            const value = event.target.value;
                            setDirection(
                                (value === 'all' ? undefined : value) as
                                    | TransactionDirection
                                    | undefined
                            );
                        }}
                    >
                        {transactionDirections.map((direction) => (
                            <MenuItem key={direction.value} value={direction.value}>
                                {direction.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TokenizedAutocomplete
                        label='Reference ID'
                        placeholder='Filter by Reference ID'
                        value={referenceIds}
                        onChange={setReferenceIds}
                    />
                </Box>
            </ElevatedPaper>
        </Box>
    );
};
