import { FC } from 'react';
import { TypographyProps, styled, Typography } from '@mui/material';
import { AttachIcon as AttachIconBase } from '../../icons';
import * as React from 'react';

const AttachIcon = styled(AttachIconBase)(({ theme }) => ({
    fill: theme.palette.error.main,
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
}));

const Link = styled('a')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
}));

const LinkText = styled((props: TypographyProps) => <Typography variant='body2' {...props} />)(
    ({ theme }) => ({
        fontWeight: theme.typography.fontWeightRegular,
        textDecorationLine: 'underline',

        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    })
);

type MerchantContentWebsiteProps = {
    text: string;
    href: string;
};

export const MerchantContentWebsite: FC<MerchantContentWebsiteProps> = ({ text, href }) => {
    return (
        <Link target='_blank' href={href}>
            <AttachIcon />
            <LinkText>{text}</LinkText>
        </Link>
    );
};
