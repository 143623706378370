import { FC } from 'react';
import { Grid } from '@mui/material';
import { LanguageSwitcher } from '../../..';
import { Logo } from './components/Logo';
import { GridStyled } from './components/GridStyled';

export const NonAuthorizedLayoutHeader: FC = () => (
  <GridStyled item container direction='row' justifyContent='space-between'>
    <Grid item alignItems='center' container width='auto'>
      <Logo />
    </Grid>
    <Grid item>
      <LanguageSwitcher />
    </Grid>
  </GridStyled>
);
