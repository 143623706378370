import React, { useState } from 'react';
import { Autocomplete, Box, Checkbox, TextField } from '@mui/material';
import liberWhite from '@/ui-kit/theme/colors/liberWhite';

interface MultipleSelectProps {
    options: string[];
    value: string[];
    onChange: (options: string[]) => void;
    limitTags?: number;
    label?: string;
    placeholder?: string;
}

export const MultipleSelect: React.FC<MultipleSelectProps> = ({
    options,
    value,
    onChange,
    limitTags = -1,
    label,
    placeholder,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Autocomplete
            multiple
            options={options}
            value={value}
            onChange={(_, values) => onChange(values)}
            disableCloseOnSelect
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
                <Box {...props} key={option} component={'li'} fontSize={'1rem'}>
                    <Checkbox size='small' style={{ marginRight: 8 }} checked={selected} />
                    {option}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    // placeholder={value.length > 0 && !isOpen ? '' : placeholder}
                    placeholder={placeholder}
                    sx={(theme) => ({
                        [theme.breakpoints.up('sm')]: {
                            // position: 'relative',
                            height: 56,
                            '& .MuiInputBase-root': {
                                // position: 'absolute',
                                display: 'flex',
                                flexDirection: 'row',
                                bgcolor: liberWhite[400],
                            },
                        },
                        '& .MuiInputBase-input': {
                            display: value.length > 0 && !isOpen ? 'none' : 'initial',
                        },
                        '&:focus-within .MuiFormLabel-root': {
                            zIndex: 2,
                        },
                        '&:focus-within .MuiInputBase-root': {
                            zIndex: 1,
                        },
                    })}
                />
            )}
            sx={{ display: 'flex', flexWrap: 'nowrap' }}
            limitTags={limitTags}
        />
    );
};
