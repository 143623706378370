import { Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

/**
 * TODO: fix subtitle3 typing and replace it with <Typography variant="subtitle3" ...>...</>Typography
 * @param props
 * @returns
 */

export const TypographySubtitle3: FC<Omit<TypographyProps, 'variant'>> = (props) => (
    <>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Typography variant='subtitle3' {...props} />
    </>
);
