import { createSvgIcon } from '@mui/material/utils';

export const ClockIcon = createSvgIcon(
    <path
        d='M4 12a8 8 0 1116 0 8 8 0 01-16 0zm8.707-4.707a.706.706 0 00-1.414 0V12c0 .242.125.469.332.598l2.824 1.765a.706.706 0 00.75-1.195l-2.492-1.559zm0 0'
        stroke='none'
        fillRule='evenodd'
    />,
    'ClockIcon'
);
