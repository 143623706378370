import { FC, ReactNode } from 'react';
import { Box, Typography, styled } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ChevronLeftIcon } from '../../icons';
import liberWhite from '../../theme/colors/liberWhite';
import liberBlack from '../../theme/colors/liberBlack';

const PageHeaderContainer = styled(Box)(({ theme }) => ({
    background: liberWhite[300],
    boxShadow: theme.shadows[3],

    marginTop: theme.spacing(-2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),

    [theme.breakpoints.up('sm')]: {
        background: 'none',
        boxShadow: 'none',
        paddingTop: theme.spacing(1),
    },
}));

const PageHeaderInner = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    alignItems: 'center',
}));

const PageHeaderTitle = styled(Typography)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '20px',

    [theme.breakpoints.up('sm')]: {
        color: liberBlack[100],
    },
}));

type PageHeaderProps = {
    children: string | ReactNode;
    onClose?: () => void;
    closeVariant: 'back' | 'cross';
    titleBtn?: ReactNode;
};

const hiddenBtnStyle = { opacity: 0, pointerEvents: 'none', cursor: 'none' } as const;

export const PageDialogTitle: FC<PageHeaderProps> = ({
    children,
    onClose,
    closeVariant,
    titleBtn,
}) => {
    return (
        <PageHeaderContainer>
            <PageHeaderInner>
                {onClose && (
                    <ChevronLeftIcon
                        onClick={onClose}
                        sx={
                            closeVariant === 'back'
                                ? {
                                      cursor: 'pointer',
                                  }
                                : hiddenBtnStyle
                        }
                    />
                )}
                <PageHeaderTitle variant='h4' noWrap>
                    {children}
                </PageHeaderTitle>
                {titleBtn}
                {onClose ? (
                    <CloseRoundedIcon
                        onClick={onClose}
                        sx={
                            closeVariant === 'cross'
                                ? {
                                      cursor: 'pointer',
                                  }
                                : hiddenBtnStyle
                        }
                    />
                ) : (
                    <Box />
                )}
            </PageHeaderInner>
        </PageHeaderContainer>
    );
};
