import React, { FC } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { LocationIcon as LocationIconBase } from '../../icons';

const LocationIcon = styled(LocationIconBase)(({ theme }) => ({
    fill: theme.palette.error.main,
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
}));

const Container = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}));

type MerchantContentAddressProps = {
    address: string;
};

export const MerchantContentAddress: FC<MerchantContentAddressProps> = ({ address }) => {
    return (
        <Container>
            <LocationIcon />
            <Typography variant='body2'>{address}</Typography>
        </Container>
    );
};
