import { ElevatedPaper } from '@/bo-kit/components/ElevatedPaper';
import { useTranslation } from '@/ui-kit/hooks';
import { Box } from '@mui/material';
import { CellHeader, RowHeader } from './TableElements';
import { TransactionsRowSkeleton } from './TransactionsRowSkeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TransactionsRow } from './TransactionsRow';
import { useAccountTransactionsFilters } from '../hooks/useAccountTransactionsFilters';
import { useGetAccountTransactions } from '../hooks/useGetAccountTransactions';

const columns = ['ID', 'Date', 'Category', 'Status', 'Amount', 'Reference'];

export const TransactionsTable = () => {
    const { t } = useTranslation('statements');

    const { dateStart, dateEnd, categories, direction, referenceIds, referenceSubstring } =
        useAccountTransactionsFilters();

    const {
        data: accountTransactionsData,
        isError,
        isLoading,
        loadNextPage,
        hasNextPage,
    } = useGetAccountTransactions({
        dateStart,
        dateEnd,
        categories,
        direction,
        referenceIds,
        referenceSubstring,
    });

    return (
        <Box sx={{ overflow: 'auto' }}>
            <Box
                sx={{
                    minWidth: '1000px',
                    pb: 2,
                }}
            >
                <ElevatedPaper
                    sx={({ spacing }) => ({
                        mt: spacing(4),
                        py: spacing(3),
                        px: spacing(5),
                        width: 'fit-content',
                        minWidth: '100%',
                        margin: '0 auto',
                        mb: spacing(1),
                    })}
                >
                    <RowHeader
                        sx={({ spacing }) => ({
                            mt: spacing(1),
                            px: spacing(5),
                            py: spacing(2.5),
                        })}
                    >
                        {columns.map((columnName) => (
                            <CellHeader key={columnName}>{t(columnName)}</CellHeader>
                        ))}
                    </RowHeader>
                </ElevatedPaper>
                <ElevatedPaper
                    sx={({ spacing }) => ({
                        mt: spacing(4),
                        py: spacing(3),
                        px: spacing(5),
                        width: 'fit-content',
                        minWidth: '100%',
                        margin: '0 auto',
                    })}
                >
                    {isLoading &&
                        Array.from({ length: 8 }, (_, index) => index).map((_, index) => (
                            <TransactionsRowSkeleton key={index} />
                        ))}

                    {!isError && !isLoading && (
                        <InfiniteScroll
                            dataLength={accountTransactionsData.length}
                            next={loadNextPage}
                            hasMore={hasNextPage}
                            loader={<TransactionsRowSkeleton />}
                        >
                            {accountTransactionsData?.map((item, index) => (
                                <TransactionsRow
                                    key={item.id + item.referenceId}
                                    id={item.id}
                                    referenceId={item.referenceId}
                                    reference={item.reference}
                                    date={item.date}
                                    amount={item.amount}
                                    currency={item.currency}
                                    status={item.status}
                                    category={item.category}
                                    direction={item.direction}
                                />
                            ))}
                        </InfiniteScroll>
                    )}
                </ElevatedPaper>
            </Box>
        </Box>
    );
};
