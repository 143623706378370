import { useContext } from 'react';
import { NotificationContext } from './NotificationContext';

export const useNotification = () => {
    const context = useContext(NotificationContext);

    if (context === null) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }
    return context;
};
