const cdnUrl = process.env.REACT_APP_CDN_URL ?? process.env.NEXT_PUBLIC_CDN_URL;

export function getCDNImageUrl(image: string): string;
export function getCDNImageUrl(image: string | null): string | null;
export function getCDNImageUrl(image: string | undefined): string | undefined;
export function getCDNImageUrl(image: string | null | undefined): string | null | undefined {
    if (!image) {
        return null;
    }
    if (image.startsWith('https://') || image.startsWith('http://')) {
        return image;
    }
    return `${cdnUrl}${image}`;
}
