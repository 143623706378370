import React from 'react';
import { useTranslation } from '@/ui-kit/hooks';
import { Box } from '@mui/material';
import { ElevatedPaper } from '@/bo-kit/components/ElevatedPaper';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MerchantRowSkeleton } from './MerchantRowSkeleton';
import { MerchantRow } from './MerchantRow';
import { CellHeader, RowHeader } from './TableElements';
import { useNavigate } from 'react-router-dom';
import { useGetMerchants } from '../hooks/useGetMerchants';

const columns = [
    'Merchant ID',
    'Name',
    'Coupon',
    'Email',
    'Bank Balance',
    'Platform Income',
    'Platform Fee',
    'Last Operation',
    'Status',
];

export const MerchantsTable: React.FC = () => {
    const { t } = useTranslation('merchants');

    const {
        data: merchantsData,
        isError,
        isLoading,
        loadNextPage,
        hasNextPage,
    } = useGetMerchants();

    const navigate = useNavigate();

    const handleClickMerchantRow = (merchantId: string) => {
        navigate(merchantId);
    };

    return (
        <Box
            sx={{
                overflow: 'auto',
            }}
        >
            <Box
                sx={{
                    minWidth: '1000px',
                    position: 'relative',
                }}
            >
                <ElevatedPaper
                    sx={({ spacing }) => ({
                        mt: spacing(4),
                        py: spacing(3),
                        px: spacing(5),
                        width: 'fit-content',
                        minWidth: '100%',
                        margin: '0 auto',
                        mb: spacing(1),
                    })}
                >
                    <div>
                        <RowHeader
                            sx={({ spacing }) => ({
                                mt: spacing(1),
                                px: spacing(5),
                                py: spacing(2.5),
                            })}
                        >
                            {columns.map((columnName) => (
                                <CellHeader key={columnName}>{t(columnName)}</CellHeader>
                            ))}
                        </RowHeader>
                    </div>
                </ElevatedPaper>
                <ElevatedPaper
                    sx={({ spacing }) => ({
                        py: spacing(3),
                        px: spacing(5),
                        width: 'fit-content',
                        minWidth: '100%',
                        margin: '0 auto',
                        mb: spacing(4),
                    })}
                >
                    {isLoading &&
                        Array.from({ length: 8 }, (_, index) => index).map((_, index) => (
                            <MerchantRowSkeleton key={index} />
                        ))}
                    <InfiniteScroll
                        dataLength={merchantsData?.length || 0}
                        next={loadNextPage}
                        hasMore={hasNextPage}
                        loader={<MerchantRowSkeleton />}
                    >
                        {!isLoading &&
                            !isError &&
                            merchantsData?.map((merchant) => (
                                <div
                                    key={merchant.id}
                                    onClick={() => {
                                        handleClickMerchantRow(merchant.id);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <MerchantRow
                                        key={merchant.id}
                                        firstName={merchant.firstName}
                                        lastName={merchant.lastName}
                                        legalName={merchant.legalName}
                                        email={merchant.email}
                                        id={merchant.id}
                                        isActivated={merchant.isActivated}
                                        isRemoved={merchant.isRemoved}
                                        coupon={merchant.coupon}
                                        bankBalance={merchant.balances.bank}
                                        platformFee={merchant.balances.platformFee}
                                        platformIncome={merchant.platformIncome.total}
                                        lastOperationDate={merchant.lastOperationDate}
                                        lastOperationTariff={merchant.lastOperationTariff}
                                    />
                                </div>
                            ))}
                    </InfiniteScroll>
                </ElevatedPaper>
            </Box>
        </Box>
    );
};
