export const formatNumber = (number: number) => {
    const roundedNumber = Math.round(number * 10) / 10;

    const [integerPart, decimalPart] = roundedNumber.toString().split('.');

    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    const formattedNumber =
        decimalPart !== undefined ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;

    return formattedNumber;
};

export const getDateInYYYYMMDDFormat = (date: Date): string =>
    new Date(date).toISOString().split('T')[0];

interface GetTransferVolumeFormParametrsType {
    dateStart: string;
    dateEnd: string;
    coupons?: string;
}

export const onGetTransferVolumeFormParametrs = (arg: GetTransferVolumeFormParametrsType) => {
    const dateParametrs = `dateStart=${arg.dateStart}&dateEnd=${arg.dateEnd}`;

    return '?' + dateParametrs + arg.coupons ?? '';
};
