import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { UAParser } from 'ua-parser-js';

export const desktopBreakpoint: Breakpoint = 'sm';

export const useIsDesktop = () => {
    const theme = useTheme();
    const isDesktopUAParsed = useMemo(() => new UAParser().getDevice().type !== 'mobile', []);
    const isDesktopMediaQuery = useMediaQuery(theme.breakpoints.up(desktopBreakpoint));
    return isDesktopUAParsed && isDesktopMediaQuery;
};
