import { Alert, Box, Snackbar, Tooltip, tooltipClasses, Typography } from '@mui/material';
import React, { useState } from 'react';

interface CopyableOrderIdProps {
    value: string;
    start?: number;
    end?: number;
}

export const CopyableOrderId: React.FC<CopyableOrderIdProps> = ({ value, start = 4, end = 3 }) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleCopyToClipboard = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        navigator.clipboard.writeText(value);
        setOpenSnackbar(true);
    };

    const formattedValue =
        value.length > start + end ? `${value.slice(0, start)}...${value.slice(-end)}` : value;

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Box onClick={(e) => e.stopPropagation()} sx={{ cursor: 'pointer' }}>
            <Tooltip
                sx={{}}
                title={value}
                PopperProps={{
                    sx: {
                        [`&.${tooltipClasses.popper} .${tooltipClasses.tooltip}`]: {
                            marginTop: 0,
                        },
                    },
                }}
            >
                <Typography
                    variant='body2'
                    onClick={handleCopyToClipboard}
                    sx={(theme) => ({
                        color: theme.palette.text.primary,
                        p: 0,
                    })}
                >
                    {formattedValue}
                </Typography>
            </Tooltip>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert severity='success'>Order ID has been copied to the clipboard!</Alert>
            </Snackbar>
        </Box>
    );
};
