import { styled, useTheme } from '@mui/material';
import { FC } from 'react';

const MerchantLogoInner = styled('div')(({ theme }) => ({
  top: theme.spacing(1.5),
  left: theme.spacing(1.5),
  right: theme.spacing(1.5),
  margin: 'auto',
  borderRadius: theme.spacing(0.5),
  height: '100%',
  width: '100%',
}));

export const HorizontalCouponMerchantLogo: FC<{ image: string }> = ({ image }) => {
  const { spacing } = useTheme();
  return (
    <MerchantLogoInner
      style={{
        background: `center / contain no-repeat url(${image})`,
        width: spacing(9),
        height: '100%',
      }}
    />
  );
};
