import { ThemeType } from '@/ui-kit/theme';
import liberBlack from '@/ui-kit/theme/colors/liberBlack';
import liberWhite from '@/ui-kit/theme/colors/liberWhite';
import { Box, SxProps, Typography } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';

export const CellHeader: FC<PropsWithChildren<{ sx?: SxProps<ThemeType> }>> = ({
    children,
    sx,
}) => (
    <Box sx={{ minWidth: 0, ...sx }}>
        <Typography variant='body2' color='secondary' fontWeight={400} noWrap>
            {children}
        </Typography>
    </Box>
);

export const Cell: FC<{
    sx?: SxProps<ThemeType>;
    primaryText: ReactNode | string;
    secondaryText?: ReactNode | string;
}> = ({ sx, primaryText, secondaryText }) => (
    <Box sx={{ minWidth: 0, ...sx }}>
        <Typography variant='body2' fontWeight={400}>
            {primaryText}
        </Typography>
        {secondaryText && (
            <Typography
                variant='body2'
                sx={({ spacing }) => ({
                    color: liberBlack[100],
                    fontWeight: 400,
                    fontSize: spacing(1.5),
                })}
            >
                {secondaryText}
            </Typography>
        )}
    </Box>
);

const gridTemplateColumns = '8.6fr 8.9fr 7fr 26fr 8.3fr 8.3fr 8.3fr 10fr 8.3fr';

export const RowHeader: FC<PropsWithChildren<{ sx?: SxProps<ThemeType> }>> = ({ children }) => (
    <Box
        sx={{
            display: 'grid',
            gridTemplateColumns: gridTemplateColumns,
            columnGap: 1,
            // maxWidth: '1400px',
            width: '100%',
        }}
    >
        {children}
    </Box>
);

export const Row: FC<PropsWithChildren<{ sx?: SxProps<ThemeType> }>> = ({ children }) => (
    <Box
        sx={({ spacing }) => ({
            display: 'grid',
            gridTemplateColumns: gridTemplateColumns,
            columnGap: 1,
            // maxWidth: '1400px',
            width: '100%',
            borderBottom: `1px solid ${liberWhite[100]}`,
            py: spacing(2.5),

            '&:last-child': {
                pb: 0,
                borderBottom: 'none',
            },
        })}
    >
        {children}
    </Box>
);
