import { createSvgIcon } from '@mui/material/utils';

export const FlagDEIcon = createSvgIcon(
    <>
        <rect x={2} y={5} width={20} height={14} rx={2} fill='#fff' />
        <mask
            id='a'
            style={{
                maskType: 'alpha',
            }}
            maskUnits='userSpaceOnUse'
            x={2}
            y={5}
            width={20}
            height={14}
        >
            <rect x={2} y={5} width={20} height={14} rx={2} fill='#fff' />
        </mask>
        <g mask='url(#a)'>
            <path fillRule='evenodd' clipRule='evenodd' d='M2 9.667h20V5H2v4.667z' fill='#262626' />
            <g filter='url(#filter0_d_837_1232)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M2 14.333h20V9.667H2v4.666z'
                    fill='#F01515'
                />
            </g>
            <g filter='url(#filter1_d_837_1232)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M2 19h20v-4.667H2V19z'
                    fill='#FFD521'
                />
            </g>
        </g>
        <defs>
            <filter
                id='filter0_d_837_1232'
                x={2}
                y={9.66675}
                width={20}
                height={4.66675}
                filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'
            >
                <feFlood floodOpacity={0} result='BackgroundImageFix' />
                <feColorMatrix
                    in='SourceAlpha'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                />
                <feOffset />
                <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0' />
                <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_837_1232' />
                <feBlend in='SourceGraphic' in2='effect1_dropShadow_837_1232' result='shape' />
            </filter>
            <filter
                id='filter1_d_837_1232'
                x={2}
                y={14.3333}
                width={20}
                height={4.66675}
                filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'
            >
                <feFlood floodOpacity={0} result='BackgroundImageFix' />
                <feColorMatrix
                    in='SourceAlpha'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                />
                <feOffset />
                <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0' />
                <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_837_1232' />
                <feBlend in='SourceGraphic' in2='effect1_dropShadow_837_1232' result='shape' />
            </filter>
        </defs>
    </>,
    'FlagDEIcon'
);
