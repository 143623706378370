import React, { FC } from 'react';
import { Paper, styled, Typography } from '@mui/material';

type CashbackBlockProps = {
    coverImage: string;
    title: string;
    percent: string;
};

const Container = styled(Paper)(({ theme }) => ({
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    backgroundSize: 'cover',
    width: '100%',
    position: 'relative',
    paddingBottom: '100%',
}));

const ContainerInner = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'absolute',
    overflow: 'hidden',
    height: '100%',
}));

const MainTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

export const CashbackBlockNarrow: FC<CashbackBlockProps> = ({ coverImage, title, percent }) => {
    return (
        <Container
            elevation={4}
            sx={{
                backgroundImage: `url(${coverImage})`,
            }}
        >
            <ContainerInner>
                <MainTitle variant='body2'>{title}</MainTitle>
                <Typography variant='h2'>{percent}</Typography>
            </ContainerInner>
        </Container>
    );
};
