import { createSvgIcon } from '@mui/material/utils';

export const DownloadIcon = createSvgIcon(
    <>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M 2.109 17.647 C 2.694 17.647 3.168 18.121 3.168 18.706 L 3.168 21.529 C 3.168 21.724 3.326 21.882 3.521 21.882 L 20.462 21.882 C 20.657 21.882 20.815 21.724 20.815 21.529 L 20.815 18.706 C 20.815 18.121 21.289 17.647 21.874 17.647 C 22.459 17.647 22.933 18.121 22.933 18.706 L 22.933 21.529 C 22.933 22.894 21.827 24 20.462 24 L 3.521 24 C 2.156 24 1.05 22.894 1.05 21.529 L 1.05 18.706 C 1.05 18.121 1.524 17.647 2.109 17.647 Z'
        />
        <path d='M 10.21 0 C 9.484 0 8.878 0.555 8.814 1.278 C 8.598 3.753 8.559 6.239 8.7 8.719 C 8.351 8.738 8.003 8.76 7.654 8.786 L 5.551 8.939 C 4.741 8.998 4.287 9.902 4.725 10.587 C 6.224 12.934 8.156 14.974 10.418 16.598 L 11.26 17.203 C 11.697 17.517 12.286 17.517 12.723 17.203 L 13.565 16.598 C 15.827 14.974 17.759 12.934 19.258 10.587 C 19.696 9.902 19.243 8.998 18.432 8.939 L 16.329 8.786 C 15.981 8.76 15.632 8.738 15.283 8.719 C 15.424 6.239 15.386 3.753 15.169 1.278 C 15.105 0.555 14.5 0 13.773 0 L 10.21 0 Z' />
    </>,
    'DownloadIcon'
);
