import { UserType } from '@/bo-kit/types';

const USER_TYPE_NAMES: Record<UserType, string> = {
  [UserType.Customer]: 'Customer',
  [UserType.Merchant]: 'Merchant',
  [UserType.CustomerLegal]: 'Customer',
  [UserType.MerchantIssuer]: 'Merchant',
};

export const getUserTypeName = (userType: UserType): string =>
  USER_TYPE_NAMES[userType] ?? 'Unknown';
