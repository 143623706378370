import { IFaceLoader, NonAuthorizedLayout, ResponsiveLazyRoute } from '@/bo-kit/components';
import NotFoundPage from '@/bo-kit/components/NotFoundPage';
import AuthorizedLayout from '@/components/AuthorizedLayout';
import CheckAuthComponent from '@/components/CheckAuthComponent/CheckAuthComponent';
import { RoutesPermissionGuard } from '@/components/Permissions/RoutesPermissionGuard';
import AuthPage from '@/pages/Auth';
import LogoutPage from '@/pages/Logout';
import DesktopMainPage from '@/pages/Main/DesktopMainPage';
import MobileMainPage from '@/pages/Main/MobileMainPage';
import DesktopSearchTransactionPage from '@/pages/SearchTransactions/DesktopSearchTransactionPage';
import MobileSearchTransactionPage from '@/pages/SearchTransactions/MobileSearchTransactionPage';
import DesktopTransferVolumePage from '@/pages/ShowTransferVolume/DesktopShowTransferVolumePage';
import MobileTransferVolumePage from '@/pages/ShowTransferVolume/MobileShowTransferVolumePage';
import DesktopMerchantsListPage from '@/pages/MerchantsList/DesktopMerchantsListPage';
import MobileMerchantsListPage from '@/pages/MerchantsList/MobileMerchantsListPage';
import DesktopMerchantInfoPage from '@/pages/MerchantInfo/DesktopMerchantInfoPage';
import MobileMerchantInfoPage from '@/pages/MerchantInfo/MobileMerchantInfoPage';
import { FC, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Routes as RoutesBase } from 'react-router-dom';
import DesktopUsersListPage from '@/pages/Users/DesktopUsersListPage';
import MobileUsersListPage from '@/pages/Users/MobileUsersListPage';
import { CreateUserDialog } from '@/pages/Users/components/CreateUserDialog';
import { UpdateUserDialog } from '@/pages/Users/components/UpdateUserDialog';
import { DeleteUserDialog } from '@/pages/Users/components/DeleteUserDialog';
import { ResetUserPasswordDialog } from '@/pages/Users/components/ResetUserPasswordDialog';
import DesktopUserRolesListPage from '@/pages/Roles/DesktopUserRolesListPage';
import MobileUserRolesListPage from '@/pages/Roles/MobileUserRolesListPage';
import { CreateUserRoleDialog } from '@/pages/Roles/components/CreateUserRoleDialog';
import { UpdateUserRoleDialog } from '@/pages/Roles/components/UpdateUserRoleDialog';
import { DeleteUserRoleDialog } from '@/pages/Roles/components/DeleteUserRoleDialog';
import OrderDialog from '@/pages/SearchTransactions/components/OrderDialog';
import DesktopAccountTransactionsPage from '@/pages/AccountTransactions/DesktopAccountTransactionsPage';
import MobileAccountTransactionsPage from '@/pages/AccountTransactions/MobileAccountTransactionsPage';

const RoutesInner: FC = () => {
    const { t } = useTranslation('bo-kit__common');

    return (
        <RoutesBase>
            <Route
                path='/'
                element={
                    <CheckAuthComponent>
                        <RoutesPermissionGuard>
                            <AuthorizedLayout useDummyLayoutForDesktop={false} />
                        </RoutesPermissionGuard>
                    </CheckAuthComponent>
                }
            >
                <Route
                    index
                    element={
                        <ResponsiveLazyRoute
                            implementation='js'
                            desktop={<DesktopMainPage />}
                            mobile={<MobileMainPage />}
                            title={t('Home')}
                        />
                    }
                />
                <Route
                    path='show-transfer-volume'
                    element={
                        <ResponsiveLazyRoute
                            implementation='js'
                            desktop={<DesktopTransferVolumePage />}
                            mobile={<MobileTransferVolumePage />}
                            title='Daily transfer volume'
                        />
                    }
                />

                <Route
                    path='search-transactions'
                    element={
                        <ResponsiveLazyRoute
                            implementation='js'
                            desktop={<DesktopSearchTransactionPage />}
                            mobile={<MobileSearchTransactionPage />}
                            title='Transactions'
                        />
                    }
                >
                    <Route path=':orderId' element={<OrderDialog />} />
                </Route>

                <Route path='merchants'>
                    <Route
                        path=''
                        element={
                            <ResponsiveLazyRoute
                                implementation='js'
                                desktop={<DesktopMerchantsListPage />}
                                mobile={<MobileMerchantsListPage />}
                                title='Merchants list'
                            />
                        }
                    />
                    <Route
                        path=':merchantId'
                        element={
                            <ResponsiveLazyRoute
                                implementation='js'
                                desktop={<DesktopMerchantInfoPage />}
                                mobile={<MobileMerchantInfoPage />}
                                title='Merchant info'
                            />
                        }
                    />
                </Route>

                <Route
                    path='users'
                    element={
                        <ResponsiveLazyRoute
                            implementation='js'
                            desktop={<DesktopUsersListPage />}
                            mobile={<MobileUsersListPage />}
                            title='Users list'
                        />
                    }
                >
                    <Route path='create' element={<CreateUserDialog />} />
                    <Route path='update/:userId' element={<UpdateUserDialog />} />
                    <Route path='delete/:userId' element={<DeleteUserDialog />} />
                    <Route path='reset/:userId' element={<ResetUserPasswordDialog />} />
                </Route>
                <Route
                    path='users/roles'
                    element={
                        <ResponsiveLazyRoute
                            implementation='js'
                            desktop={<DesktopUserRolesListPage />}
                            mobile={<MobileUserRolesListPage />}
                            title='User roles list'
                        />
                    }
                >
                    <Route path='create' element={<CreateUserRoleDialog />} />
                    <Route path='update/:roleId' element={<UpdateUserRoleDialog />} />
                    <Route path='delete/:roleId' element={<DeleteUserRoleDialog />} />
                </Route>
                <Route
                    path='account-transactions'
                    element={
                        <ResponsiveLazyRoute
                            implementation='js'
                            desktop={<DesktopAccountTransactionsPage />}
                            mobile={<MobileAccountTransactionsPage />}
                            title='Transactions'
                        />
                    }
                />
            </Route>
            <Route path='auth' element={<NonAuthorizedLayout />}>
                <Route index element={<AuthPage />} />
            </Route>
            <Route
                path='logout'
                element={
                    <Suspense fallback={<IFaceLoader />}>
                        <LogoutPage />
                    </Suspense>
                }
            />
            <Route
                path='*'
                element={
                    <Suspense fallback={<IFaceLoader />}>
                        <NotFoundPage />
                    </Suspense>
                }
            />
        </RoutesBase>
    );
};

export const Routes: FC = () => (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
        <RoutesInner />
    </BrowserRouter>
);
