import React, { FC } from 'react';
import { Box, Paper, styled, IconButton } from '@mui/material';
import { LikeIcon } from '../../icons';
import liberBlack from '../../theme/colors/liberBlack';
import liberWhite from '../../theme/colors/liberWhite';
import { useTranslation } from '../../hooks/useTranslation';

const ContainerVotes = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between ',
}));

const TotalVotes = styled(Box)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.h1.fontSize,
    color: theme.palette.text.primary,
    lineHeight: 1,
}));

const TotalVotesLabel = styled(Box)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.fontSize,
    color: theme.palette.text.secondary,
    lineHeight: 1,
}));

const VotesNote = styled(Box)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.fontSize,
    color: theme.palette.text.secondary,
}));

const VotesStat = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}));

const VoteButton = styled(IconButton)(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'center',

    borderRadius: theme.spacing(1.5),
    boxShadow: 'rgb(18 20 23 / 12%) 0px 2px 4px -1px',

    border: 'none',
    backgroundColor: theme.palette.primary.light,
    height: `${theme.spacing(6)} !important`,
    width: `${theme.spacing(6)} !important`,
    display: 'flex',
    cursor: 'pointer',

    '&:disabled': {
        backgroundColor: liberWhite[100],
        cursor: 'initial',
    },
    '&:active': {
        boxShadow: 'none',
    },
}));

type MerchantContentVotingProps = {
    onVote: () => void;
    votes: number;
    voted?: boolean;
};

export const MerchantContentVoting: FC<MerchantContentVotingProps> = ({ onVote, votes, voted }) => {
    const { t } = useTranslation('ui-kit__merchant');

    return (
        <>
            <VotesNote>
                {t('merchant_content.voting.title', {
                    // will be applied only for labeled app
                    appLabelName: process.env.REACT_APP_LABEL_NAME,
                })}
            </VotesNote>

            <ContainerVotes elevation={4}>
                <VotesStat>
                    <TotalVotes>{votes}</TotalVotes>
                    <TotalVotesLabel>{t('merchant_content.voting.num_votes')}</TotalVotesLabel>
                </VotesStat>

                <VoteButton onClick={onVote} disabled={voted}>
                    <LikeIcon
                        sx={(theme) => ({
                            fill: voted ? liberBlack[100] : theme.palette.error.main,
                        })}
                    />
                </VoteButton>
            </ContainerVotes>
        </>
    );
};
