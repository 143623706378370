import React, { useCallback, useEffect } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteUserMutation, useGetUsersQuery } from '@/transport/usersApi';
import { useNotification } from '@/components/Notification';
import {
    ManageDialog,
    ManageDialogActions,
    ManageDialogContent,
    ManageDialogTitle,
} from '@/components/ManageDialog';

const DeleteUserDialog: React.FC = () => {
    const navigate = useNavigate();
    const { userId } = useParams();

    const { data: usersData } = useGetUsersQuery();
    const [deleteUser, { isSuccess, isLoading, isError }] = useDeleteUserMutation();

    const handleClose = useCallback(() => {
        navigate('/users');
    }, [navigate]);

    const handleDelete = () => {
        if (!userId) return;
        deleteUser({ id: userId });
    };

    const { notify } = useNotification();

    useEffect(() => {
        if (isError) {
            notify({ message: 'Error while deleting user', type: 'error' });
        }
    }, [isError, notify]);

    useEffect(() => {
        if (isSuccess) {
            notify({ message: 'User deleted successfully', type: 'success' });
            handleClose();
        }
    }, [handleClose, isSuccess, notify]);

    const currentUserData = usersData?.data.find((user) => user.id === userId);

    return (
        <ManageDialog open={true} onClose={handleClose}>
            <ManageDialogTitle>Delete user</ManageDialogTitle>
            <ManageDialogContent>
                {!currentUserData && (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}

                {currentUserData && (
                    <Typography variant='body2'>
                        Are you sure want to delete user &quot;
                        {currentUserData.firstName} {currentUserData.lastName}&quot; (
                        {currentUserData.email})?
                    </Typography>
                )}
            </ManageDialogContent>
            <ManageDialogActions>
                <Button
                    color='secondary'
                    onClick={handleClose}
                    disabled={isLoading || !currentUserData}
                >
                    Cancel
                </Button>
                <Button
                    type='submit'
                    color='primary'
                    onClick={handleDelete}
                    disabled={isLoading || !currentUserData || isSuccess}
                >
                    Delete
                </Button>
            </ManageDialogActions>
        </ManageDialog>
    );
};

export { DeleteUserDialog };
