import React from 'react';
import { Autocomplete, Box, Checkbox, TextField } from '@mui/material';
import liberWhite from '@/ui-kit/theme/colors/liberWhite';

interface CouponsSelectProps {
    coupons: string[];
    selectedCoupons: string[];
    onChange: (coupons: string[]) => void;
    limitTags: number;
}

export const CouponsSelect: React.FC<CouponsSelectProps> = ({
    coupons,
    selectedCoupons,
    onChange,
    limitTags,
}) => {
    return (
        <Autocomplete
            multiple
            options={coupons}
            value={selectedCoupons}
            onChange={(_, values) => onChange(values)}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
                <Box {...props} key={option} component={'li'} fontSize={'1rem'}>
                    <Checkbox size='small' style={{ marginRight: 8 }} checked={selected} />
                    {option}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label='Coupons'
                    placeholder=''
                    multiline={false}
                    sx={(theme) => ({
                        [theme.breakpoints.up('sm')]: {
                            position: 'relative',
                            height: 56,
                            '& .MuiInputBase-root': {
                                position: 'absolute',
                                display: 'flex',
                                flexDirection: 'row',
                                bgcolor: liberWhite[400],
                            },
                        },
                    })}
                />
            )}
            sx={{ display: 'flex', flexWrap: 'nowrap' }}
            limitTags={limitTags}
        />
    );
};
