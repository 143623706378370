import { FC, PropsWithChildren, ReactNode } from 'react';
import {
    Dialog as DialogBase,
    DialogContent,
    styled,
    ModalProps,
    DialogProps,
    DialogActions,
    DialogContentProps,
} from '@mui/material';
import { PageDialogTitle } from './PageDialogTitle';
import { DialogShowUpTransition } from '../DialogShowTransition/DialogShowUpTransition';
import { DialogShowLeftTransition } from '../DialogShowTransition/DialogShowLeftTransition';

type PageDialogProps = PropsWithChildren & {
    open: ModalProps['open'];
    keepMounted?: ModalProps['keepMounted'];
    onClose?: () => void;
    title?: string;
    PaperProps?: DialogProps['PaperProps'];
    actions?: ReactNode;
    closeVariant?: 'back' | 'cross';
    dialogContentProps?: DialogContentProps;
    titleBtn?: ReactNode;
};

const Dialog = styled(DialogBase)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        '&  .MuiDialog-paper': {
            margin: 0,
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            maxHeight: 'none',
            borderRadius: 0,
            '&$paperScrollBody': {
                margin: 0,
                maxWidth: '100%',
            },
        },
    },
    [theme.breakpoints.up('sm')]: {
        '& .MuiDialog-container': {
            '& .MuiPaper-root': {
                width: '100%',
                maxWidth: '375px',
            },
        },
    },
}));

export const PageDialog: FC<PageDialogProps> = ({
    closeVariant = 'cross',
    open,
    keepMounted,
    onClose,
    PaperProps,
    title,
    children,
    actions,
    dialogContentProps = {},
    titleBtn,
}) => (
    <Dialog
        open={open}
        keepMounted={keepMounted}
        onClose={onClose}
        PaperProps={PaperProps}
        TransitionComponent={
            closeVariant === 'cross' ? DialogShowUpTransition : DialogShowLeftTransition
        }
    >
        {title && (
            <PageDialogTitle titleBtn={titleBtn} closeVariant={closeVariant} onClose={onClose}>
                {title}
            </PageDialogTitle>
        )}
        <DialogContent {...dialogContentProps}>{children}</DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
);
