import { FC, PropsWithChildren } from 'react';
import { Container } from '../components/Container';
import { NonAuthorizedLayoutHeader } from '../components/Header';
import { FullScreenContainer } from './FullScreenContainer';
import { Content } from './Content';

export const NonAuthorizedLayoutWithoutOutlet: FC<PropsWithChildren> = ({ children }) => (
  <Container>
    <FullScreenContainer>
      <NonAuthorizedLayoutHeader />
      <Content>{children}</Content>
    </FullScreenContainer>
  </Container>
);
