import React, { FC, useMemo } from 'react';
import { styled, Box } from '@mui/material';
import {
    LockIcon,
    CircularProgressLock,
    CircularProgressContainer,
} from '../../components/MerchantCard/MerchantCardImage';
import liberWhite from '../../theme/colors/liberWhite';

import { CircularProgress } from '../../components/MerchantCard/CircularProgress';
import { numDigits } from '../../utils/numDigits';
import { Nullable } from '../../types';

export const CircularProgressWithLock: FC<{ votes: number }> = ({ votes }) => {
    const value = useMemo(() => {
        if (votes < 100) {
            return votes;
        } else {
            return (votes / 10 ** numDigits(votes)) * 100;
        }
    }, [votes]);

    return (
        <CircularProgressContainer
            sx={(theme) => ({
                borderRadius: theme.spacing(1.5),
            })}
        >
            <CircularProgress variant='determinate' size={80} value={value} />
            <CircularProgressLock>
                <LockIcon
                    sx={{
                        color: liberWhite[200],
                    }}
                />
            </CircularProgressLock>
        </CircularProgressContainer>
    );
};

const Image = styled('img')(({ theme }) => ({
    width: '100%',
    borderRadius: theme.spacing(1.5),
    height: '144px',
    objectFit: 'cover',
    verticalAlign: 'middle',
}));

const NewTag = styled(Box)(({ theme }) => ({
    background: theme.palette.error.main,
    borderRadius: theme.spacing(0.5),
    display: 'inline',
    position: 'absolute',
    margin: theme.spacing(1.5),
    padding: theme.spacing(0.5),
    lineHeight: 1.25,
    color: theme.palette.common.white,

    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.fontSize,
}));

const Container = styled(Box)(() => ({
    position: 'relative',
}));

type MerchantImageProps = {
    image: string;
    isNew?: boolean;
    votes?: Nullable<number>;
};

export const MerchantContentImage: FC<MerchantImageProps> = ({ image, isNew = false, votes }) => {
    return (
        <Container>
            {isNew && <NewTag>New</NewTag>}
            <Image src={image} />
            {votes != null && <CircularProgressWithLock votes={votes} />}
        </Container>
    );
};
