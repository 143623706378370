import liberIcon from '../../../../images/logo.svg';
import { IconButton, SvgIcon } from '@mui/material';
import { ReactComponent as MenuImg } from './assets/menu.svg';
import { FC, useCallback, useState } from 'react';
import { Box } from '@mui/system';
import { MobileLayoutSideMenu } from '../SideMenu';
import { AppBarStyled, ElevationScroll, ToolbarStyled } from './components';
import { getRootUrl } from '@/ui-kit/utils/getExternalLoginUrl';

const MenuIcon: FC = () => (
  <SvgIcon viewBox='0 0 32 32'>
    <MenuImg />
  </SvgIcon>
);

export const DefaultHeader: FC = () => {
  const [sideMenuShown, setSideMenuShown] = useState(false);
  const showMenu = useCallback(() => setSideMenuShown((prevValue) => !prevValue), []);
  const onMenuClose = useCallback(() => setSideMenuShown(false), []);
  return (
    <>
      <ElevationScroll>
        <AppBarStyled position='fixed'>
          <ToolbarStyled>
            <a href={getRootUrl()}>
              <img height='100%' src={liberIcon} alt='logo' />
            </a>
            <Box sx={{ flexGrow: 1, textAlign: 'right' }}>
              <IconButton
                size='large'
                color='inherit'
                aria-label='menu'
                onClick={showMenu}
                sx={({ spacing }) => ({ position: 'relative', right: spacing(-1) })}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </ToolbarStyled>
        </AppBarStyled>
      </ElevationScroll>
      <ToolbarStyled />
      <MobileLayoutSideMenu onClose={onMenuClose} open={sideMenuShown} />
    </>
  );
};

export const LabelHeader: FC = () => {
  const [sideMenuShown, setSideMenuShown] = useState(false);
  const showMenu = useCallback(() => setSideMenuShown((prevValue) => !prevValue), []);
  const onMenuClose = useCallback(() => setSideMenuShown(false), []);
  return (
    <>
      <ElevationScroll>
        <AppBarStyled position='fixed'>
          <ToolbarStyled>
            <a href={getRootUrl()}>
              <img
                height='100%'
                src={`${process.env.PUBLIC_URL}/logo-icon.svg`}
                alt='logo'
                width={48}
              />
            </a>
            <Box sx={{ flexGrow: 1, textAlign: 'right' }}>
              <IconButton
                size='large'
                color='inherit'
                aria-label='menu'
                onClick={showMenu}
                sx={({ spacing }) => ({ position: 'relative', right: spacing(-1) })}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </ToolbarStyled>
        </AppBarStyled>
      </ElevationScroll>
      <ToolbarStyled />
      <MobileLayoutSideMenu onClose={onMenuClose} open={sideMenuShown} />
    </>
  );
};

// use different components for labeled and unlabeled apps during the build phase
// to maintain compatibility until all applications start using the new labeled components
export const Header = process.env.REACT_APP_LABEL_NAME ? LabelHeader : DefaultHeader;
