import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    CreateUserRequest,
    CreateUserResponse,
    CreateUserRoleRequest,
    CreateUserRoleResponse,
    DeleteUserRequest,
    DeleteUserResponse,
    DeleteUserRoleRequest,
    DeleteUserRoleResponse,
    GetUserRolesRequest,
    GetUserRolesResponse,
    GetUsersRequest,
    GetUsersResponse,
    ResetUserPasswordRequest,
    ResetUserPasswordResponse,
    UpdateUserRequest,
    UpdateUserResponse,
    UpdateUserRoleRequest,
    UpdateUserRoleResponse,
} from './types';

const baseUrl = process.env.REACT_APP_STAT_BASE_URL;

const reducerPath = 'usersApi' as const;

export const usersApi = createApi({
    reducerPath,
    baseQuery: fetchBaseQuery({ baseUrl }),
    tagTypes: ['User', 'Role'],
    endpoints: (build) => ({
        getUsers: build.query<GetUsersResponse, GetUsersRequest>({
            query: () => ({
                url: '/api/users',
                method: 'GET',
                credentials: 'include',
            }),
            providesTags: ['User'],
        }),
        createUser: build.mutation<CreateUserResponse, CreateUserRequest>({
            query: (data) => ({
                url: '/api/users',
                method: 'POST',
                credentials: 'include',
                body: data,
            }),
            invalidatesTags: ['User'],
        }),
        updateUser: build.mutation<UpdateUserResponse, UpdateUserRequest>({
            query: ({ id, ...data }) => ({
                url: `/api/users/${id}`,
                method: 'PATCH',
                credentials: 'include',
                body: data,
            }),
            invalidatesTags: ['User'],
        }),
        deleteUser: build.mutation<DeleteUserResponse, DeleteUserRequest>({
            query: ({ id }) => ({
                url: `/api/users/${id}`,
                method: 'DELETE',
                credentials: 'include',
            }),
            invalidatesTags: ['User'],
        }),
        resetUserPassword: build.mutation<ResetUserPasswordResponse, ResetUserPasswordRequest>({
            query: ({ id }) => ({
                url: `/api/users/${id}/reset-password`,
                method: 'POST',
                credentials: 'include',
            }),
        }),

        getUserRoles: build.query<GetUserRolesResponse, GetUserRolesRequest>({
            query: () => ({
                url: '/api/roles',
                method: 'GET',
                credentials: 'include',
            }),
            providesTags: ['Role'],
        }),
        createUserRole: build.mutation<CreateUserRoleResponse, CreateUserRoleRequest>({
            query: (data) => ({
                url: '/api/roles',
                method: 'POST',
                credentials: 'include',
                body: data,
            }),
            invalidatesTags: ['Role'],
        }),
        updateUserRole: build.mutation<UpdateUserRoleResponse, UpdateUserRoleRequest>({
            query: ({ id, ...data }) => ({
                url: `/api/roles/${id}`,
                method: 'PATCH',
                credentials: 'include',
                body: data,
            }),
            invalidatesTags: ['Role'],
        }),
        deleteUserRole: build.mutation<DeleteUserRoleResponse, DeleteUserRoleRequest>({
            query: ({ id }) => ({
                url: `/api/roles/${id}`,
                method: 'DELETE',
                credentials: 'include',
            }),
            invalidatesTags: ['Role'],
        }),
    }),
});

export const {
    useGetUsersQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useResetUserPasswordMutation,
    useDeleteUserMutation,

    useGetUserRolesQuery,
    useCreateUserRoleMutation,
    useUpdateUserRoleMutation,
    useDeleteUserRoleMutation,
} = usersApi;
