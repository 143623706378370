import React, { FC, useMemo } from 'react';
import { Box, CardMedia as CardMediaBase, styled } from '@mui/material';
import { DEFAULT_IMAGE } from './MerchantCard';
import { LockIcon as LockIconBase } from '../../icons';
import { numDigits } from '../../utils/numDigits';
import { CircularProgress } from './CircularProgress';
import liberWhite from '../../theme/colors/liberWhite';
import { Nullable } from '../../types';

export const LockIcon = styled(LockIconBase)(({ theme }) => ({
    width: theme.spacing(5),
    height: theme.spacing(5),
}));

export const CircularProgressContainer = styled(Box)(() => ({
    position: 'absolute',
    background: 'rgb(77 81 88 / 70%)',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
}));

export const CircularProgressLock = styled(Box)(() => ({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const CircularProgressWithLock: FC<{ votes: number }> = ({ votes }) => {
    const value = useMemo(() => {
        if (votes < 100) {
            return votes;
        } else {
            return (votes / 10 ** numDigits(votes)) * 100;
        }
    }, [votes]);

    return (
        <CircularProgressContainer>
            <CircularProgress size={80} value={value} />
            <CircularProgressLock>
                <LockIcon
                    sx={{
                        color: liberWhite[200],
                    }}
                />
            </CircularProgressLock>
        </CircularProgressContainer>
    );
};

const CardMedia = styled(CardMediaBase)({
    height: '100%',
});

const Container = styled(Box)({
    position: 'relative',
    flex: '1 0 50%',
});

const MAX_CATEGORIES_TO_SHOW = 3;

const CardCategories = styled(Box)(({ theme }) => ({
    position: 'absolute',
    margin: theme.spacing(0.5, 1),
    width: '100%',
    zIndex: 1,
}));

const CardCategory = styled('span')(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
    opacity: 0.9,
    borderRadius: theme.spacing(0.5),
    zIndex: 1,
    padding: theme.spacing(0.5),
    margin: theme.spacing(0, 0.5),
    display: 'inline-block',
}));

type MerchantCardImageProps = {
    voteCount?: Nullable<number>;
    image?: string;
    categories?: string[];
};

export const MerchantCardImage: FC<MerchantCardImageProps> = ({ image, voteCount, categories }) => (
    <Container>
        {categories && (
            <CardCategories>
                {categories.slice(0, MAX_CATEGORIES_TO_SHOW).map((cat) => (
                    <CardCategory key={cat}>{cat}</CardCategory>
                ))}
            </CardCategories>
        )}
        <CardMedia image={image ?? DEFAULT_IMAGE} />
        {voteCount != null && <CircularProgressWithLock votes={voteCount} />}
    </Container>
);
