import { Chip, ChipProps, styled } from '@mui/material';
import { FC } from 'react';
import { CloseIcon } from '../../icons';

const FilterTagBase = styled(Chip)(({ theme }) => ({
    borderRadius: theme.spacing(0.5),
    margin: theme.spacing(0.5, 0.5),
}));

export const FilterTag: FC<ChipProps> = (props) => (
    <FilterTagBase deleteIcon={<CloseIcon fontSize='small' />} size='small' {...props} />
);
