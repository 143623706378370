import { ComponentProps, FC, ReactNode, Suspense, useMemo } from 'react';
import { ResponsivePage as ResponsivePageBase } from '@/ui-kit/components/ResponsivePage';
import { IFaceLoader } from './IFaceLoader';
import { HtmlPageTitle } from '@/bo-kit/components/HtmlPageTitle';

export const ResponsiveLazyRoute: FC<
  ComponentProps<typeof ResponsivePageBase> & {
    title?: ReactNode;
    suspense?: boolean;
  }
> = ({ title, desktop: _desktop, mobile: _mobile, suspense = true, ...props }) => {
  const desktop = useMemo(
    () => (suspense ? <Suspense fallback={<IFaceLoader />}>{_desktop}</Suspense> : _desktop),
    [_desktop, suspense]
  );
  const mobile = useMemo(
    () => (suspense ? <Suspense fallback={<IFaceLoader />}>{_mobile}</Suspense> : _mobile),
    [_mobile, suspense]
  );
  return (
    <>
      {title && <HtmlPageTitle title={title} />}
      <ResponsivePageBase
        {...props}
        desktop={desktop}
        mobile={<Suspense fallback={<IFaceLoader />}>{mobile}</Suspense>}
      />
    </>
  );
};
