import { useState, useEffect } from 'react';

export const useDebounce = <T = unknown>(
    value: T,
    delay: number,
    condition?: (value: T) => boolean
): T => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        // condition fn defined and it give false => skip debounce, just propagate value
        if (condition?.(value) === false) {
            setDebouncedValue(value);
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return () => {};
        }
        const handler = setTimeout(() => setDebouncedValue(value), delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay, condition]);
    return debouncedValue;
};
