import React, { FC, ComponentProps, useMemo } from 'react';
import { useMeasure } from '@uidotdev/usehooks';
import { Box } from '@mui/material';
import { HorizontallyStackedCards } from '@/ui-kit/components';

const MIN_WIDTH = 276;

export const FullWidthHorizontallyStackedCards: FC<
  Omit<ComponentProps<typeof HorizontallyStackedCards>, 'width'>
> = ({ children, ...props }) => {
  const [ref, { width }] = useMeasure();

  const cardsToFitIntoScreen = useMemo(() => (width ? Math.ceil(width / 126) : 3), [width]);

  return (
    <Box ref={ref} sx={{ width: '100%', overflow: 'hidden', minWidth: MIN_WIDTH }}>
      <HorizontallyStackedCards
        {...props}
        cardsToFitIntoScreen={cardsToFitIntoScreen}
        width={`${width ?? MIN_WIDTH}px`}
      >
        {children}
      </HorizontallyStackedCards>
    </Box>
  );
};
