import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { isDev } from '@/ui-kit/utils/isDev';
import { Language } from '@/ui-kit/types';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)

  // for all options read: https://www.i18next.com/overview/configuration-options

  .init({
    ns: 'bo-kit__common',
    defaultNS: 'translation',
    load: 'languageOnly',
    supportedLngs: Object.values(Language),
    fallbackLng: 'de',
    debug: isDev(),
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
      addPath: `${process.env.PUBLIC_URL}/locales/add/{{lng}}/{{ns}}`,
    },
    detection: {
      lookupQuerystring: 'locale',
    },
  });

export default i18n;
