import { useLogoutMutation } from '@/transport/authApi';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

export default function LogoutPage() {
    const [doLogout] = useLogoutMutation();
    useEffect(() => {
        doLogout();
    }, [doLogout]);

    return <Navigate to='/auth' replace />;
}
