import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { isDev } from '@/ui-kit/utils/isDev';
import { initAnalytics, sendWebVitalsToAnalytics } from './analytics';
import '@/bo-kit/i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as amplitude from '@amplitude/analytics-browser';

if (isDev()) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { worker } = require('./transport/mocks/browser');
    worker.start({
        serviceWorker: {
            url: `${process.env.PUBLIC_URL}/mockServiceWorker.js`,
        },
        waitUntilReady: true,
    });
}

amplitude.init(String(process.env.REACT_APP_AMPLITUDE_KEY), {
    defaultTracking: true,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

initAnalytics();
reportWebVitals(sendWebVitalsToAnalytics);
