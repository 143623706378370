import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';
import { isDev } from '@/ui-kit/utils/isDev';

export const initAnalytics = () =>
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKER_ID ?? 'undefined', { testMode: !isDev() });

export const sendWebVitalsToAnalytics = ({
    id,
    name,
    value,
}: {
    id: UaEventOptions['label'];
    name: UaEventOptions['action'];
    value: UaEventOptions['value'];
}) =>
    ReactGA.event({
        category: 'Web Vitals',
        action: name,
        value: value === undefined ? 0 : Math.round(name === 'CLS' ? value * 1000 : value),
        label: id,
        nonInteraction: true,
    });
